import { AvatarProps, Theme } from "@mui/material";
import { AvatarSize } from "types/types";

export function getRandomColor(theme: Theme, seed?: string) {
  const arr: Array<{ background: string; contrast: string }> = [
    {
      background: "rgba(114, 57, 234, 0.30)",
      contrast: theme.palette.info.dark,
    },
    {
      background: "rgba(255, 199, 0, 0.30)",
      contrast: theme.palette.warning.dark,
    },
    {
      background: "rgba(241, 65, 108, 0.30)",
      contrast: theme.palette.error.dark,
    },
    {
      background: "rgba(80, 205, 137, 0.3)",
      contrast: theme.palette.success.dark,
    },
    {
      background: "rgba(0, 158, 247, 0.30)",
      contrast: theme.palette.primary.dark,
    },
    {
      background: theme.palette.grey[300],
      contrast: theme.palette.grey[700],
    },
  ];

  if (seed) {
    return arr[
      seed.split("").reduce((acc, char) => {
        return acc + char.charCodeAt(0);
      }, 0) % arr.length
    ];
  }

  return arr[Math.floor(Math.random() * arr.length)];
}

export function getAvatarSize(size: AvatarSize) {
  switch (size) {
    case "tiny":
      return {
        height: 12,
        width: 12,
        fontSize: "6px",
        lineHeight: "8px",
      };
    case "small":
      return {
        height: 28,
        width: 28,
        fontSize: "12px",
        lineHeight: "16px",
      };
    case "medium":
      return {
        height: 40,
        width: 40,
        fontSize: "16px",
        lineHeight: "20px",
      };
    case "large":
      return {
        height: 120,
        width: 120,
        fontSize: "56px",
        lineHeight: "56px",
      };
    case "huge":
      return {
        height: 200,
        width: "auto",
      };
    default:
      return {
        height: 40,
        width: 40,
        fontSize: "16px",
        lineHeight: "20px",
      };
  }
}

export function getAvatarIconSize(size: AvatarSize) {
  switch (size) {
    case "tiny":
      return 9;
    case "small":
      return 14;
    case "medium":
      return 20;
    case "large":
      return 60;
    default:
      return 20;
  }
}

export function getAvatarBorderRadius(
  size: AvatarSize,
  variant: AvatarProps["variant"],
) {
  if (variant === "circular") return 100;

  switch (size) {
    case "small":
      return "7px";
    case "medium":
    case "large":
    default:
      return "10px";
  }
}
