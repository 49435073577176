import { getImageUrlFromImageData } from "@blue-ocean-robotics/seto_utils";
import { Document, Image, Page, pdf, Text, View } from "@react-pdf/renderer";

import completeStatusIcon from "assets/images/completeStatus.png";
import incompleteStatusIcon from "assets/images/incompleteStatus.png";
import borLogo from "assets/logos/bor-logo.png";
import UVDLogo from "assets/logos/uvd-logo.png";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import { FormattedMessage, IntlProvider } from "react-intl";
import { interruptionMsg } from "trans/definedMessages/interruptionMessages";
import { capitalizeWithPascalCase } from "utils/utilFunctions";
import { getUVDSerialNumber } from "utils/UVD/getUVDRobotSerialNumber";
import {
  DisinfectedPositionIcon,
  FailedPositionIcon,
  RemainingPositionIcon,
} from "../../../assets/icons/PositionIcons";
import { styles } from "./styles";

const getPositionText = (value: string) => {
  const stringArr = value.split("");

  if (stringArr.length > 60) {
    return value.slice(0, 60) + " ...";
  } else {
    return value;
  }
};

const getDocument = (reportData: any, mapSrc?: string | undefined) => (
  <Document>
    <IntlProvider locale="en">
      <Page style={styles.page} size="A4" wrap={true}>
        <View style={styles.headerContainer} fixed>
          <View style={styles.mainHeaderWrapper}>
            <Text style={styles.mainHeader}>Disinfection Report</Text>
            <Image src={UVDLogo} style={styles.headerLogo} />
          </View>
          <View style={styles.column}>
            <View style={styles.disinfectionStatus}>
              <Image
                src={
                  reportData.status === "complete"
                    ? completeStatusIcon
                    : incompleteStatusIcon
                }
                style={styles.disinfectionStatusLogo}
              />
              <View style={styles.disinfectionStatusWrapper}>
                <Text>Disinfection {reportData.status}</Text>
              </View>
            </View>
            <View style={styles.headerInfoContainer}>
              <View style={styles.leftHeaderInfoWrapper}>
                <Text style={styles.detailListItem}>
                  <Text style={styles.boldText}>Room:</Text>{" "}
                  {reportData.room || "-"}
                </Text>
                <Text>
                  <Text style={styles.boldText}>Department:</Text>{" "}
                  {reportData.department || "-"}
                </Text>
              </View>
              <View style={styles.rightHeaderInfoWrapper}>
                <Text style={styles.detailListItem}>
                  <Text style={styles.boldText}>Date:</Text>{" "}
                  {format(new Date(reportData.end), "dd MMMM yyyy")}
                </Text>
                <Text>
                  <Text style={styles.boldText}>Robot name:</Text>{" "}
                  {reportData.robot?.robot?.serialNumber ||
                    getUVDSerialNumber(reportData.robotId)}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {mapSrc && !reportData?.mapError ? (
          <View style={styles.mapImageContainer} fixed={false}>
            <Image src={mapSrc} style={styles.mapImage} />
            <View style={styles.mapLegendWrapper}>
              <View style={styles.row}>
                <Text style={styles.mapLegendHeader}>
                  UV Irradiation exposure
                </Text>
              </View>
              <View style={styles.row}>
                <View style={styles.mapLegendColor100}></View>
                <Text style={styles.mapLegendText}>100</Text>
              </View>
              <View style={styles.row}>
                <View style={styles.mapLegendColor75}></View>
                <Text style={styles.mapLegendText}>75</Text>
              </View>
              <View style={styles.row}>
                <View style={styles.mapLegendColor50}></View>
                <Text style={styles.mapLegendText}>50</Text>
              </View>
              <View style={styles.row}>
                <View style={styles.mapLegendColor25}></View>
                <Text style={styles.mapLegendText}>25</Text>
              </View>
              <View style={styles.row}>
                <View style={styles.mapLegendColor0}></View>
                <Text style={styles.mapLegendText}>0 mJ/cm²</Text>
              </View>
            </View>
          </View>
        ) : (
          <View style={styles.emptyMapContainer} fixed={false}>
            <Text style={styles.emptyMapTextHeader}>
              {reportData?.mapError}
            </Text>
          </View>
        )}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 20,
            marginTop: 10,
          }}
        >
          <View
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <Text style={styles.detailsTxt}>DETAILS</Text>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <View style={styles.listItem}>
                <Text style={styles.listItemHeader}>
                  <FormattedMessage
                    id="uvd.report.disinfectionType"
                    defaultMessage="Disinfection mode"
                  />
                </Text>
                <Text style={styles.listItemValue}>{reportData.type}</Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.listItemHeader}>
                  <FormattedMessage
                    id="uvd.report.started"
                    defaultMessage="Start time"
                  />
                </Text>
                <Text style={styles.listItemValue}>
                  {format(new Date(reportData.start), "dd-MM-yy HH:mm")}
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.listItemHeader}>
                  <FormattedMessage
                    id="uvd.report.startedBy"
                    defaultMessage="Started by"
                  />
                </Text>
                <Text style={styles.listItemValue}>{reportData.startedBy}</Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.listItemHeader}>
                  <FormattedMessage
                    id="uvd.report.ended"
                    defaultMessage="Disinfection finished"
                  />
                </Text>
                <Text style={styles.listItemValue}>
                  {format(new Date(reportData.end), "dd-MM-yy HH:mm")}
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.listItemHeader}>
                  <FormattedMessage
                    id="uvd.report.submittedBy"
                    defaultMessage="Finished by"
                  />
                </Text>
                <Text style={styles.listItemValue}>
                  {reportData.submittedBy}
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.listItemHeader}>
                  <FormattedMessage
                    id="uvd.report.uvdLightDuration"
                    defaultMessage="UV-C light duration"
                  />
                </Text>
                <Text style={styles.listItemValue}>
                  {reportData.uvcLightDurationInHMS}
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.listItemHeader}>
                  <FormattedMessage
                    id="uvd.report.totalDuration"
                    defaultMessage="Total duration"
                  />
                </Text>
                <Text style={styles.listItemValue}>
                  {reportData.taskDuration}
                </Text>
              </View>
            </View>
            {reportData.type === "Predefined" && !reportData?.mapError ? (
              <View style={styles.listItem}>
                <Text style={styles.listItemHeader}>
                  <FormattedMessage
                    id="uvd.report.positionsDisinfected"
                    defaultMessage="Positions disinfected   "
                  />
                </Text>
                <Text style={styles.listItemValue}>
                  {reportData.positions?.completed.length}
                </Text>
              </View>
            ) : null}
            {reportData.type === "Predefined" && !reportData?.mapError ? (
              <View style={styles.listItem}>
                <Text style={styles.listItemHeader}>
                  <FormattedMessage
                    id="uvd.report.positionsRemaining"
                    defaultMessage="Positions remaining"
                  />
                </Text>
                <Text style={styles.listItemValue}>
                  {reportData.positions?.remaining.length}
                </Text>
              </View>
            ) : null}
            {reportData.type === "Predefined" && !reportData?.mapError ? (
              <View style={styles.listItem}>
                <Text style={styles.listItemHeader}>
                  <FormattedMessage
                    id="uvd.report.positionsFailed"
                    defaultMessage="Positions failed driving to point"
                  />
                </Text>
                <Text style={styles.listItemValue}>
                  {reportData.positions?.failed.length}
                </Text>
              </View>
            ) : null}
          </View>

          <View
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <Text style={styles.detailsTxt}>INTERRUPTIONS</Text>
            {reportData.interruptions?.map(
              (item: { label: string; value: number }) => {
                if (item.value === 0) return null;
                return (
                  <View key={item.label} style={styles.listItem}>
                    <Text style={styles.listItemHeader}>
                      <FormattedMessage
                        {...interruptionMsg[
                          capitalizeWithPascalCase(item.label)
                        ]}
                      />
                    </Text>
                    <Text style={styles.listItemValue}>{item.value}</Text>
                  </View>
                );
              },
            )}
            {reportData.interruptions?.length === 0 ? (
              <View style={styles.listItemNoBorder}>
                <Text style={styles.listItemValue}>No interruptions</Text>
              </View>
            ) : null}
          </View>
        </View>

        {reportData.type === "Predefined" && !reportData?.mapError ? (
          <Text style={styles.detailsTxt} break>
            <FormattedMessage
              id="uvd.report.positions"
              defaultMessage="Positions"
            />
          </Text>
        ) : null}
        {reportData.type === "Predefined" &&
        !reportData?.mapError &&
        (reportData.positions.completed.length > 0 ||
          reportData.positions.failed.length > 0 ||
          reportData.positions.remaining.length > 0) ? (
          <View style={styles.positionWrapper}>
            {reportData.positions.completed.length > 0 ? (
              <Text style={styles.positionHeader}>
                <FormattedMessage
                  id="uvd.report.disinfected"
                  defaultMessage="Disinfected"
                />
              </Text>
            ) : null}
            {reportData.positions.completed.map((disinfectionPosition: any) => (
              <View
                wrap={false}
                key={disinfectionPosition.uuid}
                style={styles.positionListItem}
              >
                <View style={styles.positionIconContainer}>
                  <View style={styles.positionIconImage}>
                    <DisinfectedPositionIcon />
                  </View>
                  <Text style={styles.positionIconText}>
                    {disinfectionPosition.order}
                  </Text>
                </View>
                <Text style={styles.positionText}>
                  {getPositionText(disinfectionPosition.name || "")}
                </Text>
              </View>
            ))}
            {reportData.positions.failed.length > 0 ? (
              <Text style={styles.positionHeader}>
                <FormattedMessage
                  id="uvd.report.failed"
                  defaultMessage="Failed to disinfect"
                />
              </Text>
            ) : null}
            {reportData.positions.failed.map((disinfectionPosition: any) => (
              <View
                wrap={false}
                key={disinfectionPosition.uuid}
                style={styles.positionListItem}
              >
                <View style={styles.positionIconContainer}>
                  <View style={styles.positionIconImage}>
                    <FailedPositionIcon />
                  </View>
                  <Text style={styles.positionIconText}>
                    {disinfectionPosition.order}
                  </Text>
                </View>
                <Text style={styles.positionText}>
                  {getPositionText(disinfectionPosition.name || "")}
                </Text>
              </View>
            ))}
            {reportData.positions.remaining.length > 0 ? (
              <Text style={styles.positionHeader}>
                <FormattedMessage
                  id="uvd.report.remaining"
                  defaultMessage="Remaining"
                />
              </Text>
            ) : null}
            {reportData.positions.remaining.length > 0
              ? reportData.positions.remaining.map(
                  (disinfectionPosition: any) => (
                    <View
                      wrap={false}
                      key={disinfectionPosition.uuid}
                      style={styles.positionListItem}
                    >
                      <View style={styles.positionIconContainer}>
                        <View style={styles.positionIconImage}>
                          <RemainingPositionIcon />
                        </View>
                        <Text style={styles.remainingPositionIconText}>
                          {disinfectionPosition.order}
                        </Text>
                      </View>
                      <Text style={styles.positionText}>
                        {getPositionText(disinfectionPosition.name || "")}
                      </Text>
                    </View>
                  ),
                )
              : null}
          </View>
        ) : null}

        <View fixed style={styles.footerWrapper}>
          <View style={styles.logoWrapper}>
            <View style={styles.logoLine}></View>
            <Image src={borLogo} style={styles.footerLogo} />
            <View style={styles.logoLine}></View>
          </View>
          <View style={styles.pageNumberWrapper}>
            <Text
              style={styles.pageNumber}
              render={({
                pageNumber,
                totalPages,
              }: {
                pageNumber: number;
                totalPages: number;
              }) => `${pageNumber} of ${totalPages}`}
            />
          </View>
        </View>
      </Page>
    </IntlProvider>
  </Document>
);

async function downloadPdf(
  reportData: any,
  onPDFReady: any,
  mapImgSrc?: ImageData | undefined,
) {
  const pdfInstance = pdf();
  pdfInstance.updateContainer(
    getDocument(
      reportData,
      mapImgSrc ? getImageUrlFromImageData(mapImgSrc) : undefined,
    ),
  );
  const pdfBlob = await pdfInstance.toBlob();

  console.log("REPORT DATA ---->", reportData);

  onPDFReady();

  const fileNameArr = [
    "UVD-Robots-Disinfection",
    format(new Date(reportData.end), "yyMMdd"),
    format(new Date(reportData.end), "HHmmss"),
    reportData.room === "" ? null : reportData.room,
    reportData.department === "" ? null : reportData.department,
    reportData.robot?.robot?.serialNumber ||
      getUVDSerialNumber(reportData.robotId),
  ];

  saveAs(
    URL.createObjectURL(pdfBlob),
    fileNameArr.filter((item) => !!item).join("_"),
  );
}

export { downloadPdf };
