import { useMutation } from "@apollo/client";
import { Box } from "@mui/material";
import { OrganizationCardContent } from "components/modules/cards/OrganizationCardContent";
import { CloudDrawer, CloudDrawerProps } from "components/modules/CloudDrawer";
import { FmRoles } from "components/modules/FmRoles";
import { ORG_USER_LIST_QUERY } from "components/pages/OrganizationUserListPage";
import { SUPPORT_USER_LIST_QUERY } from "components/pages/SupportUserListPage";
import { USER_QUERY } from "components/pages/UserPage";
import { useFormik } from "formik";
import { Identity, Role, RoleType } from "gql/graphql";
import _ from "lodash";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { hasSupportRole, isSupportPath } from "utils/privileges/privilegeUtils";
import { useUserDisplayName } from "utils/useUserDisplayName";
import * as Yup from "yup";
import { INVITE } from "../dialogs/InviteUser";
import { ORGUSER_QUERY } from "../organizationWidgets/OrgUsers";
import { REVOKE } from "./EditSupportRole";

interface Props {
  open: CloudDrawerProps["open"];
  onClose: CloudDrawerProps["onClose"];
  selectedAffiliation: any;
  identity: Identity;
}

type Values = {
  role: string;
};

export const EditOrganizationRole = ({
  open,
  onClose,
  selectedAffiliation,
  identity,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const supportPath = isSupportPath(location);
  const displayName = useUserDisplayName(identity);

  const isSupport = hasSupportRole(identity);
  const isLastAffiliation =
    (identity?.affiliations ?? []).length === 1 && !isSupport;
  const defaultRole = _.first(selectedAffiliation.roles) as Role;

  const [inviteUser] = useMutation(INVITE, {
    refetchQueries: [
      {
        query: ORGUSER_QUERY,
        variables: {
          orgId: selectedAffiliation.organization?.id,
        },
      },
      {
        query: USER_QUERY,
        variables: {
          userId: identity?.id,
        },
      },
      { query: SUPPORT_USER_LIST_QUERY },
      {
        query: ORG_USER_LIST_QUERY,
        variables: {
          orgId: selectedAffiliation?.organization?.id,
        },
      },
    ],
  });

  const [revokeUserRole] = useMutation(REVOKE, {
    refetchQueries: [
      {
        query: USER_QUERY,
        variables: {
          userId: identity?.id,
        },
      },
    ],
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  useEffect(() => {
    formik.setFieldValue("role", defaultRole?.title || "");
  }, [defaultRole]);

  const formik = useFormik<Values>({
    initialValues: {
      role: defaultRole?.title || "",
    },
    validationSchema: Yup.object({
      role: Yup.string().required(
        intl.formatMessage({
          id: "required",
          defaultMessage: "Required",
        }),
      ),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Updating role...", {
        variant: "loading",
        persist: true,
      });
      handleClose();

      inviteUser({
        variables: {
          inviteInputs: {
            email: identity.email,
            role: values.role as RoleType,
          },
          orgId: selectedAffiliation.organization.id,
        },
        onCompleted: (data) => {
          enqueueSnackbar(`Updated role for ${displayName}`, {
            variant: "success",
            actionLabel: "Go to user page",
            onActionClick: () => {
              if (supportPath) {
                navigate("/cloud/support/users/" + data.invite[0].id);
              } else {
                navigate(
                  `/cloud/fm/${selectedAffiliation.organization.id}/users/${data.invite[0].id}`,
                );
              }
            },
          });
          closeSnackbar(snackbarId);
        },
        onError: () => {
          enqueueSnackbar(`Could not update role for ${displayName}`, {
            variant: "error",
          });
          closeSnackbar(snackbarId);
        },
      });
    },
  });

  const handleRevokeRole = () => {
    const snackbarId = enqueueSnackbar("Removing role...", {
      variant: "loading",
      persist: true,
    });
    handleClose();

    revokeUserRole({
      variables: {
        userId: identity.id,
        role: defaultRole.title as RoleType,
        orgId: selectedAffiliation.organization.id,
      },
      onCompleted: () => {
        enqueueSnackbar(
          isLastAffiliation
            ? `Removed ${displayName} from the system`
            : `Removed ${displayName} from ${selectedAffiliation.organization?.name}`,
          {
            variant: "success",
          },
        );
        if (isLastAffiliation) {
          if (supportPath) {
            navigate("/cloud/support/users");
          } else {
            navigate(`/cloud/fm/${selectedAffiliation.organization.id}/users`);
          }
        }

        closeSnackbar(snackbarId);
      },
      onError: () => {
        enqueueSnackbar(
          `Could not remove ${displayName} role from ${selectedAffiliation.organization?.name}`,
          {
            variant: "error",
          },
        );
        closeSnackbar(snackbarId);
      },
    });
  };

  const getAlertLabel = () => {
    if (isLastAffiliation) {
      return "This action cannot be undone.";
    }
    return undefined;
  };

  return (
    <form>
      <CloudDrawer
        open={open}
        onClose={handleClose}
        header="Edit role"
        action={{
          label: "Save changes",
          onClick: formik.handleSubmit,
          disabled: formik.values.role === defaultRole.title || !formik.isValid,
        }}
        secondaryAction={{
          label: "Remove user from organization",
          onClick: handleRevokeRole,
          color: "error",
        }}
        alertLabel={getAlertLabel()}
      >
        <Box>
          <OrganizationCardContent
            organization={selectedAffiliation.organization}
            header={selectedAffiliation.organization?.name || "-"}
            description={selectedAffiliation.roles
              .map((item: Role) => item.title)
              .join(", ")}
            wrapperStyle={{ padding: "8px 0px" }}
            hideIcon
          />
        </Box>
        <FmRoles
          organizationId={selectedAffiliation.organization.id}
          radioGroupProps={{
            onBlur: () => formik.setFieldTouched("role"),
          }}
          value={formik.values.role}
          onChange={(role: any) => formik.setFieldValue("role", role)}
        />
      </CloudDrawer>
    </form>
  );
};
