import { Box, Button, IconButton, Typography } from "@mui/material";
import { ReactElement, useMemo } from "react";
import {
  IoChevronForward,
  IoListOutline,
  IoSettingsOutline,
} from "react-icons/io5";

type CloudCardContentIconType = "navigate" | "settings" | "remove" | "list";

export interface CloudCardContentProps {
  header?: string | ReactElement;
  description?: string | ReactElement;
  avatar?: ReactElement;
  hideAvatar?: boolean;
  iconType?: CloudCardContentIconType;
  wrapperStyle?: any;
  hideIcon?: boolean;
  hideDescription?: boolean;
  onIconClick?: () => void;
  size?: "small";
}

export const CloudCardContent = ({
  header,
  description,
  avatar,
  hideAvatar,
  iconType,
  wrapperStyle,
  hideIcon,
  onIconClick,
  size,
}: CloudCardContentProps) => {
  const icon = useMemo(() => {
    switch (iconType) {
      case "settings":
        return <IoSettingsOutline size={18} />;
      case "remove":
        return <Button variant="text">Remove</Button>;
      case "list":
        return <IoListOutline size={18} />;
      case "navigate":
      default:
        return <IoChevronForward size={18} />;
    }
  }, [iconType]);

  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        alignSelf: "center",
        padding: "16px",
        flexGrow: 1,
        ...wrapperStyle,
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
        }}
      >
        {hideAvatar ? null : avatar}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography component="span">{header}</Typography>
          {description ? (
            <Typography
              flexWrap={"wrap"}
              component="span"
              variant="body2"
              fontSize={size === "small" ? "10px" : undefined}
              lineHeight={size === "small" ? "12px" : undefined}
              color={(theme) => theme.palette.grey[600]}
            >
              {description}
            </Typography>
          ) : null}
        </Box>
      </Box>
      {hideIcon ? null : (
        <IconButton
          onClick={onIconClick}
          sx={(theme) => ({
            svg: {
              height: 18,
              width: 18,
            },
            "& svg > *": {
              color: theme.palette.grey[600],
            },
            marginLeft: 2,
          })}
        >
          {icon}
        </IconButton>
      )}
    </Box>
  );
};
