import { Dialog } from "@mui/material";
import { CloudDialogContent } from "components/atoms/CloudDialogContent";
import {
  CloudDialogFooter,
  CloudDialogFooterProps,
} from "components/atoms/CloudDialogFooter";
import { DrawerHeader } from "components/atoms/drawer/DrawerHeader";
import { CloudHorizontalStepper } from "components/atoms/stepper/CloudHorizontalStepper";
import { CloudStepperProps } from "components/atoms/stepper/CloudStepper";
import { ReactElement } from "react";

interface CloudStepsDialogProps {
  open: boolean;
  onClose: () => void;
  header: string | ReactElement;
  steps: CloudStepperProps["steps"];
  stepIndex: number;
  children?: ReactElement[] | ReactElement;
  contentStyle?: any;
  hideFooter?: boolean;
}

export const CloudStepsDialog = ({
  open,
  steps,
  onClose,
  hideFooter,
  stepIndex,
  header,
  ...footerProps
}: CloudStepsDialogProps & CloudDialogFooterProps) => {
  return (
    <form>
      <Dialog open={open} fullWidth maxWidth={"sm"} onClose={onClose}>
        <DrawerHeader
          header={header}
          description={(steps[stepIndex]?.description as string) || undefined}
          onClose={onClose}
          wrapperStyle={{ paddingBottom: 0 }}
        />
        <CloudDialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <CloudHorizontalStepper
            wrapperStyle={{ alignSelf: "center", margin: "32px 0px" }}
            stepIndex={stepIndex}
            steps={steps}
          />
          {steps[stepIndex]?.component?.()}
        </CloudDialogContent>
        {hideFooter ? null : (
          <CloudDialogFooter
            {...footerProps}
            submitLabel={steps[stepIndex]?.submitLabel}
            onSubmit={steps[stepIndex]?.onSubmit}
            submitDisabled={steps[stepIndex]?.submitDisabled}
            secondaryButtonLabel={steps[stepIndex]?.secondaryButtonLabel}
            onSecondaryButtonClick={steps[stepIndex]?.onSecondaryButtonClick}
            loading={steps[stepIndex]?.loading}
          />
        )}
      </Dialog>
    </form>
  );
};
