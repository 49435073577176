import { Grid, Skeleton } from "@mui/material";
import { InlineCardEmptyView } from "components/atoms/InlineCardEmptyView";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { RoleCard } from "components/modules/cards/RoleCard";
import { Affiliation, Role, RoleType } from "gql/graphql";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { roles } from "trans/definedMessages/roleMessages";
import { isSupportPath } from "utils/privileges/privilegeUtils";
import { InviteUser } from "../dialogs/InviteUser";
import { EditOrganizationRole } from "../panels/EditOrganizationRole";

export const UserOrganizationRoles = ({
  identity,
  currentUserIsSupport,
  readonly,
  userIsMe,
  loading,
}: {
  identity: any;
  currentUserIsSupport: boolean;
  readonly?: boolean;
  userIsMe?: boolean;
  loading?: boolean;
}) => {
  const { orgId } = useParams();
  const location = useLocation();
  const intl = useIntl();

  const [editOrgRoleOpen, setEditOrgRoleOpen] = useState(false);
  const [selectedAffiliation, setSelectedAffiliation] = useState(null);
  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);

  const isOnSupportPath = isSupportPath(location);
  const showAllOrgs = isOnSupportPath || currentUserIsSupport || userIsMe;

  const affiliations = useMemo(() => {
    if (showAllOrgs) {
      return identity?.affiliations;
    } else {
      return identity?.affiliations?.filter(
        (item: Affiliation) => item.organization.id === orgId,
      );
    }
  }, [showAllOrgs, identity?.affiliations]);

  const currentOrg = orgId
    ? (_.first(affiliations) as Affiliation)?.organization
    : null;

  const otherAffiliationsText = useMemo(() => {
    if (identity?.affiliations?.length - 1 > 0 && !showAllOrgs) {
      return `+${
        identity?.affiliations?.length - 1
      } other Roles outside ${currentOrg?.name}`;
    } else return null;
  }, [affiliations, currentOrg]);

  if (loading) return <Skeleton />;

  return (
    <>
      <WidgetPaper>
        <WidgetHeader
          label="Organizations"
          buttonLabel={readonly || userIsMe ? undefined : "Add"}
          onButtonClick={
            readonly ? undefined : () => setInviteUserDialogOpen(true)
          }
        />
        <Grid container mt={0} spacing={4} display="flex">
          {affiliations?.length > 0 ? (
            affiliations?.map((item: any) => (
              <Grid
                key={item?.organization?.id}
                item
                xs={6}
                display="flex"
                sx={{ "& .MuiGrid-root": { flexGrow: 1 } }}
              >
                <RoleCard
                  disabled={readonly}
                  header={item.organization?.name}
                  description={item.roles
                    .map((item: Role) =>
                      intl.formatMessage(roles[item.title as RoleType]),
                    )
                    .join(", ")}
                  iconType="settings"
                  onClick={() => {
                    setSelectedAffiliation(item);
                    setEditOrgRoleOpen(true);
                  }}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12} display="flex">
              <InlineCardEmptyView
                sx={{ width: "100%", maxWidth: "100%" }}
                label="No organizations"
              />
            </Grid>
          )}
          {otherAffiliationsText ? (
            <Grid item xs={6} display="flex">
              <InlineCardEmptyView
                sx={{ width: "100%", maxWidth: "100%" }}
                label={otherAffiliationsText}
              />
            </Grid>
          ) : null}
        </Grid>
      </WidgetPaper>
      {selectedAffiliation ? (
        <EditOrganizationRole
          open={editOrgRoleOpen}
          onClose={() => setEditOrgRoleOpen(false)}
          selectedAffiliation={selectedAffiliation}
          identity={identity}
        />
      ) : null}
      {inviteUserDialogOpen ? (
        <InviteUser
          identity={identity}
          organization={currentOrg}
          open={inviteUserDialogOpen}
          onClose={() => setInviteUserDialogOpen(false)}
          currentUserIsSupport={currentUserIsSupport}
        />
      ) : null}
    </>
  );
};
