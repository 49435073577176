import { UVDRobotIcon } from "assets/icons/UVDRobotIcon";
import { MenuItem } from "components/atoms/dashboard/menuItems/MenuItem";
import { MenuTitle } from "components/atoms/dashboard/menuItems/MenuTitle";
import { graphql } from "gql";
import { RoleType } from "gql/graphql";
import { IoGridOutline, IoList, IoPersonOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

const QUERY = graphql(`
  query OrganizationMenuItem($orgId: String!, $filter: DisinfectionFilter) {
    organization(id: $orgId) {
      name
      robotAffiliations {
        active {
          id
        }
      }
      affiliations {
        id
      }
    }
    disinfectionRooms(filter: $filter) {
      room
    }
  }
`);

export const OrganizationMenuItem = (affiliation: any) => {
  const { data } = useQueryWithSnack(QUERY, {
    variables: {
      orgId: affiliation.organization.id || "",
      filter: {
        organizationIds: [affiliation.organization.id || ""],
      },
    },
    skip: !affiliation.organization.id,
  });

  const isViewer = affiliation.roles[0].title === RoleType.Viewer;
  const activeRobotAffs = data?.organization.robotAffiliations.filter(
    (item) => item.active !== null,
  );

  return (
    <>
      <MenuTitle
        title={affiliation.organization.name || ""}
        orgId={affiliation.organization.id}
        hideSettings={isViewer}
      />
      <NavLink
        to={`fm/${affiliation.organization.id}/disinfections`}
        style={{
          textDecoration: "none",
        }}
      >
        {({ isActive }) => {
          return (
            <MenuItem
              hideCount
              isActive={isActive}
              value="Disinfections"
              icon={<IoGridOutline style={{ height: 20, width: 20 }} />}
            />
          );
        }}
      </NavLink>
      <NavLink
        to={`fm/${affiliation.organization.id}/rooms`}
        style={{
          textDecoration: "none",
          pointerEvents:
            data?.disinfectionRooms?.length === 0 ? "none" : "auto",
        }}
      >
        {({ isActive }) => {
          return (
            <MenuItem
              isActive={isActive}
              value="Rooms"
              icon={<IoList style={{ height: 20, width: 20 }} />}
              count={data?.disinfectionRooms?.length}
              disabled={data?.disinfectionRooms?.length === 0}
            />
          );
        }}
      </NavLink>
      {isViewer ? null : (
        <NavLink
          to={`fm/${affiliation.organization.id}/users`}
          style={{
            textDecoration: "none",
            pointerEvents:
              data?.organization.affiliations?.length === 0 ? "none" : "auto",
          }}
        >
          {({ isActive }) => {
            return (
              <MenuItem
                isActive={isActive}
                value="Users"
                icon={<IoPersonOutline style={{ height: 20, width: 20 }} />}
                count={(data?.organization.affiliations || [])?.length || 0}
                disabled={(data?.organization.affiliations || [])?.length === 0}
              />
            );
          }}
        </NavLink>
      )}
      {isViewer ? null : (
        <NavLink
          to={`fm/${affiliation.organization.id}/robots`}
          style={{
            textDecoration: "none",
            pointerEvents: activeRobotAffs?.length === 0 ? "none" : "auto",
          }}
        >
          {({ isActive }) => {
            return (
              <MenuItem
                isActive={isActive}
                value="Robots"
                icon={<UVDRobotIcon />}
                count={activeRobotAffs?.length || 0}
                disabled={activeRobotAffs?.length === 0}
              />
            );
          }}
        </NavLink>
      )}
    </>
  );
};
