import { Box, Typography } from "@mui/material";
import { graphql } from "gql";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

const QUERY = graphql(`
  query RobotDisinfectionCountCell($robotSerialNumber: String!) {
    disinfections(filter: { serialNumbers: [$robotSerialNumber] }) {
      count
    }
  }
`);

export const RobotDisinfectionCountCell = ({ robot, hideDescription }: any) => {
  const { data } = useQueryWithSnack(QUERY, {
    variables: { robotSerialNumber: robot.serialNumber },
  });

  return (
    <Box>
      <Typography>{data?.disinfections.count} Disinfection cycles</Typography>
      {hideDescription ? null : (
        <Typography
          fontSize="10px"
          variant="bodyS"
          color={(theme) => theme.palette.grey[600]}
        >
          Historic data
        </Typography>
      )}
    </Box>
  );
};
