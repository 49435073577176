import { useMutation } from "@apollo/client";
import { RadioGroup } from "@mui/material";
import { OrganizationAutoComplete } from "components/atoms/autoComplete/OrganizationAutoComplete";
import { RadioButton } from "components/atoms/buttons/RadioButton";
import { CloudLabel } from "components/atoms/CloudLabel";
import { FormikTextField } from "components/atoms/formik";
import { CloudDrawer, CloudDrawerProps } from "components/modules/CloudDrawer";
import { useFormik } from "formik";
import { graphql } from "gql";
import { Organization, RobotType } from "gql/graphql";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { enumMsg } from "trans/definedMessages/enumMessages";
import * as Yup from "yup";
import { ASSIGNED_ROBOTS_QUERY } from "../AssignedRobots";
import { UNASSIGNED_ROBOTS_QUERY } from "../UnassignedRobots";

const TRANSFER_ROBOT = graphql(`
  mutation AssignRobot(
    $robotId: String!
    $orgId: String
    $robotType: RobotType
    $robotJournalLink: String
  ) {
    transferRobot(
      id: $robotId
      organizationId: $orgId
      robotType: $robotType
      journalPage: $robotJournalLink
    ) {
      id
    }
  }
`);

const robotTypes = [
  RobotType.Tuv,
  RobotType.Pharma,
  RobotType.Eu,
  RobotType.Maritime,
];

interface Props {
  robot: any;
  open: CloudDrawerProps["open"];
  onClose: CloudDrawerProps["onClose"];
}

type Values = {
  organization: Partial<Organization> | undefined;
  robotType: RobotType | string;
  journalLink?: string;
};

export const AssignRobotToOrganization = ({ open, onClose, robot }: Props) => {
  const intl = useIntl();

  const [updateRobot] = useMutation(TRANSFER_ROBOT, {
    refetchQueries: [UNASSIGNED_ROBOTS_QUERY, { query: ASSIGNED_ROBOTS_QUERY }],
  });

  useEffect(() => {
    (() => formik.validateForm())();
  }, []);

  const formik = useFormik<Values>({
    initialValues: {
      organization: undefined,
      robotType: "",
      journalLink: "",
    },
    validationSchema: Yup.object({
      organization: Yup.object().required(
        intl.formatMessage({
          id: "required",
          defaultMessage: "Required",
        }),
      ),
      robotType: Yup.string(),
      journalLink: Yup.string().url(
        intl.formatMessage({
          id: "login.invalidUrl",
          defaultMessage: "Invalid URL",
        }),
      ),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Assigning robot to organization...", {
        variant: "loading",
        persist: true,
      });
      onClose();

      updateRobot({
        variables: {
          robotId: robot?.id || "",
          orgId: values.organization?.id ?? "",
          robotType: (values.robotType as RobotType) || undefined,
          robotJournalLink:
            values.journalLink !== "" ? values.journalLink?.trim() : undefined,
        },
        onCompleted: () => {
          enqueueSnackbar(`Robot was assigned`, {
            variant: "success",
          });
          closeSnackbar(snackbarId);
        },
        onError: () => {
          enqueueSnackbar(`Robot could not be assigned`, {
            variant: "error",
          });
          closeSnackbar(snackbarId);
        },
      });
    },
  });

  useEffect(() => {
    formik.setFieldValue("robotType", RobotType.Tuv);
  }, []);

  return (
    <form>
      <CloudDrawer
        open={open}
        onClose={onClose}
        header={`Assign robot ${robot?.serialNumber}`}
        action={{
          label: "Assign",
          disabled: formik.isValid === false,
          onClick: formik.handleSubmit,
        }}
      >
        <CloudLabel>To organization</CloudLabel>
        <OrganizationAutoComplete
          multiple={false}
          value={formik.values.organization}
          onChange={(e: any, value: Organization) => {
            formik.setFieldValue("organization", value);
          }}
          placeholder="Select organization"
          onBlur={() => formik.setFieldTouched("organization")}
          textfieldprops={{
            error: formik.touched.organization && formik.errors.organization,
            helperText:
              formik.touched.organization && formik.errors.organization
                ? formik.errors.organization
                : "The organization will be able to see the robot right away in their Fleet Management. The robot will need Technical Service before their disinfections will sync to the Cloud.",
          }}
        />
        <CloudLabel>Robot type</CloudLabel>
        <RadioGroup
          name="robotType"
          value={formik.values.robotType}
          onChange={(e: any) =>
            formik.setFieldValue("robotType", e.target.value)
          }
          onBlur={() => formik.setFieldTouched("robotType")}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          {robotTypes.map((item) => (
            <RadioButton
              key={item}
              label={intl.formatMessage(enumMsg[item])}
              value={item}
              style={{ maxHeight: "36px", padding: 0 }}
            />
          ))}
        </RadioGroup>
        <CloudLabel>Link to robot journal (optional)</CloudLabel>
        <FormikTextField
          name="journalLink"
          formik={formik}
          placeholder="Link to robot journal"
        />
      </CloudDrawer>
    </form>
  );
};
