import {
  Box,
  Button,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { OrganizationAvatar } from "components/atoms/avatars/OrganizationAvatar";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { graphql } from "gql";
import { useState } from "react";
import { IoOpenOutline, IoSettingsOutline } from "react-icons/io5";
import { useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { countryMsg } from "trans/definedMessages/countryMessages";
import { hasSupportRole, isSupportPath } from "utils/privileges/privilegeUtils";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { EditOrganizationBasicInfo } from "../panels/EditOrganizationBasicInfo";

export const ORG_BASIC_QUERY = graphql(`
  query OrganizationBasicInfo($orgId: String!) {
    organization(id: $orgId) {
      id
      name
      country
      customerPage
      customerRecordPage
      hubSpotPage
      robotAffiliations {
        id
        active {
          id
        }
        robot {
          status {
            lastTimeActive
          }
          serialNumber
        }
      }
    }
    me {
      supportRole {
        title
      }
    }
  }
`);

export const OrganizationBasicInfo = () => {
  const { orgId } = useParams();
  const location = useLocation();
  const intl = useIntl();

  const [editPanelOpen, setEditPanelOpen] = useState(false);

  const { data, loading } = useQueryWithSnack(ORG_BASIC_QUERY, {
    variables: { orgId: orgId || "" },
    skip: !orgId,
  });

  const isSupporter = hasSupportRole(data?.me);
  const isOnSupportPath = isSupportPath(location);
  const showSupport = isSupporter && isOnSupportPath;

  const organization = data?.organization;

  if (loading) return <Skeleton variant="rounded" height={150} />;

  return (
    <>
      <WidgetPaper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <OrganizationAvatar
              size="large"
              sx={{ marginRight: 4 }}
              orgId={organization?.id}
            />
            <Box width="75%">
              <Typography
                variant="h3"
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "Industry-Bold",
                  overflowWrap: "anywhere",
                }}
              >
                {organization?.name}
              </Typography>
              {organization?.country ? (
                <Typography
                  fontSize="16px"
                  lineHeight="inherit"
                  sx={{ overflowWrap: "anywhere" }}
                >
                  {intl.formatMessage(countryMsg[organization.country || ""])}
                </Typography>
              ) : null}
            </Box>
          </Box>
          <IconButton
            onClick={() => setEditPanelOpen(true)}
            sx={{
              alignSelf: "flex-start",
              marginRight: "-8px",
              marginTop: "-8px",
            }}
          >
            <IoSettingsOutline size={24} />
          </IconButton>
        </Box>
        {showSupport &&
        (organization?.customerPage ||
          organization?.customerRecordPage ||
          organization?.hubSpotPage) ? (
          <Grid container marginTop={4}>
            {data?.organization.customerPage ? (
              <Grid item xs={4} display="flex" justifyContent="center">
                <Button
                  target="_blank"
                  href={data?.organization.customerPage}
                  endIcon={<IoOpenOutline style={{ marginBottom: 2 }} />}
                  variant="text"
                >
                  Odoo
                </Button>
              </Grid>
            ) : null}
            {data?.organization.customerRecordPage ? (
              <Grid item xs={4} display="flex" justifyContent="center">
                <Button
                  target="_blank"
                  href={data?.organization.customerRecordPage}
                  endIcon={<IoOpenOutline style={{ marginBottom: 2 }} />}
                  variant="text"
                >
                  Confluence
                </Button>
              </Grid>
            ) : null}
            {data?.organization.hubSpotPage ? (
              <Grid item xs={4} display="flex" justifyContent="center">
                <Button
                  target="_blank"
                  href={data?.organization.hubSpotPage}
                  endIcon={<IoOpenOutline style={{ marginBottom: 2 }} />}
                  variant="text"
                >
                  HubSpot
                </Button>
              </Grid>
            ) : null}
          </Grid>
        ) : null}
      </WidgetPaper>
      {editPanelOpen ? (
        <EditOrganizationBasicInfo
          open={editPanelOpen}
          onClose={() => setEditPanelOpen(false)}
          organization={organization}
        />
      ) : null}
    </>
  );
};
