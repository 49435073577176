import { Box, Typography } from "@mui/material";
import { InlineCard } from "components/atoms/InlineCard";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { IoChevronForward } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isSupportPath } from "utils/privileges/privilegeUtils";
import { CyclesCounterChart } from "../charts/CyclesCounterChart";

export const OrgDisinfectionCount = ({ organization }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isOnSupportPath = isSupportPath(location);
  const { orgId } = useParams();

  return (
    <CyclesCounterChart
      showDisinfectionLink
      header={
        <FormattedMessage
          id="disinfections.last-month"
          defaultMessage="This Month's Disinfections"
        />
      }
      description={`Number of disinfection cycles in ${format(
        new Date(),
        "MMMM yyyy",
      )}`}
      filter={{
        timeSpan: {
          start: startOfMonth(new Date()),
          end: endOfMonth(new Date()),
        },
        organizationIds: orgId ? [orgId] : undefined,
      }}
      actionCardChildren={
        <Box mt={3}>
          <InlineCard
            onClick={() => {
              const basePath = isOnSupportPath
                ? "/cloud/support/disinfections"
                : `/cloud/fm/${orgId}/disinfections`;

              const path =
                basePath +
                "?filters=" +
                encodeURI(JSON.stringify({ organizationIds: [organization] }));

              navigate(path);
            }}
          >
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 4,
                width: "100%",
                "& svg > *": {
                  color: theme.palette.grey[600],
                },
              })}
            >
              <Typography>View Disinfections</Typography>
              <IoChevronForward size={18} />
            </Box>
          </InlineCard>
        </Box>
      }
    />
  );
};
