import { gql } from "@apollo/client";
import { AvatarProps } from "@mui/material";
import { Identity } from "gql/graphql";
import { useMemo } from "react";
import { IoPersonOutline } from "react-icons/io5";
import { AvatarSize } from "types/types";
import { getAvatarIconSize } from "utils/avatarUtils";
import { useUserDisplayName } from "utils/useUserDisplayName";
import { getInitials } from "utils/utilFunctions";
import { CloudAvatar } from "./CloudAvatar";

export interface CloudAvatarProps extends AvatarProps {
  size?: AvatarSize;
}

interface UserAvatarProps extends CloudAvatarProps {
  identity?: Partial<Identity>;
}

const USERAVATAR_FRAGMENT = gql`
  fragment UserAvatar on Identity {
    id
    firstName
    lastName
    email
    ...UserDisplayName
  }
  ${useUserDisplayName.fragments.identity}
`;

export const UserAvatar = ({
  children,
  size = "medium",
  identity,
  sx,
  onClick,
}: UserAvatarProps) => {
  const iconSize = getAvatarIconSize(size);
  const displayName = useUserDisplayName(identity);

  const initials = useMemo(() => {
    if (!identity?.firstName && !identity?.lastName) {
      return identity?.email?.slice(0, 2);
    }

    return getInitials(displayName || "");
  }, [identity]);

  return (
    <CloudAvatar
      seed={identity?.id}
      variant="circular"
      size={size}
      sx={sx}
      onClick={onClick}
    >
      {identity ? (
        initials
      ) : children ? (
        children
      ) : (
        <IoPersonOutline size={iconSize} />
      )}
    </CloudAvatar>
  );
};

UserAvatar.fragments = {
  identity: USERAVATAR_FRAGMENT,
};
