import {
  ExposureMapCamera,
  ExposureType,
  ThemeProvider as BorUiThemeProvider,
} from "@blue-ocean-robotics/bor_ui";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { WidgetWrapper } from "components/atoms/WidgetWrapper";
import { graphql } from "gql";
import { Disinfection } from "gql/graphql";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { getMapPositions, getMissingMapMessage } from "utils/UVD/UVDUtils";

const QUERY = graphql(`
  query ExposureMap(
    $robotId: String!
    $heatMapFileName: String!
    $navigationMapFileName: String!
  ) {
    requestHeatMap: requestFile(
      robotId: $robotId
      mapType: HeatMap
      fileName: $heatMapFileName
    )
    requestNavigationMap: requestFile(
      robotId: $robotId
      mapType: NavigationMap
      fileName: $navigationMapFileName
    )
  }
`);

const MapStateWrapper = styled(WidgetWrapper)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.grey[500],
  textAlign: "center",
  lineHeight: "20px",
  flexGrow: 1,
}));

const MapStateText = styled(Typography)(() => ({
  maxWidth: 350,
}));

export const ExposureMap = ({
  selectedReport,
  exportImage,
  mapStateWrapperStyle,
}: {
  selectedReport: Partial<Disinfection> | undefined;
  exportImage?: (callback: () => ImageData) => void;
  mapStateWrapperStyle?: any;
}) => {
  const { formattedMapPositions } = getMapPositions(selectedReport);

  const navMapFileName = selectedReport?.navigationMap?.navigationMapFile?.name;

  const { data, error } = useQueryWithSnack(QUERY, {
    variables: {
      robotId:
        selectedReport?.robot?.robot?.id ?? selectedReport?.robotId ?? "",
      heatMapFileName: selectedReport?.heatMap?.name ?? "",
      navigationMapFileName: navMapFileName ?? "",
    },
    skip: !selectedReport?.heatMap?.name || !navMapFileName,
  });

  const missingMapMessage = getMissingMapMessage(
    navMapFileName,
    selectedReport?.heatMap,
    selectedReport?.room,
    error,
  );

  if (missingMapMessage) {
    return (
      <MapStateWrapper style={mapStateWrapperStyle}>
        <MapStateText>{missingMapMessage}</MapStateText>
      </MapStateWrapper>
    );
  }

  return (
    <BorUiThemeProvider injectCssBaseline={false}>
      <ExposureMapCamera
        disinfectionPositions={formattedMapPositions || []}
        srcMap={data?.requestNavigationMap ?? ""}
        srcExposure={data?.requestHeatMap ?? ""}
        origin={selectedReport?.navigationMap?.origin ?? { x: 0, y: 0, yaw: 0 }}
        resolution={selectedReport?.navigationMap?.resolution ?? 0.05}
        exposureType={ExposureType.RawExposure}
        exportImage={exportImage}
      />
    </BorUiThemeProvider>
  );
};
