import { RobotPrivileges } from "@blue-ocean-robotics/cloud-library";
import { Box, Skeleton } from "@mui/material";
import { InlineCard } from "components/atoms/InlineCard";
import { InlineCardEmptyView } from "components/atoms/InlineCardEmptyView";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { CloudCardContent } from "components/modules/cards/CloudCardContent";
import { OrganizationCard } from "components/modules/cards/OrganizationCard";
import { graphql } from "gql";
import { UvdRobot } from "gql/graphql";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePrivilege } from "utils/privileges/usePrivileges";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { PrevAffiliations } from "../dialogs/PrevAffiliations";
import { AssignRobotToOrganization } from "../panels/AssignRobotToOrganization";
import { TransferRobot } from "../panels/TransferRobot";

export const ROBOT_ORGANIZATION_QUERY = graphql(`
  query RobotOrganization($robotId: String!) {
    robot(id: $robotId) {
      id
      serialNumber
      active {
        id
        organization {
          id
          name
          country
        }
      }
      affiliations {
        id
        createdAt
        active {
          id
        }
        organization {
          id
          name
        }
      }
    }
  }
`);

export const RobotOrganization = ({
  forbiddenOrg,
}: {
  forbiddenOrg?: boolean;
}) => {
  const { robotId } = useParams();
  const navigate = useNavigate();
  const canTransferRobot = usePrivilege(RobotPrivileges.Update).hasPrivilege;

  const [transferRobotOpen, setTransferRobotOpen] = useState(false);
  const [assignRobotOpen, setAssignRobotOpen] = useState(false);
  const [prevAffiliationsOpen, setPrevAffiliationsOpen] = useState(false);

  const { data, loading } = useQueryWithSnack(ROBOT_ORGANIZATION_QUERY, {
    variables: { robotId: robotId ?? "" },
    skip: !robotId,
  });

  const previousRobotAffiliations = useMemo(() => {
    if ((data?.robot?.affiliations || []).length > 0) {
      return (data?.robot?.affiliations || []).filter(
        (item) => item.id !== data?.robot?.active?.id,
      );
    } else {
      return [];
    }
  }, [data?.robot]);

  const prevAffiliationsText = useMemo(() => {
    const firstAff = _.first(previousRobotAffiliations);
    const prevLength = previousRobotAffiliations.length - 1;

    return (
      firstAff?.organization.name +
      (prevLength > 0 ? ` + ${prevLength} more` : "")
    );
  }, [previousRobotAffiliations]);

  const handleButtonClick = () => {
    if (orgMissing) {
      setAssignRobotOpen(true);
    } else {
      setTransferRobotOpen(true);
    }
  };

  const robot = data?.robot as UvdRobot;

  const orgMissing = !robot?.active?.organization;

  if (loading) return <Skeleton />;

  return (
    <>
      <WidgetPaper>
        <WidgetHeader
          label={"Organization"}
          wrapperStyle={{ marginBottom: 4 }}
          buttonLabel={
            forbiddenOrg
              ? ""
              : canTransferRobot
                ? orgMissing
                  ? "Assign to organization"
                  : "Transfer to other organization"
                : ""
          }
          onButtonClick={handleButtonClick}
          buttonVariant={orgMissing ? "contained" : "outlined"}
        />
        {forbiddenOrg ? (
          <InlineCardEmptyView
            sx={{ flex: 1 }}
            label="You do not have permission to view this organization"
          />
        ) : robot?.affiliations.length > 0 ? (
          <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <Box style={{ display: "flex", flex: 1 }}>
              {robot?.active !== null ? (
                <OrganizationCard
                  inlineCardStyle={{ flex: 1 }}
                  organization={robot?.active?.organization}
                  onClick={() =>
                    navigate(
                      `/cloud/support/organizations/${robot?.active?.organization.id}`,
                    )
                  }
                />
              ) : (
                <InlineCardEmptyView
                  sx={{ flex: 1 }}
                  label="No organization assigned"
                />
              )}
            </Box>
            {previousRobotAffiliations.length > 0 ? (
              <Box style={{ display: "flex", flex: 1 }}>
                <InlineCard
                  style={{ flex: 1 }}
                  onClick={() => setPrevAffiliationsOpen(true)}
                >
                  <CloudCardContent
                    header="Previous Owners"
                    description={prevAffiliationsText}
                    iconType="list"
                  />
                </InlineCard>
              </Box>
            ) : null}
          </Box>
        ) : (
          <InlineCardEmptyView label="No organization assigned" />
        )}
      </WidgetPaper>
      {transferRobotOpen ? (
        <TransferRobot
          robot={robot}
          open={transferRobotOpen}
          onClose={() => setTransferRobotOpen(false)}
        />
      ) : null}
      {assignRobotOpen ? (
        <AssignRobotToOrganization
          robot={robot}
          open={assignRobotOpen}
          onClose={() => setAssignRobotOpen(false)}
        />
      ) : null}
      {prevAffiliationsOpen ? (
        <PrevAffiliations
          affiliations={previousRobotAffiliations}
          open={prevAffiliationsOpen}
          onClose={() => setPrevAffiliationsOpen(false)}
        />
      ) : null}
    </>
  );
};
