import { Box } from "@mui/material";
import { CustomCheck, CustomChecked } from "assets/icons/CheckboxIcons";
import { IoClose } from "react-icons/io5";
import { fontFamilies } from "../assets/fonts/fontFamilies";
import { CloudGrey, CloudShadows, CloudThemeOptions } from "./themeTypes";

const greyPalette: CloudGrey = {
  900: "#181C32",
  800: "#3f4254",
  700: "#5E6278",
  600: "#7E8299",
  500: "#A1A5B7",
  400: "#B5B5C3",
  350: "#CBCCD7",
  300: "#E1E3EA",
  200: "#F4F4F4",
  100: "#F9F9F9",
};

const breakpoints = {
  xs: 0, // 0 - 576 px
  sm: 576, // 576 - 767 px
  md: 768, // 768 - 991 px
  lg: 992, // 992 - 1199 px
  xl: 1200, // 1200 - 1399 px
  xxl: 1400, // 1400px -
};

const shadows: CloudShadows = {
  cloudShadows: [
    "none",
    "0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.04)", // base
    "0px 0px 1px 0px rgba(36, 37, 37, 0.04), 0px 2px 6px 0px rgba(36, 37, 37, 0.04), 0px 10px 20px 0px rgba(36, 37, 37, 0.04);", // medium
    "0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06);", // large
  ],
};

const primaryPalette = {
  main: "#009EF7",
  light: "#EEF6FF",
  dark: "#005D91",
  hover: "#0095E8",
  third: "rgba(0, 158, 247, 0.30)",
};

const errorPalette = {
  // "DANGER"
  main: "#F1416C",
  light: "#FFF5F8",
  dark: "#B02A37",
  contrastText: "#FFFFFF",
  hover: "#D9214E",
  third: "rgba(241, 65, 108, 0.3)",
};

export const defaultTheme: CloudThemeOptions = {
  palette: {
    primary: primaryPalette,
    secondary: primaryPalette,
    success: {
      main: "#50cd89",
      light: "#e8fff3",
      dark: "#146c43",
      contrastText: "#FFFFFF",
      hover: "#47be7d",
      third: "rgba(80, 205, 137, 0.3)",
    },
    warning: {
      main: "#FFC700",
      light: "#fff8dd",
      dark: "#997404",
      contrastText: "#242525",
      hover: "#F1BC00",
      third: "rgba(255, 199, 0, 0.3)",
    },
    error: errorPalette,
    info: {
      main: "#7239EA",
      light: "#F8F5FF",
      dark: "#3D1F80",
      contrastText: "#FFFFFF",
      hover: "#5014D0",
      third: "rgba(114, 57, 234, 0.3)",
    },
    grey: greyPalette,
    text: {
      primary: greyPalette[900],
      secondary: greyPalette[500],
      disabled: greyPalette[400],
    },
    background: {
      paper: "#FFFFFF",
      page: "#F6F6F6",
      card: "#FFFFFF",
      default: "#F6F6F6",
    },
    battery: {
      red: "#A81B14",
      yellow: "#FFC400",
      green: "rgba(7, 137, 43)",
      grey: "#AAAAAA",
    },
    light: {
      main: "#FFF",
      light: "#FFF",
      dark: "#FFF",
      hover: "#FFF",
      third: "#FFF",
    },
  },
  typography: {
    fontFamily: fontFamilies.roboto,
    fontSize: 14,
    htmlFontSize: 14,
    h1: {
      // Heading XL
      fontFamily: fontFamilies.industryDemi,
      fontSize: "2.85rem" as unknown as number, // 40px
      lineHeight: "3.71rem" as unknown as number, // 52px
      margin: 0,
      padding: 0,
    },
    h2: {
      // Heading L
      fontFamily: fontFamilies.industryDemi,
      fontSize: "2rem" as unknown as number, // 28px
      lineHeight: "3.42rem" as unknown as number, // 48px
      margin: 0,
      padding: 0,
    },
    h3: {
      // Heading
      fontFamily: fontFamilies.industryMedium,
      fontSize: "1.71rem" as unknown as number, // 24px
      lineHeight: "2.28rem" as unknown as number, // 32px
    },
    h4: {
      // Heading M
      fontFamily: fontFamilies.industryDemi,
      fontSize: "1.21rem" as unknown as number, // 17px
      lineHeight: "1.71rem" as unknown as number, // 24px
      margin: 0,
      padding: 0,
    },
    h5: {
      // Heading S
      fontFamily: fontFamilies.industryDemi,
      fontSize: "1rem" as unknown as number, // 14px
      lineHeight: "1.42rem" as unknown as number, // 20px
      fontStyle: "normal",
    },
    subtitle1: {
      // Heading S Upper
      fontFamily: fontFamilies.industryDemi,
      fontSize: "1rem" as unknown as number, // 14px
      lineHeight: "1.42rem" as unknown as number, // 20px
      textTransform: "uppercase",
    },
    subtitle2: {
      // Heading XS
      fontFamily: fontFamilies.industryMedium,
      fontSize: "0.85rem" as unknown as number, // 12px
      lineHeight: "1.42rem" as unknown as number, // 20px
    },
    body1: {
      // Body
      fontFamily: fontFamilies.roboto,
      fontSize: "1rem" as unknown as number, // 14px
      lineHeight: "1.42rem" as unknown as number, // 20px
    },
    body2: {
      // Body M
      fontFamily: fontFamilies.roboto,
      fontSize: "0.85rem" as unknown as number, // 12px
      lineHeight: "1.14rem" as unknown as number, // 16px
    },
    bodyS: {
      fontFamily: fontFamilies.roboto,
      fontSize: "0.71rem" as unknown as number, // 10px
      lineHeight: "0.85rem" as unknown as number, // 12px
    },
  },
  spacing: 4,
  cloudShadows: shadows,
  breakpoints: {
    values: breakpoints,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          boxShadow: "none",
          transition: "none",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          ":hover": {
            background: greyPalette[200],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "16px 8px",
          fontSize: "1rem",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          width: "fit-content",
          fontWeight: 400,
          ":hover": {
            transition: "none",
          },
          svg: {
            maxHeight: 20,
            maxWidth: 20,
          },
          img: {
            maxWidth: 20,
            maxHeight: 20,
          },
        },
        startIcon: {
          marginRight: 4,
        },
        sizeSmall: {
          padding: "7px 16px",
          borderRadius: "5px",
          maxHeight: "30px",
          fontSize: "13px",
          lineHeight: "16px",
          minWidth: 0,
        },
        sizeMedium: {
          padding: "8px 16px",
          borderRadius: "10px",
          maxHeight: "40px",
          fontSize: "15px",
          lineHeight: "24px",
          minWidth: 0,
        },
        sizeLarge: {
          padding: "14px 24px",
          borderRadius: "10px",
          borderWidth: "2px",
          maxHeight: "52px",
          fontSize: "18px",
          lineHeight: "24px",
          minWidth: 0,
        },
        // Outlined
        outlined: {
          color: primaryPalette.main,
          borderColor: primaryPalette.main,
          ":hover": {
            borderColor: primaryPalette.hover,
            color: primaryPalette.hover,
            backgroundColor: "transparent",
          },
          "&.Mui-disabled": {
            backgroundColor: greyPalette[100],
            color: greyPalette[500],
            border: "none",
          },
        },
        outlinedError: {
          color: errorPalette.main,
          borderColor: errorPalette.main,
          background: "transparent",
          ":hover": {
            borderColor: errorPalette.hover,
            color: errorPalette.hover,
            backgroundColor: "transparent",
          },
        },
        // Contained (default variant)
        contained: {
          boxShadow: "none",
          color: "white",
          backgroundColor: primaryPalette.main,
          ":hover": {
            backgroundColor: primaryPalette.hover,
            boxShadow: "none",
          },
          "&.Mui-disabled": {
            backgroundColor: greyPalette[100],
            color: greyPalette[500],
            border: "none",
          },
        },
        containedError: {
          boxShadow: "none",
          color: "white",
          backgroundColor: errorPalette.main,
          ":hover": {
            backgroundColor: errorPalette.hover,
            boxShadow: "none",
          },
        },
        // Text
        text: {
          fontWeight: 500,
          alignItems: "center",
          ":hover": {
            color: primaryPalette.hover,
            backgroundColor: primaryPalette.light,
          },
        },
        textError: {
          color: errorPalette.main,
          ":hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: greyPalette[900],
        },
        popper: {
          zIndex: 15000,
        },
        tooltip: {
          backgroundColor: greyPalette[900],
          padding: "8px",
          fontSize: "12px",
          color: "white",
        },
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <IoClose size={12} />,
      },
      styleOverrides: {
        root: {
          cursor: "auto",
          span: {
            padding: 0,
          },
        },
        deleteIcon: {
          color: greyPalette[600],
          marginLeft: 4,
          marginRight: 0,
          ":hover": {
            color: greyPalette[400],
          },
        },
        icon: {
          marginRight: 4,
          marginLeft: 0,
        },
        sizeSmall: {
          fontSize: "10px",
          lineHeight: "12px",
          padding: "5px 8px ",
        },
        sizeMedium: {
          fontSize: "12px",
          lineHeight: "16px",
          padding: "7.5px 12px",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: 9999,
        },
        paper: {
          borderRadius: 0,
          width: 500,
          zIndex: 100,
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        ChipProps: {
          size: "small",
        },
      },
      styleOverrides: {
        popper: {
          zIndex: 99999,
        },
        paper: {
          borderRadius: 0,
        },
        root: {
          height: "auto !important",
          ".MuiChip-root": {
            background: "transparent",
            border: `1px solid ${greyPalette[300]}`,
          },
          ".MuiInput-root": {
            padding: "10px 14px",
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: "8px 0px",
          borderRadius: "5px",
          boxShadow:
            "0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          background: greyPalette[100],
          "&:has(> input:-webkit-autofill)": {
            backgroundColor: "rgb(232, 240, 254)", //TODO
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: greyPalette[400],
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        variant: "rounded",
        height: 150,
      },
      styleOverrides: {
        root: {
          backgroundColor: greyPalette[300],
          opacity: "50%",
        },
        rounded: {
          borderRadius: "10px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
      styleOverrides: {
        root: {
          ".MuiInput-root": {
            border: "1px solid transparent",
            background: greyPalette[100],
            color: greyPalette[900],
            input: {
              // todo: fix autofill color
              height: 20,
              padding: 14,
              background: "transparent",
              borderRadius: 10,
              ".MuiInputBase-inputAdornedStart": {
                paddingLeft: 14,
              },
              ".MuiInputBase-inputAdornedEnd": {
                paddingRight: 14,
              },
            },
            placeholder: {
              color: "grey",
            },
            ".Mui-disabled": {
              background: greyPalette[200],
              color: greyPalette[350],
            },
            "&.Mui-focused": {
              border: `1px solid ${greyPalette[300]}`,
            },
          },
          ".Mui-disabled": {
            background: greyPalette[200],
            color: greyPalette[350],
          },
          ".MuiInputBase-inputAdornedEnd": {
            paddingRight: 14,
          },
          ".MuiInputBase-adornedStart": {
            paddingLeft: 14,
          },
          ".MuiInputBase-adornedEnd": {
            paddingRight: 14,
          },
          ".MuiAutocomplete-endAdornment": {
            paddingRight: 14,
            marginLeft: 14,
          },
          ".MuiAutocomplete-startAdornment": {
            paddingRight: 14,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        checkedIcon: (
          <Box
            sx={(theme) => ({
              height: "18px",
              width: "18px",
              "& path": {
                color: theme.palette.primary.main,
                "&.Mui-disabled": {
                  color: greyPalette[200],
                },
              },
            })}
          >
            <CustomChecked />
          </Box>
        ),
        icon: (
          <Box
            sx={(theme) => ({
              height: "18px",
              width: "18px",
              "& path": {
                color: theme.palette.grey[200],
              },
            })}
          >
            <CustomCheck />
          </Box>
        ),
      },
      styleOverrides: {
        root: {
          width: "fit-content",
          ":hover path": {
            color: greyPalette[300],
          },
          "&.Mui-checked": {
            ":hover path": {
              color: primaryPalette.dark,
            },
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          background: greyPalette[600],
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: "button",
        underline: "hover",
      },
    },
  },
};
