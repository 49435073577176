import { Typography } from "@mui/material";
import { Affiliation } from "gql/graphql";
import _ from "lodash";
import {
  OrganizationCardContent,
  OrganizationCardContentProps,
} from "../cards/OrganizationCardContent";

interface MultipleOrganizationCellProps {
  identity: any;
  hideAvatar?: OrganizationCardContentProps["hideAvatar"];
}

export const MultipleOrganizationCell = ({
  identity,
}: MultipleOrganizationCellProps) => {
  const affiliations = identity?.affiliations || [];

  const firstOrgAffiliation =
    affiliations.length > 0 ? (_.first(affiliations) as Affiliation) : null;
  const remainingCount =
    affiliations.length >= 1 ? affiliations.length - 1 : affiliations.length;

  if (affiliations.length === 0) {
    return <Typography component="span">-</Typography>;
  }

  return firstOrgAffiliation?.organization ? (
    <OrganizationCardContent
      size="small"
      organization={firstOrgAffiliation.organization}
      hideIcon
      hideAvatar
      header={
        <Typography noWrap display="block">
          {firstOrgAffiliation?.organization?.name || "-"}{" "}
          {remainingCount > 0 ? (
            <Typography
              component="span"
              color={(theme) => theme.palette.grey[600]}
              display="inline"
            >
              +{remainingCount} more
            </Typography>
          ) : (
            ""
          )}
        </Typography>
      }
    />
  ) : null;
};
