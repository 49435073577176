import { gql } from "@apollo/client";
import { GenericPrivileges } from "@blue-ocean-robotics/cloud-library";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { Pill } from "components/atoms/Pill";
import { ActivityWidget } from "components/organisms/activityWidgets/ActivityWidget";
import { UserBasicInfo } from "components/organisms/userWidgets/UserBasicInfo";
import { UserOnboardingStatus } from "components/organisms/userWidgets/UserOnboardingStatus";
import { UserOrganizationRoles } from "components/organisms/userWidgets/UserOrganizationRoles";
import { UserRobotAccess } from "components/organisms/userWidgets/UserRobotAccess";
import { UserSupportRoles } from "components/organisms/userWidgets/UserSupportRoles";
import { graphql } from "gql";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { hasSupportRole, isSupportPath } from "utils/privileges/privilegeUtils";
import { PrivilegeWrapper } from "utils/privileges/PrivilegeWrapper";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { useUserDisplayName } from "utils/useUserDisplayName";

export const USER_QUERY = gql`
  query UserPage($userId: String!) {
    supportAssignable
    me {
      id
      supportRole {
        title
      }
    }
    identity(id: $userId) {
      id
      firstName
      lastName
      email
      language
      inviteState
      firstLogin
      firstReminderSent
      invitedDate
      lastLogin
      secondReminderSent
      loginWithGoogle
      loginWithMicrosoft
      loginWithPassword
      affiliations {
        id
        organization {
          id
          name
          country
        }
        roles {
          title
          date
        }
      }
      supportRole {
        title
      }
    }
  }
`;

export const ORG_QUERY = graphql(`
  query OrganizationNameQuery($orgId: String!) {
    organization(id: $orgId) {
      id
      name
    }
  }
`);

const columnLeft = ["basicInfo", "robotAccess", "activityLog"];

const columnRight = ["status", "supportRole", "orgRoles"];

const smallScreenColumns = [
  "basicInfo",
  "status",
  "robotAccess",
  "supportRole",
  "orgRoles",
  "activityLog",
];

export const UserPage = () => {
  const { userId, orgId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isOnSupportPath = isSupportPath(location);

  const { data, loading } = useQueryWithSnack(USER_QUERY, {
    variables: { userId: userId ?? "" },
    skip: !userId,
  });

  const { data: orgData } = useQueryWithSnack(ORG_QUERY, {
    variables: { orgId: orgId ?? "" },
    skip: !orgId || orgId === "support",
  });

  const userIsMe = data?.identity.id === data?.me.id;
  const currentUserIsSupport = hasSupportRole(data?.me);
  const displayName = useUserDisplayName(data?.identity);

  const widgetArr = largeScreen ? columnLeft : smallScreenColumns;

  const renderColumn = (type: any) => {
    switch (type) {
      case "basicInfo":
        return (
          <Grid item mb={6} key={type}>
            <UserBasicInfo
              user={data?.identity}
              userIsMe={userIsMe}
              loading={loading}
            />
          </Grid>
        );
      case "status":
        return (
          <Grid item mb={6} key={type}>
            <UserOnboardingStatus user={data?.identity} loading={loading} />
          </Grid>
        );
      case "robotAccess":
        return (
          <Grid item mb={6} key={type}>
            <UserRobotAccess />
          </Grid>
        );
      case "supportRole":
        return currentUserIsSupport ? (
          <Grid item xs={12} mb={6} key={type}>
            <UserSupportRoles
              identity={data?.identity}
              canAssign={(data?.supportAssignable || []).length > 0}
              loading={loading}
            />
          </Grid>
        ) : null;
      case "orgRoles":
        return (
          <Grid item xs={12} mb={6} key={type}>
            <UserOrganizationRoles
              identity={data?.identity}
              currentUserIsSupport={currentUserIsSupport}
              loading={loading}
            />
          </Grid>
        );
      case "activityLog":
        return isOnSupportPath ? (
          <PrivilegeWrapper privilege={GenericPrivileges.AuditLog} key={type}>
            <Grid item xs={12} mb={6}>
              <ActivityWidget mustInclude={[userId ?? ""]} />
            </Grid>
          </PrivilegeWrapper>
        ) : null;
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={6}>
      <HeaderGrid
        crumbs={
          loading
            ? []
            : location.pathname.includes("support")
              ? [
                  { label: "Support" },
                  {
                    label: "Users",
                    onClick: () => navigate("/cloud/support/users"),
                  },
                  {
                    label: displayName ?? "-",
                    pill: userIsMe ? (
                      <Pill
                        label="YOU"
                        color="primary"
                        sx={{ color: "white", marginBottom: 1, marginLeft: 2 }}
                      />
                    ) : undefined,
                  },
                ]
              : [
                  {
                    label: orgId && orgData ? orgData?.organization.name : "-",
                  },
                  {
                    label: "Users",
                    onClick: () => navigate(`/cloud/fm/${orgId}/users`),
                  },
                  {
                    label: displayName ?? "-",
                    pill: userIsMe ? (
                      <Pill
                        label="YOU"
                        color="primary"
                        sx={{ color: "white", marginBottom: 1, marginLeft: 2 }}
                      />
                    ) : undefined,
                  },
                ]
        }
      />

      <Grid item xs={12} xl={6}>
        {widgetArr.map((item) => renderColumn(item))}
      </Grid>
      {largeScreen ? (
        <Grid item xs={12} xl={6}>
          {columnRight.map((item) => renderColumn(item))}
        </Grid>
      ) : null}
    </Grid>
  );
};
