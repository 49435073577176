import { useMutation } from "@apollo/client";
import { IdentityPrivileges } from "@blue-ocean-robotics/cloud-library";
import { Grid } from "@mui/material";
import { EditableAvatar } from "components/atoms/avatars/EditableAvatar";
import { UserAvatar } from "components/atoms/avatars/UserAvatar";
import { CloudTextField } from "components/atoms/CloudTextField";
import { FormikTextField } from "components/atoms/formik";
import { CloudDrawer, CloudDrawerProps } from "components/modules/CloudDrawer";
import { ORG_USER_LIST_QUERY } from "components/pages/OrganizationUserListPage";
import { SUPPORT_USER_LIST_QUERY } from "components/pages/SupportUserListPage";
import { USER_QUERY } from "components/pages/UserPage";
import { useConfig } from "config/configHook";
import { useFirebase } from "firebaseWrapper";
import { useFormik } from "formik";
import { graphql } from "gql";
import { Identity } from "gql/graphql";
import _ from "lodash";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isSupportPath } from "utils/privileges/privilegeUtils";
import { usePrivilege } from "utils/privileges/usePrivileges";
import * as Yup from "yup";

export const DELETE_USER = graphql(`
  mutation DeleteUser($userId: String!) {
    deleteIdentity(identityId: $userId)
  }
`);

const UPDATE_ME = graphql(`
  mutation UpdateMe($firstName: String, $lastName: String) {
    updateMe(firstName: $firstName, lastName: $lastName) {
      id
    }
  }
`);

interface Props {
  open: CloudDrawerProps["open"];
  onClose: CloudDrawerProps["onClose"];
  identity: any;
  userIsMe: boolean;
}

type Values = {
  firstName?: string;
  lastName?: string;
};

export const EditUserBasicInfo = ({
  open,
  onClose,
  identity,
  userIsMe,
}: Props) => {
  const config = useConfig();
  const firebase = useFirebase(config);
  const location = useLocation();
  const supportPath = isSupportPath(location);
  const navigate = useNavigate();
  const canDeleteUser = usePrivilege(IdentityPrivileges.Delete).hasPrivilege;
  const user = identity as Identity;

  const [deleteUser, { client }] = useMutation(DELETE_USER, {
    refetchQueries: [
      { query: SUPPORT_USER_LIST_QUERY },
      {
        query: ORG_USER_LIST_QUERY,
        variables: {
          orgId: _.first(user?.affiliations)?.organization?.id,
        },
      },
    ],
  });

  const [updateMe] = useMutation(UPDATE_ME, {
    refetchQueries: [
      {
        query: USER_QUERY,
        variables: {
          userId: user?.id,
        },
      },
    ],
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const formik = useFormik<Values>({
    initialValues: {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string(),
      lastName: Yup.string(),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Updating your name...", {
        variant: "loading",
        persist: true,
      });

      updateMe({
        variables: {
          firstName: values.firstName?.trim(),
          lastName: values.lastName?.trim(),
        },
        onCompleted: () => {
          enqueueSnackbar(`Your name was updated`, {
            variant: "success",
          });
          closeSnackbar(snackbarId);
        },
        onError: () => {
          enqueueSnackbar(`Could not update your name`, {
            variant: "error",
          });
          closeSnackbar(snackbarId);
        },
      });

      handleClose();
    },
  });

  useEffect(() => {
    formik.setFieldValue("firstName", user?.firstName ?? "");
    formik.setFieldValue("lastName", user?.lastName ?? "");
  }, []);

  useEffect(() => {
    (() => formik.validateForm())();
  }, []);

  const handleDeleteUser = () => {
    const snackbarId = enqueueSnackbar("Deleting user...", {
      variant: "loading",
      persist: true,
    });

    deleteUser({
      variables: {
        userId: user.id,
      },
      onCompleted: () => {
        enqueueSnackbar(`User was deleted`, {
          variant: "success",
        });
        closeSnackbar(snackbarId);

        if (userIsMe) {
          firebase.logout();
          closeSnackbar();
          navigate("/");
          client.clearStore();
        }

        if (supportPath) {
          navigate("/cloud/support/users");
        } else {
          navigate(
            `/cloud/fm/${_.first(user?.affiliations)?.organization?.id}/users`,
          );
        }
      },
      onError: () => {
        enqueueSnackbar(`Could not delete user`, {
          variant: "error",
        });
        closeSnackbar(snackbarId);
      },
    });
  };

  return (
    <form>
      <CloudDrawer
        open={open}
        onClose={handleClose}
        header={userIsMe ? "Edit My Profile details" : "Edit User details"}
        action={{
          label: "Save changes",
          onClick: formik.handleSubmit,
          disabled:
            formik.isValid === false ||
            (formik.values.firstName === user?.firstName &&
              formik.values.lastName === user?.lastName),
        }}
        secondaryAction={
          canDeleteUser || userIsMe
            ? {
                label: userIsMe ? "Delete my profile" : "Delete user",
                onClick: handleDeleteUser,
              }
            : undefined
        }
      >
        <EditableAvatar
          defaultAvatar={<UserAvatar size="large" identity={user} />}
        />
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormikTextField
              name="firstName"
              formik={formik}
              label="First name"
              placeholder="First name"
              disabled={!userIsMe}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              name="lastName"
              formik={formik}
              label="Last name"
              placeholder="Last name"
              disabled={!userIsMe}
            />
          </Grid>
          <Grid item xs={12}>
            <CloudTextField
              disabled
              label="Email address"
              value={user?.email}
            />
          </Grid>
        </Grid>
      </CloudDrawer>
    </form>
  );
};
