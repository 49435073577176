import { Box, Button, Link } from "@mui/material";
import { styled } from "@mui/system";
import { LoginHeader } from "components/atoms/LoginHeader";
import { useNavigate } from "react-router-dom";
import { EmailPasswordForm } from "./EmailPasswordForm";
import { SSOButtons } from "./SSOButtons";

export const FormWrapper = styled(Box)(() => ({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  gap: "32px",
}));

export const LoginDefault = () => {
  const navigate = useNavigate();

  return (
    <FormWrapper>
      <LoginHeader>Welcome back</LoginHeader>
      <EmailPasswordForm
        submitButton={
          <Box display="flex" flexDirection="column">
            <Button type="submit" sx={{ width: "100%" }}>
              Log in
            </Button>
            <Link
              sx={{ marginTop: 4, alignSelf: "flex-end" }}
              onClick={() => navigate("reset")}
            >
              Forgot password?
            </Link>
          </Box>
        }
      />
      <SSOButtons />
    </FormWrapper>
  );
};
