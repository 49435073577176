import { UVDRobotIcon } from "assets/icons/UVDRobotIcon";
import { UVDRobotUnassignedIcon } from "assets/icons/UVDRobotUnassignedIcon";
import { MenuItem } from "components/atoms/dashboard/menuItems/MenuItem";
import { MenuTitle } from "components/atoms/dashboard/menuItems/MenuTitle";
import { graphql } from "gql";
import { IRobot } from "gql/graphql";
import {
  IoBusinessOutline,
  IoGridOutline,
  IoPersonOutline,
} from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

const QUERY = graphql(`
  query SupportMenuItem {
    organizations {
      id
    }
    robots(isActive: true) {
      robots {
        serialNumber
      }
    }
    unassignedRobots: robots(isActive: false) {
      robots {
        serialNumber
      }
    }
    identities {
      id
    }
  }
`);

export const SupportMenuItem = () => {
  const { data } = useQueryWithSnack(QUERY);

  const robots = data?.robots.robots as IRobot[];
  const unassignedRobots = data?.unassignedRobots.robots as IRobot[];

  return (
    <>
      <MenuTitle title="Support" hideSettings />
      <NavLink to="support/disinfections" style={{ textDecoration: "none" }}>
        {({ isActive }) => {
          return (
            <MenuItem
              hideCount
              isActive={isActive}
              value="Disinfections"
              icon={<IoGridOutline style={{ height: 20, width: 20 }} />}
            />
          );
        }}
      </NavLink>
      <NavLink to="support/organizations" style={{ textDecoration: "none" }}>
        {({ isActive }) => {
          return (
            <MenuItem
              isActive={isActive}
              value="Organizations"
              icon={<IoBusinessOutline style={{ height: 20, width: 20 }} />}
              count={data?.organizations?.length || 0}
            />
          );
        }}
      </NavLink>
      <NavLink to="support/users" style={{ textDecoration: "none" }}>
        {({ isActive }) => {
          return (
            <MenuItem
              isActive={isActive}
              value="Users"
              icon={<IoPersonOutline style={{ height: 20, width: 20 }} />}
              count={data?.identities?.length || 0}
            />
          );
        }}
      </NavLink>
      <NavLink to="support/robots" style={{ textDecoration: "none" }}>
        {({ isActive }) => {
          return (
            <MenuItem
              isActive={isActive}
              value="Robots"
              icon={<UVDRobotIcon />}
              count={robots?.length || 0}
            />
          );
        }}
      </NavLink>
      <NavLink
        to="support/unassigned-robots"
        style={{ textDecoration: "none" }}
      >
        {({ isActive }) => {
          return (
            <MenuItem
              isActive={isActive}
              value="Unassigned Robots"
              icon={<UVDRobotUnassignedIcon />}
              count={unassignedRobots?.length || 0}
            />
          );
        }}
      </NavLink>
    </>
  );
};
