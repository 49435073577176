import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";

interface MenuItemProps {
  icon: ReactElement;
  value: string | ReactElement;
  count?: number | string;
  hideCount?: boolean;
  isActive: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const MenuItem = ({
  icon,
  value,
  count,
  isActive = false,
  hideCount = false,
  onClick,
  disabled,
}: MenuItemProps) => {
  return (
    <Box
      onClick={onClick}
      sx={(theme) => ({
        backgroundColor: isActive ? "white" : "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 8px 10px 8px",
        borderRadius: "10px",
        marginBottom: 2,
        height: "22px",
        cursor: "pointer",
        pointerEvents: disabled ? "none" : "auto",
        "svg > *": {
          color: isActive
            ? theme.palette.primary.main
            : disabled
              ? theme.palette.grey[350]
              : theme.palette.grey[500],
        },
        ":hover": {
          backgroundColor: "white",
        },
        ":hover div:first-of-type p": {
          color: theme.palette.primary.main,
        },
        ":hover svg > *": {
          color: theme.palette.primary.main,
        },
      })}
    >
      <Box sx={() => ({ display: "flex", alignItems: "center", flex: 1 })}>
        <Box sx={{ minWidth: 20, display: "flex", justifyContent: "center" }}>
          {icon}
        </Box>
        <Typography
          variant="body1"
          sx={(theme) => ({
            fontWeight: 500,
            ml: 2,
            lineHeight: "14px",
            color: isActive
              ? theme.palette.primary.main
              : disabled
                ? theme.palette.grey[500]
                : theme.palette.grey[800],
          })}
        >
          {value}
        </Typography>
      </Box>
      {hideCount ? null : (
        <Box
          sx={(theme) => ({
            backgroundColor: isActive
              ? theme.palette.primary.light
              : theme.palette.grey[300],
            borderRadius: "5px",
            padding: "0px 6px",
            height: "19px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          })}
        >
          <Typography
            variant="body2"
            sx={(theme) => ({
              color: isActive
                ? theme.palette.primary.dark
                : disabled
                  ? theme.palette.grey[500]
                  : theme.palette.grey[800],
              lineHeight: "12px",
              fontWeight: 500,
            })}
          >
            {count}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
