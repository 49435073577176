import { NumberChart } from "components/modules/charts/NumberChart";
import { useDDFilterParams } from "components/modules/disinfectionFilter/DisinfectionFilterPanel";
import { graphql } from "gql";
import { DisinfectionFilter } from "gql/graphql";
import { FormattedMessage } from "react-intl";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

const UNIQUE_OPERATORS = graphql(`
  query UniqueOperators($filter: DisinfectionFilter!) {
    numberOfUniqueDisinfectionOperators(filter: $filter)
  }
`);

interface Props {
  hiddenFilter?: DisinfectionFilter;
}

export const UniqueOperatorsChart = ({ hiddenFilter }: Props) => {
  const filterParams = useDDFilterParams();

  const { data } = useQueryWithSnack(UNIQUE_OPERATORS, {
    variables: {
      filter: {
        ...filterParams,
        ...hiddenFilter,
      },
    },
  });

  return (
    <NumberChart
      header={
        <FormattedMessage
          id="unique-operators"
          defaultMessage="Unique Operators"
        />
      }
      description={
        <FormattedMessage
          id="unique-operators-description"
          defaultMessage="Number of unique operators that started a disinfection cycle in this period"
        />
      }
      value={data?.numberOfUniqueDisinfectionOperators || 0}
    />
  );
};
