import { defineMessages } from "react-intl";
import { DefineMsg } from "types/types";

export const interruptionMsg: DefineMsg = defineMessages({
  cause: { id: "interruption.cause", defaultMessage: "Cause" },
  ConnectionClosed: {
    id: "interruption.connectionClosed",
    defaultMessage: "App being closed",
  },
  ConnectionUnstable: {
    id: "interruption.connectionUnstable",
    defaultMessage: "Unstable connection",
  },
  FromUI: {
    id: "interruption.fromUI",
    defaultMessage: "Interrupted by operator",
  },
  FunctionButton: {
    id: "interruption.functionButton",
    defaultMessage: "Fn button pressed",
  },
  HeatDetection: {
    id: "interruption.heatDetection",
    defaultMessage: "Body heat detected",
  },
  InternalError: {
    id: "interruption.internalError",
    defaultMessage: "Internal error",
  },
  LocalizationLoss: {
    id: "interruption.localizationLoss",
    defaultMessage: "Lost localization",
  },
  LowBattery: {
    id: "interruption.lowBattery",
    defaultMessage: "Low battery",
  },
  PersonDetection: {
    id: "interruption.personDetection",
    defaultMessage: "Person detection",
  },
  ResetButton: {
    id: "interruption.resetButton",
    defaultMessage: "Reset button pressed",
  },
  RobotStuck: {
    id: "interruption.robotStuck",
    defaultMessage: "Robot stuck",
  },
  TabletMovement: {
    id: "interruption.tabletMovement",
    defaultMessage: "Tablet movement",
  },
  TabletPermission: {
    id: "interruption.tabletPermission",
    defaultMessage: "Tablet permission",
  },
  WalkDetection: {
    id: "interruption.walkDetection",
    defaultMessage: "Walk detected",
  },
});
