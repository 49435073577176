import { Box, Stack, styled, Typography } from "@mui/material";
import { InlineCardEmptyView } from "components/atoms/InlineCardEmptyView";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { format } from "date-fns";
import { graphql } from "gql";
import { Activity, ActivityCode, Identity } from "gql/graphql";
import _ from "lodash";
import {
  IoAdd,
  IoHelp,
  IoRemove,
  IoSettingsOutline,
  IoSwapHorizontal,
  IoTrashOutline,
} from "react-icons/io5";
import { useIntl } from "react-intl";
import { activityMsg } from "trans/definedMessages/activityLogMessages";
import { hasSupportRole } from "utils/privileges/privilegeUtils";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { Actor } from "./components/ActorSubText";
import { LogEntryIcon } from "./components/LogEntryIcon";
import { getActivityMessage } from "./getActivityMessage";

const QUERY = graphql(`
  query Activities($mustInclude: [String!]) {
    me {
      id
      supportRole {
        title
      }
    }
    activities(mustInclude: $mustInclude) {
      id
      createdAt
      code
      actorId
      actor {
        id
        firstName
        lastName
        email
        supportRole {
          title
        }
      }
      variables {
        key
        value
        related {
          ... on Organization {
            id
            name
          }
          ... on Identity {
            id
            email
            firstName
            lastName
          }
          ... on UvdRobot {
            id
            serialNumber
          }
        }
      }
    }
  }
`);

const DateStamp = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  fontWeight: "700",
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const TimeStamp = styled(Typography)(({ theme }) => ({
  width: "32px",
  textAlign: "right",
  color: theme.palette.grey[600],
}));

const AbsoluteLine = styled("div")(({ theme }) => ({
  borderLeft: `1px dashed ${theme.palette.grey[300]}`,
  position: "absolute",
  right: "25px",
  top: 0,
  bottom: `-${theme.spacing(4)}`,
  transform: "translateX(-50%)",
}));

export function getIcon(code: ActivityCode) {
  switch (code) {
    case ActivityCode.RoleRevoked:
      return <IoRemove />;
    case ActivityCode.OrganizationUpdated:
      return <IoSettingsOutline />;
    case ActivityCode.OrganizationDeleted:
      return <IoTrashOutline />;
    case ActivityCode.OrganizationCreated:
    case ActivityCode.RobotCreated:
    case ActivityCode.RoleGranted:
      return <IoAdd />;
    case ActivityCode.RobotTransferred:
      return <IoSwapHorizontal />;
    default:
      return <IoHelp />;
  }
}

export const ActivityWidget = (props: { mustInclude: string[] }) => {
  const intl = useIntl();

  const { data } = useQueryWithSnack(QUERY, {
    variables: {
      mustInclude: props.mustInclude,
    },
  });

  const isSupporter = hasSupportRole(data?.me);
  const activity = data?.activities as Activity[] | undefined;

  const groupedByDate = _.chain(activity)
    .groupBy((o) => {
      return format(new Date(o.createdAt), "yyyyMMdd");
    })
    .sortBy((group) => activity?.indexOf(group[0]))
    .value();

  return (
    <WidgetPaper>
      <WidgetHeader label="Activity" />
      <Box sx={{ maxHeight: 880, overflow: "auto" }}>
        {groupedByDate.length > 0 ? (
          _.map(groupedByDate, (dayLog) => {
            return (
              <Box key={dayLog[0].createdAt}>
                <DateStamp variant="body2">
                  {format(new Date(dayLog[0].createdAt), "dd MMMM yyyy")}
                </DateStamp>
                <Stack>
                  {dayLog.map((logEntry) => {
                    const identity = logEntry.actor as Identity | undefined;
                    const entryText = activityMsg[logEntry.code]
                      ? getActivityMessage(logEntry, isSupporter, intl)
                      : "...";

                    return (
                      <Stack
                        direction={"row"}
                        padding="8px 0px 12px 0px"
                        key={logEntry.id}
                      >
                        <Box position="relative">
                          <Stack direction={"row"} alignItems={"center"}>
                            <TimeStamp variant="bodyS">
                              {format(new Date(logEntry.createdAt), "HH:mm")}
                            </TimeStamp>
                            <Box>
                              {dayLog.length - 1 ==
                              dayLog.indexOf(logEntry) ? undefined : (
                                <AbsoluteLine />
                              )}
                              <LogEntryIcon logEntry={logEntry} />
                            </Box>
                          </Stack>
                        </Box>
                        <Box>
                          <Typography variant="body2" mb={1} component="p">
                            {entryText}
                          </Typography>
                          <Actor
                            logEntry={logEntry}
                            userIsMe={data?.me?.id === identity?.id}
                          />
                        </Box>
                      </Stack>
                    );
                  })}
                </Stack>
              </Box>
            );
          })
        ) : (
          <InlineCardEmptyView label="No activities yet" />
        )}
      </Box>
    </WidgetPaper>
  );
};
