import UVDFavicon from "../assets/favicons/uvd-favicons.svg";
import UVDLogo from "../assets/logos/uvd-logo.svg";
import { uvdTheme } from "../theme/uvdTheme";
import { Config } from "./configTypes";

export const config: Config = {
  theme: uvdTheme,
  defaultRouteKey: "",
  logo: {
    defaultLogo: UVDLogo,
  },
  favicon: UVDFavicon,
};
