import { useMutation } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { CloudDialogContent } from "components/atoms/CloudDialogContent";
import { CloudInlineLink } from "components/atoms/CloudInlineLink";
import { FormikTextField } from "components/atoms/formik";
import { ListElement, OrderedList } from "components/atoms/ListElements";
import { CloudDialog, CloudDialogProps } from "components/modules/CloudDialog";
import { useFormik } from "formik";
import { graphql } from "gql";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { UNASSIGNED_ROBOTS_QUERY } from "../UnassignedRobots";

const CREATE_ROBOT = graphql(`
  mutation AddRobot($serialNumber: String!) {
    createRobot(serialNumber: $serialNumber) {
      id
      serialNumber
    }
  }
`);

type Values = {
  serialNumber: string;
};

export const AddRobot = ({ open, onClose }: CloudDialogProps) => {
  const intl = useIntl();

  const [createRobot] = useMutation(CREATE_ROBOT, {
    refetchQueries: [{ query: UNASSIGNED_ROBOTS_QUERY }],
  });

  useEffect(() => {
    (() => formik.validateForm())();
  }, []);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const formik = useFormik<Values>({
    initialValues: {
      serialNumber: "",
    },
    validationSchema: Yup.object({
      serialNumber: Yup.string().required(
        intl.formatMessage({
          id: "required",
          defaultMessage: "Required",
        }),
      ),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Adding robot...", {
        variant: "loading",
        persist: true,
      });
      handleClose();

      createRobot({
        variables: {
          serialNumber: values.serialNumber,
        },
        onCompleted: () => {
          enqueueSnackbar(`Added Robot ${values.serialNumber}`, {
            variant: "success",
          });
          closeSnackbar(snackbarId);
        },
        onError: () => {
          enqueueSnackbar(`Could not add robot ${values.serialNumber}`, {
            variant: "error",
          });
          closeSnackbar(snackbarId);
        },
      });
    },
  });

  return (
    <form>
      <CloudDialog
        header="Add robot"
        open={open}
        onClose={handleClose}
        hideCancel
        submitLabel="Add"
        submitDisabled={!formik.isValid}
        onSubmit={formik.handleSubmit}
      >
        <CloudDialogContent>
          <Box mb={2} sx={() => ({})}>
            <Typography
              variant="body2"
              color={(theme) => theme.palette.grey[600]}
            >
              Before you add the robot:
            </Typography>
            <OrderedList>
              <ListElement>Make sure the robot is a staging robot</ListElement>
              <ListElement>
                Make sure the robot is active in the “Blue Ocean Robotics”
                organization in{" "}
                <CloudInlineLink
                  to={"https://uvd.staging.blue-ocean-robotics-fleet.com/"}
                >
                  the current system (staging environment)
                </CloudInlineLink>
              </ListElement>
              <ListElement>
                Use the robot's serial number from the{" "}
                <CloudInlineLink to="https://docs.google.com/spreadsheets/d/12vlmT5itFvskVNLoHFHSDW4RulxQmSOmKzNxgZliMM8/edit#gid=1358927794">
                  UVD Credentials sheet
                </CloudInlineLink>
              </ListElement>
            </OrderedList>
          </Box>
          <FormikTextField
            formik={formik}
            name="serialNumber"
            label="serial number"
            placeholder="Robot serial number, for example 10000123"
          />
        </CloudDialogContent>
      </CloudDialog>
    </form>
  );
};
