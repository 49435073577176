import { gql, useMutation } from "@apollo/client";
import { GenericPrivileges } from "@blue-ocean-robotics/cloud-library";
import { Box, Typography } from "@mui/material";
import { CloudLabel } from "components/atoms/CloudLabel";
import { SupportCardContent } from "components/modules/cards/SupportCardContent";
import { CloudDrawer, CloudDrawerProps } from "components/modules/CloudDrawer";
import { USER_QUERY } from "components/pages/UserPage";
import { graphql } from "gql";
import { Identity, RoleType } from "gql/graphql";
import _ from "lodash";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getSupportRoleShortDescription } from "trans/definedMessages/roleDescriptions";
import { roles } from "trans/definedMessages/roleMessages";
import { SupportRole } from "types/types";
import { usePrivilege } from "utils/privileges/usePrivileges";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { useUserDisplayName } from "utils/useUserDisplayName";
import { ALL_ORG_QUERY } from "../dialogs/AssignSupportRole";

export const REVOKE = graphql(`
  mutation RevokeUserRole($userId: String!, $role: RoleType!, $orgId: String) {
    revoke(id: $userId, role: $role, targetOrganizationId: $orgId) {
      id
      firstName
      lastName
      affiliations {
        organization {
          name
        }
        roles {
          title
        }
      }
    }
  }
`);

const EDITSUPPORTROLE_FRAGMENT = gql`
  fragment EditSupportRoles on Identity {
    id
    supportRole {
      title
    }
  }
`;

interface Props {
  open: CloudDrawerProps["open"];
  onClose: CloudDrawerProps["onClose"];
  user: Identity;
}

export const EditSupportRole = ({ open, onClose, user }: Props) => {
  const intl = useIntl();
  const displayName = useUserDisplayName(user);
  const canRemoveSupportRole = usePrivilege(
    GenericPrivileges.RoleManagement,
  ).hasPrivilege;
  const supportRole = user?.supportRole?.title ?? RoleType.SecondLevel;

  const handleClose = () => {
    onClose();
  };

  const [revokeUserRole] = useMutation(REVOKE, {
    refetchQueries: [
      {
        query: USER_QUERY,
        variables: {
          userId: user?.id,
        },
      },
    ],
  });

  const handleRevokeRole = () => {
    const snackbarId = enqueueSnackbar("Revoking support role...", {
      variant: "loading",
      persist: true,
    });
    handleClose();

    revokeUserRole({
      variables: {
        userId: user.id,
        role: supportRole as RoleType,
      },
      onCompleted: () => {
        enqueueSnackbar(
          `Revoked ${intl.formatMessage(
            roles[supportRole],
          )} role from ${displayName}`,
          {
            variant: "success",
          },
        );
        closeSnackbar(snackbarId);
      },
      onError: () => {
        enqueueSnackbar(
          `Could not revoke ${intl.formatMessage(
            roles[supportRole],
          )} role from ${displayName}`,
          {
            variant: "error",
          },
        );
        closeSnackbar(snackbarId);
      },
    });
  };

  const { data: allOrgData } = useQueryWithSnack(ALL_ORG_QUERY);

  const allOrgText = useMemo(() => {
    const allOrgs = allOrgData?.organizations ?? [];

    if (allOrgs.length > 0) {
      const firstOrg = _.first(allOrgs);

      return (
        <FormattedMessage
          id="support.organizationsCount"
          defaultMessage={
            "{firstOrgName} <light>{restCount, plural, =0 {} one {+# organization} other {+# organizations}}</light>"
          }
          values={{
            restCount: allOrgs.length - 1,
            firstOrgName: firstOrg?.name ?? "-",
            light: (chunks) => (
              <Typography
                color={(theme) => theme.palette.grey[600]}
                display="inline"
              >
                {chunks}
              </Typography>
            ),
          }}
        />
      );
    } else {
      return "No organizations";
    }
  }, [allOrgData]);

  return (
    <CloudDrawer
      open={open}
      onClose={handleClose}
      header={`Manage ${intl.formatMessage(
        roles[supportRole as RoleType],
      )} Role`}
      secondaryAction={{
        label: `Remove ${intl.formatMessage(
          roles[supportRole as RoleType],
        )} Role`,
        onClick: handleRevokeRole,
        color: "error",
        disabled: !canRemoveSupportRole,
      }}
    >
      <CloudLabel>Support role</CloudLabel>
      <Box
        sx={{
          alignSelf: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SupportCardContent
          header={intl.formatMessage(roles[SupportRole.SecondLevel])}
          description={getSupportRoleShortDescription(
            SupportRole.SecondLevel as unknown as RoleType,
          )}
        />
        <Typography ml={13}>{allOrgText}</Typography>
      </Box>
    </CloudDrawer>
  );
};

EditSupportRole.fragments = {
  user: EDITSUPPORTROLE_FRAGMENT,
};
