import { gql } from "@apollo/client";
import { GenericPrivileges } from "@blue-ocean-robotics/cloud-library";
import { Grid, Skeleton } from "@mui/material";
import { InlineCardEmptyView } from "components/atoms/InlineCardEmptyView";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { RoleCard } from "components/modules/cards/RoleCard";
import { RoleType } from "gql/graphql";
import { useState } from "react";
import { useIntl } from "react-intl";
import { getSupportRoleShortDescription } from "trans/definedMessages/roleDescriptions";
import { roles } from "trans/definedMessages/roleMessages";
import { usePrivilege } from "utils/privileges/usePrivileges";
import { AssignSupportRole } from "../dialogs/AssignSupportRole";
import { EditSupportRole } from "../panels/EditSupportRole";

const USERSUPPORTROLE_FRAGMENT = gql`
  fragment UserSupportRoles on Identity {
    supportRole {
      title
    }
    ...EditSupportRoles
  }
  ${EditSupportRole.fragments.user}
`;

export const UserSupportRoles = ({
  identity,
  readonly,
  canAssign,
  loading,
}: {
  identity: any;
  readonly?: boolean;
  canAssign?: boolean;
  loading?: boolean;
}) => {
  const intl = useIntl();
  const supportRole = identity?.supportRole;
  const canInviteSupportUser = usePrivilege(
    GenericPrivileges.RoleManagement,
  ).hasPrivilege;

  const [editPanelOpen, setEditPanelOpen] = useState(false);
  const [selectedAffiliation, setSeletedAffiliation] = useState(null);
  const [assignRoleDialogOpen, setAssignRoleDialogOpen] = useState(false);

  if (loading) return <Skeleton />;

  return (
    <>
      <WidgetPaper>
        <WidgetHeader
          label="Support"
          buttonLabel={
            readonly ||
            supportRole ||
            canAssign === false ||
            canInviteSupportUser === false
              ? undefined
              : "Assign Support role"
          }
          onButtonClick={() => setAssignRoleDialogOpen(true)}
        />
        <Grid container mt={0} spacing={4} display="flex">
          {supportRole ? (
            <Grid
              item
              xs={12}
              display="flex"
              sx={{ "& .MuiGrid-root": { flexGrow: 1 } }}
            >
              <RoleCard
                disabled={readonly}
                type="support"
                iconType="settings"
                header={intl.formatMessage(
                  roles[supportRole?.title as RoleType],
                )}
                description={getSupportRoleShortDescription(supportRole?.title)}
                onClick={() => {
                  setEditPanelOpen(true);
                  setSeletedAffiliation(supportRole);
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={12} display="flex">
              <InlineCardEmptyView label="No Support role" />
            </Grid>
          )}
        </Grid>
      </WidgetPaper>
      {selectedAffiliation ? (
        <EditSupportRole
          open={editPanelOpen}
          onClose={() => setEditPanelOpen(false)}
          user={identity}
        />
      ) : null}
      <AssignSupportRole
        identity={identity}
        open={assignRoleDialogOpen}
        onClose={() => setAssignRoleDialogOpen(false)}
      />
    </>
  );
};

UserSupportRoles.fragments = {
  identity: USERSUPPORTROLE_FRAGMENT,
};
