import { useMutation } from "@apollo/client";
import { Box, Button } from "@mui/material";
import { UserAvatar } from "components/atoms/avatars/UserAvatar";
import { FormikTextField } from "components/atoms/formik";
import { useFormik } from "formik";
import { graphql } from "gql";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { getInitials } from "utils/utilFunctions";
import * as Yup from "yup";

const UPDATE_ME = graphql(`
  mutation UpdateMe($firstName: String, $lastName: String) {
    updateMe(firstName: $firstName, lastName: $lastName) {
      id
    }
  }
`);

interface Props {
  onComplete: () => void;
  buttonLabel: string;
}

interface Values {
  firstName: string;
  lastName: string;
}

export const SetupProfile = ({ onComplete, buttonLabel }: Props) => {
  const intl = useIntl();

  const [updateMe, { loading }] = useMutation(UPDATE_ME);

  const formik = useFormik<Values>({
    initialValues: {
      firstName: "",
      lastName: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(
        intl.formatMessage({
          id: "required",
          defaultMessage: "Required",
        }),
      ),
      lastName: Yup.string().required(
        intl.formatMessage({
          id: "required",
          defaultMessage: "Required",
        }),
      ),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Saving your name...", {
        variant: "loading",
        persist: true,
      });

      updateMe({
        variables: {
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
        },
        onCompleted: () => {
          enqueueSnackbar(`Your name was saved`, {
            variant: "success",
          });
          closeSnackbar(snackbarId);
          onComplete();
        },
        onError: () => {
          enqueueSnackbar(`Could not save your name`, {
            variant: "error",
          });
          closeSnackbar(snackbarId);
        },
      });
    },
  });

  const initials = useMemo(() => {
    if (formik.values.firstName || formik.values.lastName)
      return getInitials(
        [formik.values.firstName, formik.values.lastName]
          .filter((item) => item !== "")
          .join(" "),
      );
  }, [formik.values]);

  return (
    <form>
      <Box
        width={"100%"}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <UserAvatar size="large">{initials}</UserAvatar>
        <Box mb={12} flexDirection="row" display="flex" gap={4} width="100%">
          <FormikTextField
            formik={formik}
            fullWidth
            name="firstName"
            label={intl.formatMessage({
              id: "firstName",
              defaultMessage: "First name",
            })}
          />
          <FormikTextField
            formik={formik}
            fullWidth
            name="lastName"
            label={intl.formatMessage({
              id: "lastName",
              defaultMessage: "Last name",
            })}
          />
        </Box>
        <Button
          disabled={
            loading ||
            !formik.isValid ||
            (formik.values.firstName === "" && formik.values.lastName === "")
          }
          type="submit"
          sx={{ width: "100%" }}
          onClick={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          {buttonLabel}
        </Button>
      </Box>
    </form>
  );
};
