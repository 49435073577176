import { useMutation } from "@apollo/client";
import { OrganizationPrivileges } from "@blue-ocean-robotics/cloud-library";
import { CountryAutocomplete } from "components/atoms/autoComplete/CountryAutocomplete";
import { EditableAvatar } from "components/atoms/avatars/EditableAvatar";
import { OrganizationAvatar } from "components/atoms/avatars/OrganizationAvatar";
import { CloudLabel } from "components/atoms/CloudLabel";
import { FormikTextField } from "components/atoms/formik";
import { FormikAutocomplete } from "components/atoms/formik/FormikAutocomplete";
import { CloudDrawer, CloudDrawerProps } from "components/modules/CloudDrawer";
import { useFormik } from "formik";
import { graphql } from "gql";
import { CountryCodes, Organization } from "gql/graphql";
import _ from "lodash";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { isSupportPath } from "utils/privileges/privilegeUtils";
import { usePrivilege } from "utils/privileges/usePrivileges";
import * as Yup from "yup";
import { ORG_LIST_QUERY } from "../Organizations";
import { ORG_BASIC_QUERY } from "../organizationWidgets/OrganizationBasicInfo";

export const UPDATE_ORG = graphql(`
  mutation EditOrganizationBasicInfo(
    $orgId: String!
    $orgName: String
    $country: CountryCodes
    $odooLink: String
    $confluenceLink: String
    $hubSpotLink: String
  ) {
    updateOrganization(
      id: $orgId
      name: $orgName
      country: $country
      customerPage: $odooLink
      customerRecordPage: $confluenceLink
      hubSpotPage: $hubSpotLink
    ) {
      id
    }
  }
`);

const DELETE_ORG = graphql(`
  mutation DeleteOrganization($orgId: String!) {
    deleteOrganization(id: $orgId) {
      id
    }
  }
`);

interface Props {
  open: CloudDrawerProps["open"];
  onClose: CloudDrawerProps["onClose"];
  organization: any;
}

type Values = {
  name?: string;
  country?: CountryCodes;
  odoo?: string;
  hubspot?: string;
  confluence?: string;
};

export const EditOrganizationBasicInfo = ({
  open,
  onClose,
  organization: orgFromProps,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const organization = orgFromProps as Organization;
  const isOnSupportPath = isSupportPath(location);

  const canUpdateOrganization = usePrivilege(
    OrganizationPrivileges.Update,
    organization?.id,
  ).hasPrivilege;
  const canDeleteOrganization = usePrivilege(
    OrganizationPrivileges.Delete,
    organization.id,
  ).hasPrivilege;

  const allRobotsInactive =
    organization?.robotAffiliations.length > 0 &&
    _.every(organization?.robotAffiliations, ["active", null]);

  const [updateOrganization] = useMutation(UPDATE_ORG, {
    refetchQueries: [
      {
        query: ORG_BASIC_QUERY,
        variables: {
          orgId: organization?.id || "",
        },
      },
    ],
  });

  const [deleteOrganization] = useMutation(DELETE_ORG, {
    refetchQueries: [
      {
        query: ORG_LIST_QUERY,
      },
    ],
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const formik = useFormik<Values>({
    initialValues: {
      name: organization?.name || "",
      country: organization?.country ?? undefined,
      odoo: organization?.customerPage ?? "",
      hubspot: organization?.hubSpotPage ?? "",
      confluence: organization?.customerRecordPage ?? "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        intl.formatMessage({
          id: "required",
          defaultMessage: "Required",
        }),
      ),
      country: Yup.string(),
      odoo: Yup.string().url("Must be a valid URL"),
      hubspot: Yup.string().url("Must be a valid URL"),
      confluence: Yup.string().url("Must be a valid URL"),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Updating organization...", {
        variant: "loading",
        persist: true,
      });

      updateOrganization({
        variables: {
          orgId: organization?.id || "",
          orgName: values.name?.trim(),
          country: values.country,
          odooLink: formik.values.odoo?.trim(),
          confluenceLink: formik.values.confluence?.trim(),
          hubSpotLink: formik.values.hubspot?.trim(),
        },
        onCompleted: () => {
          enqueueSnackbar(`Updated organization`, {
            variant: "success",
          });
          closeSnackbar(snackbarId);
        },
        onError: () => {
          enqueueSnackbar(`Could not update organization`, {
            variant: "error",
          });
          closeSnackbar(snackbarId);
        },
      });

      handleClose();
    },
  });

  useEffect(() => {
    (() => formik.validateForm())();
  }, []);

  const handleDeleteOrganization = () => {
    const snackbarId = enqueueSnackbar("Deleting organization...", {
      variant: "loading",
      persist: true,
    });
    handleClose();

    deleteOrganization({
      variables: {
        orgId: organization?.id || "",
      },
      onCompleted: () => {
        enqueueSnackbar(`${organization?.name || "organization"} was deleted`, {
          variant: "success",
        });
        closeSnackbar(snackbarId);
        navigate("/cloud/support/organizations");
      },
      onError: () => {
        enqueueSnackbar(
          `Could not delete ${organization?.name || "organization"}`,
          {
            variant: "error",
          },
        );
        closeSnackbar(snackbarId);
      },
    });
  };

  return (
    <form>
      <CloudDrawer
        open={open}
        onClose={handleClose}
        header="Edit Organization Details "
        action={{
          label: "Save changes",
          onClick: formik.handleSubmit,
          disabled:
            formik.isValid === false || formik.values == formik.initialValues,
        }}
        secondaryAction={
          canDeleteOrganization
            ? {
                label: "Delete organization",
                onClick: handleDeleteOrganization,
                disabled: !allRobotsInactive,
                toolTip:
                  "Please remove all active robots before deleting organization",
              }
            : undefined
        }
      >
        <EditableAvatar
          defaultAvatar={
            <OrganizationAvatar size="large" orgId={organization?.id} />
          }
        />

        <FormikTextField
          name="name"
          formik={formik}
          label="Organization name"
          placeholder="Organization name"
          disabled={!canUpdateOrganization}
        />
        <CloudLabel>Country</CloudLabel>
        <FormikAutocomplete
          name="country"
          formik={formik}
          multiple={false}
          component={CountryAutocomplete}
          placeholder="Select country"
          disabled={!canUpdateOrganization}
          hideSearchIcon
        />
        {isOnSupportPath ? (
          <>
            <FormikTextField
              name="odoo"
              formik={formik}
              label={intl.formatMessage({
                id: "organization.odooLink",
                defaultMessage: "Odoo",
              })}
              placeholder={intl.formatMessage({
                id: "link.odoo",
                defaultMessage: "Odoo URL",
              })}
            />
            <FormikTextField
              name="hubspot"
              formik={formik}
              label={intl.formatMessage({
                id: "organization.hubspotLink",
                defaultMessage: "Hubspot",
              })}
              placeholder={intl.formatMessage({
                id: "link.hubspot",
                defaultMessage: "HubSpot URL",
              })}
            />
            <FormikTextField
              name="confluence"
              formik={formik}
              label={intl.formatMessage({
                id: "organization.confluenceLink",
                defaultMessage: "Confluence",
              })}
              placeholder={intl.formatMessage({
                id: "link.confluence",
                defaultMessage: "Confluence URL",
              })}
            />
          </>
        ) : null}
      </CloudDrawer>
    </form>
  );
};
