import { Box, useTheme } from "@mui/material";
import { GoogleIcon } from "assets/icons/GoogleIcon";
import { MicrosoftIcon } from "assets/icons/MicrosoftIcon";
import { InlineCard } from "components/atoms/InlineCard";
import { StatusCardContent } from "components/modules/cards/StatusCardContent";
import { ManageDomain } from "components/organisms/panels/ManageDomain";
import { DomainVerification } from "gql/graphql";
import { useMemo, useState } from "react";
import { IoSettingsOutline } from "react-icons/io5";

export const DNSVerificationCard = (verification: DomainVerification) => {
  const theme = useTheme();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);

  const { verified, hostname } = verification;

  const statusText = useMemo(
    () => (verified ? "Verified" : "Not verified"),
    [verified],
  );

  const statusColor = useMemo(() => {
    switch (verified) {
      case true:
        return theme.palette.success.main;
      case false:
      default:
        return theme.palette.warning.main;
    }
  }, [verified]);

  return (
    <>
      <InlineCard onClick={() => setDrawerOpen(true)}>
        <StatusCardContent
          header={hostname}
          description={statusText}
          statusColor={
            verificationFailed ? theme.palette.error.main : statusColor
          }
          wrapperStyle={{ flexGrow: 1 }}
          action={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  height: "24px",
                  marginRight: 1,
                  "& img": {
                    width: "24px",
                    height: "24px",
                  },
                }}
              >
                <MicrosoftIcon />
              </Box>
              <Box
                sx={{
                  height: "24px",
                  marginRight: 3,
                  "& svg": {
                    width: "24px",
                    height: "24px",
                  },
                }}
              >
                <GoogleIcon />
              </Box>
              <IoSettingsOutline size={18} />
            </Box>
          }
        />
      </InlineCard>
      {drawerOpen ? (
        <ManageDomain
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          verification={verification}
          statusColor={statusColor}
          statusText={statusText}
          onVerificationError={() => setVerificationFailed(true)}
        />
      ) : null}
    </>
  );
};
