import { Box, IconButton, Typography } from "@mui/material";
import { ReactElement } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

interface MenuTitleProps {
  title: string | ReactElement;
  icon?: ReactElement;
  orgId?: string;
  hideSettings?: boolean;
}

export const MenuTitle = ({
  title,
  orgId,
  hideSettings = false,
}: MenuTitleProps) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        ":hover": {
          button: {
            display: "inherit",
          },
        },
        button: {
          display: "none",
          ":hover": {
            cursor: "pointer",
          },
        },
        svg: {
          color: theme.palette.grey[700],
        },
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px 0px 0px 0px",
          width: "100%",
        }}
      >
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: theme.palette.grey[700],
            fontWeight: 700,
            textTransform: "uppercase",
            lineHeight: "20px",
            padding: "8px 0px",
            maxWidth: 180,
          })}
        >
          {title}
        </Typography>
        {hideSettings ? null : (
          <IconButton
            sx={{
              minWidth: 38,
              minHeight: 38,
              margin: "-8px 0px",
            }}
            onClick={() => navigate(`fm/${orgId}/settings`)}
          >
            <IoSettingsOutline size={20} style={{ marginRight: 2 }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
