import { Box, BoxProps, Typography } from "@mui/material";
import { ReactElement } from "react";

interface ListItemProps {
  label: string | ReactElement;
  value?: ReactElement;
  disabled?: boolean;
}

export const CloudListItem = ({
  label,
  value,
  disabled,
  ...rest
}: ListItemProps & BoxProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        padding: "6px 10px",
        flexDirection: "row",
        alignItems: "center",
        cursor: disabled ? "auto" : "pointer ",
        ":hover p": {
          color: disabled ? "auto" : theme.palette.grey[600],
        },
      })}
      {...rest}
    >
      <Typography
        variant="body1"
        sx={(theme) => ({
          color: theme.palette.grey[900],
          display: "flex",
          flex: 1,
        })}
      >
        {label}
      </Typography>
      {value ? (
        <Box
          sx={() => ({
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
          })}
        >
          {value}
        </Box>
      ) : null}
    </Box>
  );
};
