import { useSubscription } from "@apollo/client";
import { Box, Skeleton, Typography } from "@mui/material";
import { Battery } from "components/atoms/battery/Battery";
import { UVDIcon } from "components/atoms/icons/UVDPulseIcon/UVDIcon";
import { UVDPulseIcon } from "components/atoms/icons/UVDPulseIcon/UVDPulseIcon";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { graphql } from "gql";
import { UvdRobot } from "gql/graphql";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { getUvdRobotStatus } from "utils/utilFunctions";
import {
  useUvdConnectionStatus,
  UVDConnectionStatus,
} from "utils/UVD/useUvdConnectionStatus";
import {
  getChargingStatus,
  getRobotStatus,
} from "utils/UVD/UVDRobotStatusConverter";
import { ON_ROBOT_CHANGED } from "../UnassignedRobots";

const QUERY = graphql(`
  query RobotBasicInfo($robotId: String!) {
    robot(id: $robotId) {
      id
      serialNumber
      ... on UvdRobot {
        active {
          organization {
            id
          }
        }
        status {
          batteryMergerPercentage
          job {
            state
            type
          }
          lastTimeActive
        }
        internetPreference
      }
    }
  }
`);

export const RobotBasicInfo = () => {
  const { robotId } = useParams();
  const [robot, setRobot] = useState<UvdRobot | undefined>(undefined);

  const { data, loading } = useQueryWithSnack(QUERY, {
    variables: { robotId: robotId ?? "" },
  });

  useEffect(() => {
    setRobot(_.merge(robot, data?.robot) as UvdRobot | undefined);
  }, [data]);

  const { data: subscriptionData } = useSubscription(ON_ROBOT_CHANGED);

  useEffect(() => {
    if (robot && subscriptionData && subscriptionData.robotChanged) {
      const subRobot = subscriptionData.robotChanged as UvdRobot;
      if (robot.id == subRobot.id) {
        setRobot(_.merge(robot, subRobot) as UvdRobot | undefined);
      }
    }
  }, [subscriptionData]);

  const connectionStatus = useUvdConnectionStatus(robot);

  const isOffline = connectionStatus == UVDConnectionStatus.OFFLINE;

  const chargingStatus = robot?.status?.job
    ? getChargingStatus(robot.status.job)
    : null;

  const robotStatus = robot?.status?.job
    ? getRobotStatus(robot.status.job)
    : null;

  const isDocked = robotStatus === "Docked";

  const subheader = useMemo(() => {
    const labelArr = [
      getUvdRobotStatus(robot, connectionStatus),
      chargingStatus?.charging ? "Charging" : undefined,
    ].filter((item) => item);

    return labelArr.join(" - ");
  }, [robot, chargingStatus, connectionStatus]);

  if (loading) return <Skeleton />;

  return (
    <WidgetPaper
      sx={{
        position: "relative",
        overflow: "hidden",
        padding: 6,
        minHeight: connectionStatus == UVDConnectionStatus.UNKNOWN ? 0 : 62,
      }}
    >
      <UVDPulseIcon
        isOffline={isOffline}
        showPulse={
          !isOffline &&
          connectionStatus !== UVDConnectionStatus.UNKNOWN &&
          !isDocked
        }
      />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row">
            <Box position="relative" height={24} mr={4}>
              <UVDIcon
                isOffline={
                  isOffline || connectionStatus === UVDConnectionStatus.UNKNOWN
                }
              />
            </Box>
            <Box>
              <Typography
                variant="h4"
                color={(theme) =>
                  isOffline ? theme.palette.grey[400] : "auto"
                }
              >
                {robot?.serialNumber}
              </Typography>
              <Typography
                variant="body2"
                color={(theme) =>
                  isOffline ? theme.palette.grey[400] : theme.palette.grey[600]
                }
              >
                {subheader}
              </Typography>
            </Box>
          </Box>
          {connectionStatus ? (
            <Typography mt={4} variant="bodyS">
              {robot?.internetPreference ? (
                <Typography
                  variant="bodyS"
                  color={(theme) =>
                    isOffline ? theme.palette.grey[400] : "auto"
                  }
                >
                  {robot?.internetPreference === "wifi" ? (
                    <FormattedMessage
                      id="internetPreference.wifi"
                      defaultMessage="Via Wi-Fi"
                    />
                  ) : (
                    <FormattedMessage
                      id="internetPreference.data"
                      defaultMessage="Via data"
                    />
                  )}
                </Typography>
              ) : null}
            </Typography>
          ) : null}
        </Box>
        {connectionStatus ? (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Battery
              batteryPercent={robot?.status?.batteryMergerPercentage ?? 0}
              charging={chargingStatus?.charging ?? false}
              pluggedIn={chargingStatus?.pluggedIn ?? false}
              isOffline={isOffline}
              iconSize={60}
            />
            <Typography
              ml={2}
              fontSize={34}
              fontWeight={700}
              color={(theme) =>
                isOffline ? theme.palette.grey[400] : "inherit"
              }
            >
              {Math.floor(robot?.status?.batteryMergerPercentage ?? 0)}%
            </Typography>
          </Box>
        ) : null}
      </Box>
    </WidgetPaper>
  );
};
