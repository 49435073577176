import { useMutation } from "@apollo/client";
import {
  Box,
  ClickAwayListener,
  IconButton,
  Popper,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { CloudLabel } from "components/atoms/CloudLabel";
import { CloudListItem } from "components/atoms/dashboard/Identity/popup/CloudListItem";
import { CloudListItemGroup } from "components/atoms/dashboard/Identity/popup/CloudListItemGroup";
import { InlineCardEmptyView } from "components/atoms/InlineCardEmptyView";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { AlertDialog } from "components/modules/AlertDialog";
import { SimpleTable } from "components/modules/SimpleTable";
import { RobotCell } from "components/modules/tableCells/RobotCell";
import { RobotDisinfectionCountCell } from "components/modules/tableCells/RobotDisinfectionCountCell";
import { graphql } from "gql";
import { Organization, RobotAffiliation, UvdRobot } from "gql/graphql";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useMemo, useState } from "react";
import {
  IoChevronForward,
  IoEllipsisHorizontal,
  IoInformationCircleOutline,
} from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isSupportPath } from "utils/privileges/privilegeUtils";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

export const ORG_DATA_SOURCES_QUERY = graphql(`
  query DisinfectionDataSources($orgId: String!) {
    robots(organizationId: $orgId) {
      count
      robots {
        id
        active {
          id
          organization {
            id
          }
        }
        affiliations {
          id
          organization {
            id
          }
        }
        ... on UvdRobot {
          status {
            lastTimeActive
          }
          serialNumber
        }
      }
    }
    organization(id: $orgId) {
      name
    }
  }
`);

const DELETE_ROBOT = graphql(`
  mutation DeleteRobotAffiliation($affId: String!) {
    deleteRobotAffiliation(id: $affId) {
      id
    }
  }
`);

export const DisinfectionDataSources = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isOnSupportPath = isSupportPath(location);

  const [selectedRobotAff, setSelectedRobotAff] = useState<any>(null);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data, loading } = useQueryWithSnack(ORG_DATA_SOURCES_QUERY, {
    variables: { orgId: orgId || "" },
    skip: !orgId,
    fetchPolicy: "no-cache",
  });

  const [deleteRobotAffiliation] = useMutation(DELETE_ROBOT, {
    refetchQueries: [
      {
        query: ORG_DATA_SOURCES_QUERY,
        variables: {
          orgId: orgId || "",
        },
        fetchPolicy: "no-cache",
      },
    ],
  });

  const robots = data?.robots.robots as UvdRobot[];
  const organization = data?.organization as Organization;

  const handleDeleteRobotAff = () => {
    setAlertDialogOpen(false);
    const snackbarId = enqueueSnackbar("Removing robot data access...", {
      variant: "loading",
      persist: true,
    });

    const affiliation = robots
      .find((item) => item.id === selectedRobotAff.id)
      ?.affiliations.find((el) => el.organization.id === orgId);

    deleteRobotAffiliation({
      variables: {
        affId: affiliation?.id || "",
      },
      onCompleted: () => {
        enqueueSnackbar(`Robot data access was removed`, {
          variant: "success",
        });
        closeSnackbar(snackbarId);
      },
      onError: () => {
        enqueueSnackbar(`Could not remove data access`, {
          variant: "error",
        });
        closeSnackbar(snackbarId);
      },
    });
  };

  const activeRobotAffs = robots?.filter(
    (item) => item.active?.organization?.id === orgId,
  );
  const activeRobotAffIds = activeRobotAffs?.map((item) => item.id);
  const prevRobotAffs = robots?.filter(
    (item) => !activeRobotAffIds.includes(item.id),
  );

  const hasRobots = activeRobotAffs?.length > 0 || prevRobotAffs?.length > 0;
  const totalRobotCount = activeRobotAffs?.length + prevRobotAffs?.length;

  const description = useMemo(() => {
    if (totalRobotCount === data?.robots.count) {
      return `${
        organization?.name || "This organization"
      } can access data from ${data?.robots.count} robots`;
    } else {
      const diffCount = (data?.robots.count || 0) - totalRobotCount;
      return `${
        organization?.name || "This organization"
      } can access data from ${data?.robots
        .count} robots. ${diffCount} of them are hidden for you.`;
    }
  }, [totalRobotCount, data?.robots.count]);

  const handleNavigateToRobotPage = (row: RobotAffiliation, e: any) => {
    if (isOnSupportPath) {
      navigate(`/cloud/support/robots/${row?.id}`);
      e.stopPropagation();
    } else if (organization?.id || orgId) {
      navigate(`/cloud/fm/${organization?.id || orgId}/robots/${row?.id}`);
      e.stopPropagation();
    } else {
      return;
    }
  };

  const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  if (loading) return <Skeleton variant="rounded" height={150} />;

  return (
    <>
      <WidgetPaper>
        <WidgetHeader
          label="Disinfection data sources"
          description={hasRobots ? description : undefined}
        />

        {hasRobots ? (
          <>
            <CloudLabel>Active robots</CloudLabel>
            {activeRobotAffs?.length > 0 ? (
              <SimpleTable
                data={activeRobotAffs}
                onRowClick={handleNavigateToRobotPage}
                columnConfig={[
                  {
                    key: "robot",
                    renderCell: (row: RobotAffiliation) => (
                      <RobotCell
                        robot={row}
                        showStatusDot
                        orgId={orgId}
                        disableHoverOnRobotName
                      />
                    ),
                    sx: { width: 200 },
                  },
                  {
                    key: "disinfections",
                    renderCell: (row) => {
                      return (
                        <RobotDisinfectionCountCell
                          robot={row}
                          hideDescription
                        />
                      );
                    },
                  },
                  {
                    key: "navigation",
                    renderCell: () => (
                      <IoChevronForward size={16} style={{ marginRight: 8 }} />
                    ),
                    tableCellProps: { align: "right" },
                  },
                ]}
              />
            ) : (
              <InlineCardEmptyView label="No active robots in this organization" />
            )}
            <CloudLabel>
              Previous robots
              <Tooltip
                title={`These robots have been removed from ${organization?.name}. ${organization?.name} users can still see this disinfection data`}
                arrow
                placement="top"
              >
                <Box sx={{ display: "inline" }}>
                  <IoInformationCircleOutline
                    size={16}
                    style={{ paddingBottom: 4, paddingLeft: 4 }}
                  />
                </Box>
              </Tooltip>
            </CloudLabel>

            {prevRobotAffs?.length > 0 ? (
              <>
                <SimpleTable
                  data={prevRobotAffs}
                  tableRowProps={{
                    sx: { pointerEvents: !isOnSupportPath ? "none" : "auto" },
                  }}
                  columnConfig={[
                    {
                      key: "robot",
                      renderCell: (row: RobotAffiliation) => (
                        <RobotCell
                          robot={row}
                          showStatusDot
                          disableHoverOnRobotName
                        />
                      ),
                      sx: { width: 200 },
                    },
                    {
                      key: "disinfections",
                      renderCell: (row) => (
                        <RobotDisinfectionCountCell robot={row} />
                      ),
                    },
                    {
                      key: "navigation",
                      renderCell: (row: RobotAffiliation) => {
                        if (!isOnSupportPath) return null;

                        return (
                          <ClickAwayListener
                            onClickAway={() => setAnchorEl(null)}
                          >
                            <IconButton
                              onClick={(e) => {
                                handleOpenPopper(e);
                                setSelectedRobotAff(row);
                                e.stopPropagation();
                              }}
                            >
                              <IoEllipsisHorizontal size={16} />
                            </IconButton>
                          </ClickAwayListener>
                        );
                      },
                      tableCellProps: { align: "right" },
                    },
                  ]}
                />
              </>
            ) : (
              <InlineCardEmptyView label="No previous robots in this organization" />
            )}
          </>
        ) : (
          <InlineCardEmptyView
            label={`${organization?.name} does not have access to any robot data`}
            sx={{ marginTop: 3 }}
          />
        )}
      </WidgetPaper>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{
          borderRadius: "5px",
          background: "white",
          boxShadow:
            "0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <CloudListItemGroup>
          <CloudListItem
            label="Remove data access"
            onClick={() => {
              setAnchorEl(null);
              setAlertDialogOpen(true);
            }}
          />
        </CloudListItemGroup>
      </Popper>
      <AlertDialog
        header="Remove data access"
        confirmLabel="Remove"
        open={alertDialogOpen}
        onClose={() => setAlertDialogOpen(false)}
        alertMessage={`Are you sure you want to permanently remove ${organization?.name}'s access to data from robot ${selectedRobotAff?.robot?.serialNumber}?`}
        onConfirm={handleDeleteRobotAff}
      />
    </>
  );
};
