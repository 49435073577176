import { Box, Typography } from "@mui/material";
import { RobotStatusDot } from "components/atoms/RobotStatusDot";
import { useLocation, useNavigate } from "react-router-dom";
import { isSupportPath } from "utils/privileges/privilegeUtils";
import { getUVDSerialNumber } from "utils/UVD/getUVDRobotSerialNumber";
import { useUvdConnectionStatus } from "utils/UVD/useUvdConnectionStatus";
import {
  RobotCardContent,
  RobotCardContentProps,
} from "../cards/RobotCardContent";

interface RobotCellProps extends RobotCardContentProps {
  serialNumber?: string;
  showStatusDot?: boolean;
  disableHoverOnRobotName?: boolean;
  showOrg?: boolean;
  orgId?: string;
}

export const RobotCell = ({
  robot,
  showStatusDot = false,
  disableHoverOnRobotName = false,
  showOrg = false,
  serialNumber,
  orgId,
}: RobotCellProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isOnSupportPath = isSupportPath(location);
  const status = useUvdConnectionStatus(robot);

  const handleNavigateToRobotPage = (e: any) => {
    if (isOnSupportPath) {
      navigate(`/cloud/support/robots/${robot?.id}`);
      e.stopPropagation();
    } else if (robot?.active?.organization?.id || orgId) {
      navigate(
        `/cloud/fm/${
          robot?.active?.organization?.id || orgId
        }/robots/${robot?.id}`,
      );
      e.stopPropagation();
    } else {
      return;
    }
  };

  const disableRobotNavigation =
    disableHoverOnRobotName || !(robot?.active?.organization?.id || orgId);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {showStatusDot ? (
        <RobotStatusDot sx={{ marginRight: 0 }} status={status} />
      ) : null}
      <RobotCardContent
        robot={robot}
        header={
          <Typography
            component="span"
            onClick={
              disableRobotNavigation ? undefined : handleNavigateToRobotPage
            }
            mb={0.5}
            noWrap
            sx={(theme) => ({
              maxWidth: "110px",
              display: "block",
              pointerEvents: disableRobotNavigation ? "none" : "auto",
              cursor: disableRobotNavigation ? "auto" : "pointer",
              ":hover": {
                color: disableRobotNavigation
                  ? "auto"
                  : theme.palette.primary.main,
              },
            })}
          >
            {serialNumber
              ? getUVDSerialNumber(serialNumber)
              : robot?.serialNumber}
          </Typography>
        }
        description={
          showOrg ? robot?.active?.organization?.name || "-" : undefined
        }
        size="small"
        hideIcon
        wrapperStyle={{ padding: 0 }}
      />
    </Box>
  );
};
