import { Box, Typography } from "@mui/material";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { ReactElement } from "react";

interface Props {
  header: string | ReactElement;
  description?: string | ReactElement;
  value: number | string;
  actionCardChildren?: ReactElement;
  postfix?: ReactElement | string;
}

export const NumberChart = ({
  header,
  description,
  value,
  actionCardChildren,
  postfix,
}: Props) => {
  return (
    <WidgetPaper
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
      >
        <WidgetHeader label={header} description={description} />
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={(theme) => ({
              display: "block",
              fontSize: "72px",
              lineHeight: "72px",
              fontWeight: 700,
              fontFamily: theme.typography.fontFamily,
            })}
          >
            {value}
          </Typography>
          {postfix ? (
            <Typography
              sx={(theme) => ({ fontSize: 24, color: theme.palette.grey[400] })}
            >
              {postfix}
            </Typography>
          ) : null}
        </Box>
      </Box>
      {actionCardChildren}
    </WidgetPaper>
  );
};
