import { Autocomplete, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { useUserDisplayName } from "utils/useUserDisplayName";
import { graphql } from "../../../gql";
import { CloudTextField } from "../CloudTextField";
import { CustomPaper } from "./CloudAutocomplete";

const QUERY = graphql(`
  query UserAutoComplete {
    identities {
      id
      firstName
      lastName
      email
    }
  }
`);

export const UserAutoComplete = (props: any) => {
  const [emails, setEmails] = useState<any>([]);

  const { data } = useQueryWithSnack(QUERY);

  useEffect(() => {
    props.onChange(emails.map((item: any) => item.email || item.inputValue));
  }, [emails]);

  return (
    <Autocomplete
      multiple
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      autoComplete={false}
      sx={{
        height: "48px",
        ...props.sx,
      }}
      value={emails}
      onChange={(event, newValue: any) => {
        setEmails(newValue);
      }}
      filterOptions={(options: any, params: any) => {
        if (params.inputValue !== "") {
          options.unshift({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }

        return options;
      }}
      options={data?.identities || []}
      getOptionLabel={(option: any) => {
        if (option.inputValue) {
          return option.inputValue;
        }

        const displayName = useUserDisplayName(option);
        return displayName;
      }}
      renderOption={(props: any, option: any) => {
        if (option.inputValue) {
          return <li {...props}>{option.title}</li>;
        }
        const displayName = useUserDisplayName(option);

        return (
          <li {...props} key={option.id}>
            {displayName}
            <span
              style={{
                paddingLeft: 4,
                fontSize: 10,
                color: "grey",
              }}
            >
              {option.email}
            </span>
          </li>
        );
      }}
      PaperComponent={CustomPaper}
      renderInput={(params: any) => (
        <CloudTextField
          {...params}
          {...props.textfieldprops}
          autoComplete="off"
          fullWidth
          placeholder={
            emails.length === 0
              ? "Select existing users or add email of new users"
              : ""
          }
          InputProps={{
            ...params.InputProps,
            autoComplete: "off",
            disableUnderline: true,
            startAdornment: props.hideSearchIcon ? undefined : (
              <Box
                sx={(theme) => ({
                  "& svg:first-child": {
                    color: theme.palette.grey[500],
                  },
                })}
              >
                <IoSearch
                  size={20}
                  style={{
                    marginRight: 8,
                  }}
                />
                {params.InputProps.startAdornment}
              </Box>
            ),
          }}
        />
      )}
    />
  );
};
