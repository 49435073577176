import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  TableRowProps,
} from "@mui/material";
import { ReactElement } from "react";

export const SimpleTable = ({
  data,
  columnConfig,
  onRowClick,
  tableRowProps,
}: {
  data: Array<any> | undefined;
  columnConfig: Array<{
    key: string;
    renderCell: (row: any) => ReactElement | null;
    sx?: any;
    tableCellProps?: TableCellProps;
  }>;
  onRowClick?: any;
  tableRowProps?: TableRowProps;
}) => {
  return (
    <Table sx={{ width: "100%" }}>
      <TableBody>
        {data?.map((item: any) => (
          <TableRow
            onClick={!!onRowClick ? (e: any) => onRowClick(item, e) : undefined}
            key={item?.id || "table.id"}
            {...tableRowProps}
          >
            {columnConfig.map((column: any) => (
              <TableCell
                key={column.key}
                sx={column.sx}
                {...column.tableCellProps}
              >
                {column.renderCell(item)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
