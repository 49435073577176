import { RoleType } from "gql/graphql";
import { defineMessages } from "react-intl";
import { DefineMsg } from "types/types";

export const roles: DefineMsg = defineMessages({
  [RoleType.Viewer]: {
    id: "enum.Viewer",
    defaultMessage: "Dashboard Viewer",
  },
  [RoleType.Editor]: {
    id: "enum.Editor",
    defaultMessage: "Editor",
  },
  [RoleType.Owner]: {
    id: "enum.Owner",
    defaultMessage: "Owner",
  },
  [RoleType.FirstLevel]: {
    id: "enum.FirstLevel",
    defaultMessage: "Distributor",
  },
  [RoleType.SecondLevel]: {
    id: "enum.SecondLevel",
    defaultMessage: "UVD Support",
  },
});
