import { gql } from "@apollo/client";
import { UserAvatar } from "components/atoms/avatars/UserAvatar";
import { Identity } from "gql/graphql";
import { useMemo } from "react";
import { useUserDisplayName } from "utils/useUserDisplayName";
import { CloudCardContent, CloudCardContentProps } from "./CloudCardContent";

const USERCARDCONTENT_FRAGMENT = gql`
  fragment UserCardContent on Identity {
    firstName
    lastName
    email
    ...UserAvatar
    ...UserDisplayName
  }
  ${UserAvatar.fragments.identity}
  ${useUserDisplayName.fragments.identity}
`;

export interface UserCardContentProps extends CloudCardContentProps {
  identity?: Partial<Identity>; // TODO: make inviteState required
}

export const UserCardContent = ({
  identity,
  header: headerProp,
  description: descriptionProp,
  hideDescription,
  hideIcon = false,
  size,
  ...props
}: UserCardContentProps) => {
  const displayName = useUserDisplayName(identity);

  const header = useMemo(() => {
    return headerProp ? headerProp : displayName;
  }, [headerProp, identity]);

  const description = useMemo(() => {
    if (!identity?.firstName && !identity?.lastName) {
      return undefined;
    }
    return descriptionProp ? descriptionProp : identity?.email;
  }, [descriptionProp, identity]);

  return (
    <CloudCardContent
      size={size}
      header={header || "-"}
      description={hideDescription ? undefined : description}
      hideIcon={hideIcon}
      avatar={
        <UserAvatar size={size} identity={identity} sx={{ marginRight: 2 }} />
      }
      wrapperStyle={{ padding: "8px 0px" }}
      {...props}
    />
  );
};

UserCardContent.fragments = {
  identity: USERCARDCONTENT_FRAGMENT,
};
