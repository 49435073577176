import { gql } from "@apollo/client";
import { Box, Skeleton } from "@mui/material";
import { CloudLabel } from "components/atoms/CloudLabel";
import { InlineCardEmptyView } from "components/atoms/InlineCardEmptyView";
import { MoreText } from "components/atoms/MoreText";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { UserCard } from "components/modules/cards/UserCard";
import { Organization } from "gql/graphql";
import _ from "lodash";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "routing/Routes";
import { getOrganizationAdmins } from "utils/affiliationUtils";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { useUserDisplayName } from "utils/useUserDisplayName";
import { InviteUser } from "../dialogs/InviteUser";

export const ORGUSER_QUERY = gql`
  query OrgUsers($orgId: String!) {
    organization(id: $orgId) {
      id
      name
      country
      affiliations {
        id
        identity {
          id
          firstName
          lastName
          email
        }
        roles {
          title
        }
      }
    }
  }
`;

export const OrgUsers = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();

  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);

  const { data, loading } = useQueryWithSnack(ORGUSER_QUERY, {
    variables: { orgId: orgId ?? "" },
    skip: !orgId,
  });

  const organization = data?.organization as Organization;
  const normalUsers = organization?.affiliations.filter(
    (item) =>
      item.roles?.find(
        (role) => role.title !== "Owner" && role.title !== "Editor",
      ),
  );
  const displayUser = _.first(normalUsers);
  const displayName = useUserDisplayName(displayUser?.identity);

  const { owners, editors } = getOrganizationAdmins(
    data?.organization.affiliations,
  );

  if (loading) return <Skeleton height={250} />;

  return (
    <>
      <WidgetPaper>
        <WidgetHeader
          label="Users"
          buttonLabel="Invite user"
          onButtonClick={() => setInviteUserDialogOpen(true)}
        />
        <Box display="flex" flexDirection="column">
          <CloudLabel>Owners</CloudLabel>
          <Box gap={2} display="flex" flexDirection="column">
            {owners.length > 0 ? (
              owners.map((item: any) => (
                <UserCard
                  key={item.id}
                  identity={item}
                  onClick={() => navigate(`/cloud/support/users/${item.id}`)}
                />
              ))
            ) : (
              <InlineCardEmptyView label="No Owners in this organization" />
            )}
          </Box>
          <CloudLabel>Editors</CloudLabel>
          <Box gap={2} display="flex" flexDirection="column">
            {editors.length > 0 ? (
              editors.map((item: any) => (
                <UserCard
                  key={item.id}
                  identity={item}
                  onClick={() => navigate(`/cloud/support/users/${item.id}`)}
                />
              ))
            ) : (
              <InlineCardEmptyView label="No Editors in this organization" />
            )}
          </Box>
          <CloudLabel>Others</CloudLabel>
          {displayUser ? (
            <UserCard
              onClick={() => navigate(ROUTES.support.users)} //TODO
              identity={displayUser?.identity}
              hideDescription
              header={
                <FormattedMessage
                  id="organization.users.count"
                  defaultMessage={
                    "{user} <light>{count, plural, =0 {} one {+# more user} other {+# more users}}</light>"
                  }
                  values={{
                    count: (normalUsers?.length || 0) - 1,
                    user: displayName,
                    light: (chunks) => <MoreText>{chunks}</MoreText>,
                  }}
                />
              }
            />
          ) : (
            <InlineCardEmptyView label="No Other Users in this organization" />
          )}
        </Box>
      </WidgetPaper>
      <InviteUser
        open={inviteUserDialogOpen}
        onClose={() => setInviteUserDialogOpen(false)}
        organization={organization}
      />
    </>
  );
};
