import { useMutation } from "@apollo/client";
import { CloudSteps } from "components/atoms/stepper/CloudStepper";
import { CloudDialogProps } from "components/modules/CloudDialog";
import { CloudStepsDialog } from "components/modules/CloudStepsDialog";
import { OrgNameAndCountry } from "components/modules/createOrganizationSteps/OrgNameAndCountry";
import { OrgOwnerEmail } from "components/modules/createOrganizationSteps/OrgOwnerEmail";
import { OrgSupportLinks } from "components/modules/createOrganizationSteps/OrgSupportLinks";
import { useFormik } from "formik";
import { graphql } from "gql";
import { CountryCodes, RoleType } from "gql/graphql";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ORG_LIST_QUERY } from "../Organizations";
import { UPDATE_ORG } from "../panels/EditOrganizationBasicInfo";
import { INVITE } from "./InviteUser";

const CREATE_ORG = graphql(`
  mutation CreateOrganization($orgName: String!, $country: CountryCodes) {
    createOrganization(name: $orgName, country: $country) {
      id
      name
    }
  }
`);

export const CreateOrganization = ({ open, onClose }: CloudDialogProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [stepIndex, setStepIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    formikOrgNameAndCountry.resetForm();
    formikOrgOwner.resetForm();
    formikOrgSupportLinks.resetForm();

    setErrorMessage("");

    setStepIndex(0);
    onClose();

    if (stepIndex > 0) handleShowSnackbar();
  };

  const handleSetStepIndex = (newIndex: number) => {
    setStepIndex(newIndex);
    setErrorMessage("");
  };

  const handleShowSnackbar = () => {
    enqueueSnackbar(`Created ${createOrgData?.createOrganization.name}`, {
      variant: "success",
      actionLabel: "Go to organization page",
      onActionClick: () =>
        navigate(
          `/cloud/support/organizations/${createOrgData?.createOrganization.id}`,
        ),
    });
  };

  const [
    createOrganization,
    { loading: creatingOrganization, data: createOrgData },
  ] = useMutation(CREATE_ORG, {
    refetchQueries: [
      {
        query: ORG_LIST_QUERY,
      },
    ],
  });

  const [inviteOwner, { loading: invitingOwner }] = useMutation(INVITE, {
    refetchQueries: [
      {
        query: ORG_LIST_QUERY,
      },
    ],
  });

  const [updateOrganization, { loading: updatingOrganization }] =
    useMutation(UPDATE_ORG);

  const formikOrgNameAndCountry = useFormik<{
    orgName: string;
    country?: CountryCodes;
  }>({
    initialValues: {
      orgName: "",
      country: undefined,
    },
    validationSchema: Yup.object({
      orgName: Yup.string().required(
        intl.formatMessage({
          id: "required",
          defaultMessage: "Required",
        }),
      ),
      country: Yup.string(),
    }),
    onSubmit(values) {
      createOrganization({
        variables: {
          orgName: values.orgName,
          country: values.country,
        },
        onCompleted: () => {
          handleSetStepIndex(1);
        },
        onError: () => {
          setErrorMessage("Could not create organization");
        },
      });
    },
  });

  const formikOrgOwner = useFormik<{ email?: string }>({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(""),
    }),
    onSubmit(values) {
      inviteOwner({
        variables: {
          inviteInputs: [{ email: values?.email || "", role: RoleType.Owner }],
          orgId: createOrgData?.createOrganization.id,
        },
        onCompleted: () => {
          handleSetStepIndex(2);
        },
        onError: () => {
          setErrorMessage("Could not save organization owner");
        },
      });
    },
  });

  const formikOrgSupportLinks = useFormik<{
    odooLink?: string;
    hubspotLink?: string;
    confluenceLink?: string;
  }>({
    initialValues: {
      odooLink: "",
      hubspotLink: "",
      confluenceLink: "",
    },
    validationSchema: Yup.object({
      odooLink: Yup.string().required("").url("Must be a valid URL"),
      hubspotLink: Yup.string().required("").url("Must be a valid URL"),
      confluenceLink: Yup.string().required("").url("Must be a valid URL"),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Finishing organization...", {
        variant: "loading",
        persist: true,
      });
      handleClose();

      updateOrganization({
        variables: {
          orgId: createOrgData?.createOrganization?.id || "",
          odooLink: values.odooLink?.trim(),
          confluenceLink: values.confluenceLink?.trim(),
          hubSpotLink: values.hubspotLink?.trim(),
        },
        onCompleted: (resp) => {
          enqueueSnackbar(`Created ${createOrgData?.createOrganization.name}`, {
            variant: "success",
            actionLabel: "Go to organization page",
            onActionClick: () =>
              navigate(
                `/cloud/support/organizations/${resp.updateOrganization.id}`,
              ),
          });
          closeSnackbar(snackbarId);
        },
        onError: () => {
          enqueueSnackbar("Could not save support links", {
            variant: "error",
          });
          closeSnackbar(snackbarId);
        },
      });
    },
  });

  const steps = [
    {
      hidden: false,
      label: "Create organization",
      component: () => (
        <OrgNameAndCountry
          formik={formikOrgNameAndCountry}
          disableForm={creatingOrganization}
          onChange={() => setErrorMessage("")}
        />
      ),
      onSubmit: formikOrgNameAndCountry.handleSubmit,
      submitLabel: "Create",
      submitDisabled:
        creatingOrganization || formikOrgNameAndCountry.isValid === false,
      loading: creatingOrganization,
    },
    {
      hidden: false,
      label: "Invite owner",
      component: () => (
        <OrgOwnerEmail formik={formikOrgOwner} disableForm={invitingOwner} />
      ),
      onSubmit: formikOrgOwner.handleSubmit,
      submitLabel: "Invite",
      secondaryButtonLabel: "Invite Owner later",
      onSecondaryButtonClick: () => handleSetStepIndex(2),
      submitDisabled: invitingOwner || formikOrgOwner.isValid === false,
      loading: invitingOwner,
    },
    {
      hidden: false,
      label: "Add links",
      component: () => (
        <OrgSupportLinks
          formik={formikOrgSupportLinks}
          disableForm={updatingOrganization}
        />
      ),
      onSubmit: formikOrgSupportLinks.handleSubmit,
      secondaryButtonLabel: "Add links later",
      onSecondaryButtonClick: handleClose,
      submitLabel: "Add",
      submitDisabled:
        updatingOrganization || formikOrgSupportLinks.isValid === false,
      loading: updatingOrganization,
    },
  ].filter((item) => !item.hidden) as CloudSteps;

  useEffect(() => {
    formikOrgNameAndCountry.validateForm();
    formikOrgOwner.validateForm();
    formikOrgSupportLinks.validateForm();
  }, [stepIndex]);

  return (
    <CloudStepsDialog
      header="New organization"
      open={open}
      onClose={handleClose}
      hideCancel
      steps={steps}
      stepIndex={stepIndex}
      errorMessage={errorMessage}
    />
  );
};
