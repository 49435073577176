import { Box, LinearProgressProps, Typography } from "@mui/material";
import { ReactElement } from "react";
import { LinearProgressBar } from "./LinearProgressBar";

interface Props {
  label: string | ReactElement;
  labelValue: string | number;
  percentageValue: number;
  color?: LinearProgressProps["color"];
  progressBarSx?: any;
  valuePostFix?: string;
}

export const CloudProgressBar = ({
  label,
  labelValue,
  percentageValue,
  color,
  progressBarSx,
  valuePostFix,
}: Props) => {
  return (
    <Box
      sx={() => ({
        display: "flex",
        width: "100%",
        alignItems: "center",
        flexDirection: "column",
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: theme.spacing(1),
        })}
      >
        <Typography>{label}</Typography>
        <Typography fontWeight={500}>
          {labelValue}
          {valuePostFix ? " " + valuePostFix : null}
        </Typography>
      </Box>
      <Box style={{ width: "100%" }}>
        <LinearProgressBar
          sx={progressBarSx}
          value={percentageValue}
          color={color || undefined}
        />
      </Box>
    </Box>
  );
};
