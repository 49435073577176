import { Box, styled } from "@mui/material";

import { CloudProgressBar } from "components/atoms/CloudProgressBar";
import { WidgetDescription } from "components/atoms/WidgetDescription";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { useDDFilterParams } from "components/modules/disinfectionFilter/DisinfectionFilterPanel";
import { graphql } from "gql";
import { DisinfectionFilter } from "gql/graphql";
import { FormattedMessage, useIntl } from "react-intl";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

const ProgressBarChartWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  alignItems: "center",
  flexDirection: "column",
  marginTop: theme.spacing(4),
}));

const QUERY = graphql(`
  query DisinfectionModes($filter: DisinfectionFilter!) {
    disinfectionModes(filter: $filter) {
      mode
      count
    }
  }
`);

interface Props {
  hiddenFilter?: DisinfectionFilter;
}

export const DisinfectionModesChart = ({ hiddenFilter }: Props) => {
  const intl = useIntl();

  const filterParams = useDDFilterParams();

  const { data } = useQueryWithSnack(QUERY, {
    variables: {
      filter: {
        ...filterParams,
        ...hiddenFilter,
      },
    },
  });

  const predefinedCount =
    data?.disinfectionModes.find((item) => item.mode === "Predefined")?.count ??
    0;
  const remoteCount =
    data?.disinfectionModes.find((item) => item.mode === "Remote controlled")
      ?.count ?? 0;
  const stationaryCount =
    data?.disinfectionModes.find((item) => item.mode === "Stationary")?.count ??
    0;

  const totalCount = predefinedCount + remoteCount + stationaryCount;

  return (
    <WidgetPaper
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          marginBottom: theme.spacing(1),
        })}
      >
        <WidgetHeader
          label={<FormattedMessage id="by-mode" defaultMessage="By mode" />}
        />
        <WidgetDescription>
          <FormattedMessage
            id="by-mode-description"
            defaultMessage="Number of times the disinfection modes were used in this period"
          />
        </WidgetDescription>
      </Box>
      <ProgressBarChartWrapper
        sx={(theme) => ({ marginTop: theme.spacing(2) })}
      >
        <CloudProgressBar
          label="Autonomous Mode"
          labelValue={intl.formatNumber(predefinedCount)}
          percentageValue={
            predefinedCount === 0 ? 0 : (predefinedCount / totalCount) * 100
          }
        />
      </ProgressBarChartWrapper>
      <ProgressBarChartWrapper>
        <CloudProgressBar
          label="Remote Controlled Mode"
          labelValue={intl.formatNumber(remoteCount)}
          percentageValue={
            remoteCount === 0 ? 0 : (remoteCount / totalCount) * 100
          }
        />
      </ProgressBarChartWrapper>
      <ProgressBarChartWrapper>
        <CloudProgressBar
          label="Stationary Mode"
          labelValue={intl.formatNumber(stationaryCount)}
          percentageValue={
            stationaryCount === 0 ? 0 : (stationaryCount / totalCount) * 100
          }
        />
      </ProgressBarChartWrapper>
    </WidgetPaper>
  );
};
