import { GenericPrivileges } from "@blue-ocean-robotics/cloud-library";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { ActivityWidget } from "components/organisms/activityWidgets/ActivityWidget";
import { DisinfectionDataSources } from "components/organisms/organizationWidgets/DisinfectionDataSources";
import { OrganizationBasicInfo } from "components/organisms/organizationWidgets/OrganizationBasicInfo";
import { OrganizationOnboardingStatus } from "components/organisms/organizationWidgets/OrganizationOnboardingStatus";
import { OrgDisinfectionCount } from "components/organisms/organizationWidgets/OrgDisinfectionCount";
import { OrgRooms } from "components/organisms/organizationWidgets/OrgRooms";
import { OrgUsers } from "components/organisms/organizationWidgets/OrgUsers";
import { UserAuthentication } from "components/organisms/organizationWidgets/UserAuthentication";
import { graphql } from "gql";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getIsForbidden, isSupportPath } from "utils/privileges/privilegeUtils";
import { PrivilegeWrapper } from "utils/privileges/PrivilegeWrapper";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { ForbiddenPage } from "./ForbiddenPage";

const QUERY = graphql(`
  query OrganizationPage($orgId: String!) {
    organization(id: $orgId) {
      id
      name
      onboardedAt
      createdAt
      activationState
      country
      robotAffiliations {
        id
      }
      affiliations {
        identity {
          id
          email
          firstName
          lastName
          inviteState
          firstLogin
          invitedDate
        }
        roles {
          date
          title
        }
      }
    }
  }
`);

const columnLeft = [
  "basicInfo",
  "disinfectionCount",
  "dataSources",
  "rooms",
  "activityLog",
];

const columnRight = ["status", "users", "auth"];

const smallScreenColumns = [
  "basicInfo",
  "status",
  "disinfectionCount",
  "dataSources",
  "rooms",
  "users",
  "auth",
  "activityLog",
];

export const OrganizationPage = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isOnSupportPath = isSupportPath(location);

  const { data, error, loading } = useQueryWithSnack(QUERY, {
    variables: { orgId: orgId ?? "" },
    skip: !orgId,
  });

  const renderColumn = (type: any) => {
    switch (type) {
      case "basicInfo":
        return (
          <Grid item mb={6} key={type}>
            <OrganizationBasicInfo />
          </Grid>
        );
      case "status":
        return (
          <Grid item mb={6} key={type}>
            <OrganizationOnboardingStatus
              organization={data?.organization}
              loading={loading}
            />
          </Grid>
        );
      case "disinfectionCount":
        return (
          <Grid item mb={6} key={type}>
            <OrgDisinfectionCount organization={data?.organization} />
          </Grid>
        );
      case "dataSources":
        return (
          <Grid item mb={6} key={type}>
            <DisinfectionDataSources />
          </Grid>
        );
      case "rooms":
        return location.pathname.includes("support") &&
          (data?.organization?.robotAffiliations || [])?.length > 0 ? (
          <Grid item mb={6} key={type}>
            <OrgRooms />
          </Grid>
        ) : null;
      case "users":
        return (
          <Grid item mb={6} key={type}>
            <OrgUsers />
          </Grid>
        );
      case "auth":
        return (
          <Grid item mb={6} key={type}>
            <UserAuthentication />
          </Grid>
        );
      case "activityLog":
        return isOnSupportPath ? (
          <PrivilegeWrapper privilege={GenericPrivileges.AuditLog} key={type}>
            <Grid item xs={12} mb={6}>
              <ActivityWidget mustInclude={[orgId ?? ""]} />
            </Grid>
          </PrivilegeWrapper>
        ) : null;
      default:
        return null;
    }
  };

  const widgetArr = largeScreen ? columnLeft : smallScreenColumns;

  const forbidden = getIsForbidden(error);

  if (forbidden) {
    return <ForbiddenPage />;
  }

  return (
    <Grid container spacing={6}>
      <HeaderGrid
        crumbs={
          loading
            ? []
            : location.pathname.includes("support")
              ? [
                  { label: "Support" },
                  {
                    label: "Organizations",
                    onClick: () => navigate("/cloud/support/organizations"),
                  },
                  { label: data?.organization.name ?? "-" },
                ]
              : [
                  { label: data?.organization.name ?? "-" },
                  { label: "Settings" },
                ]
        }
      />
      <Grid item xs={12} xl={6}>
        {widgetArr.map((item) => renderColumn(item))}
      </Grid>
      {largeScreen ? (
        <Grid item xs={12} xl={6}>
          {columnRight.map((item) => renderColumn(item))}
        </Grid>
      ) : null}
    </Grid>
  );
};
