import { useSubscription } from "@apollo/client";
import { RobotPrivileges } from "@blue-ocean-robotics/cloud-library";
import { Box, useTheme } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { CloudTextField } from "components/atoms/CloudTextField";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { CloudDataGrid } from "components/modules/CloudDataGrid";
import { AssignRobotCell } from "components/modules/tableCells/AssignRobotCell";
import { BatteryCell } from "components/modules/tableCells/BatteryCell";
import { SerialNumberCell } from "components/modules/tableCells/SerialNumberCell";
import { UVDRobotStatusCell } from "components/modules/tableCells/UVDRobotStatusCell";
import { ForbiddenPage } from "components/pages/ForbiddenPage";
import { graphql } from "gql";
import { IRobot, UvdRobot } from "gql/graphql";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getIsForbidden } from "utils/privileges/privilegeUtils";
import { usePrivilege } from "utils/privileges/usePrivileges";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { AddRobot } from "./dialogs/AddRobot";

export const UNASSIGNED_ROBOTS_QUERY = graphql(`
  query UnassignedRobots($search: String) {
    robots(search: $search, isActive: false) {
      robots {
        id
        serialNumber
        robotType
        active {
          organization {
            id
            name
            country
          }
        }
        ... on UvdRobot {
          status {
            batteryMergerPercentage
            lastTimeActive
            job {
              state
              type
            }
          }
          version {
            seto
          }
        }
      }
    }
  }
`);

export const ON_ROBOT_CHANGED = graphql(`
  subscription OnRobotChanged {
    robotChanged {
      id
      serialNumber
      robotType
      ... on UvdRobot {
        active {
          organization {
            id
            name
            country
          }
        }
        status {
          batteryMergerPercentage
          lastTimeActive
          job {
            state
            type
          }
        }
        version {
          seto
        }
      }
    }
  }
`);

export const UnassignedRobots = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const canAddRobots = usePrivilege(RobotPrivileges.Create).hasPrivilege;

  const [addRobotDialogOpen, setAddRobotDialogOpen] = useState(false);
  const [search, setSearch] = useState("");

  const { data, loading, error } = useQueryWithSnack(UNASSIGNED_ROBOTS_QUERY, {
    variables: { search },
    fetchPolicy: "no-cache",
  });
  const robots = data?.robots.robots as IRobot[] | undefined;

  const sub = useSubscription(ON_ROBOT_CHANGED);
  useEffect(() => {
    if (robots && sub.data && sub.data.robotChanged) {
      const subRobot = sub.data.robotChanged as UvdRobot;
      const foundRobot = robots.find((o) => o.id == subRobot.id);
      if (foundRobot) {
        _.merge(foundRobot, subRobot);
      }
    }
  }, [robots, sub.data]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "serialNumber",
        maxWidth: 250,
        flex: 1,
        headerName: "Serial number",
        renderCell: SerialNumberCell,
      },
      {
        field: "battery",
        maxWidth: 250,
        flex: 1,
        headerName: "Battery",
        valueGetter: (params) => params.row?.status?.batteryMergerPercentage,
        renderCell: (params) => {
          if (!params.row?.status) return null;

          return (
            <BatteryCell
              robot={params.row}
              hideBattery={false}
              batteryMergerPercentage={
                params.row?.status?.batteryMergerPercentage
              }
            />
          );
        },
      },
      {
        field: "status",
        maxWidth: 250,
        flex: 1,
        headerName: "Status",
        sortable: false,
        renderCell: (params) => <UVDRobotStatusCell robot={params.row} />,
      },
      {
        field: "sw-version",
        maxWidth: 100,
        flex: 1,
        headerName: "SW version",
        align: "right",
        headerAlign: "right",
        valueGetter(params) {
          return params.row.version?.seto || "";
        },
      },
      {
        field: "assignAction",
        align: "right",
        maxWidth: 150,
        flex: 1,
        headerName: "",
        sortable: false,
        renderCell: AssignRobotCell,
      },
    ];
  }, []);

  const forbidden = getIsForbidden(error);
  if (forbidden) return <ForbiddenPage />;

  return (
    <>
      <WidgetPaper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <WidgetHeader
            label={
              <FormattedMessage
                id="unassigned-robots"
                defaultMessage="Unassigned robots"
              />
            }
            buttonLabel={canAddRobots ? "Add Robot" : undefined}
            onButtonClick={() => setAddRobotDialogOpen(true)}
          />
          <CloudTextField
            sx={{ mt: 2, width: "50%" }}
            placeholder="Type serial number"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <>
                  <IoSearch size={20} color={theme.palette.grey[500]} />
                </>
              ),
            }}
          />
        </Box>
        <CloudDataGrid
          rows={loading ? [] : robots || []}
          columns={columns}
          loading={loading}
          onRowClick={(params: GridRowParams) => navigate(params.row.id)}
          pageSize={25}
          initialState={{
            sorting: {
              sortModel: [{ field: "serialNumber", sort: "asc" }],
            },
          }}
        />
      </WidgetPaper>
      <AddRobot
        open={addRobotDialogOpen}
        onClose={() => setAddRobotDialogOpen(false)}
      />
    </>
  );
};
