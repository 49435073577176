import { useLazyQuery } from "@apollo/client";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { CloudTextField } from "components/atoms/CloudTextField";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { CloudDataGrid } from "components/modules/CloudDataGrid";
import { OrganizationCell } from "components/modules/tableCells/OrganizationCell";
import { RobotCell } from "components/modules/tableCells/RobotCell";
import { RobotDisinfectionCountCell } from "components/modules/tableCells/RobotDisinfectionCountCell";
import { graphql } from "gql";
import { IRobot } from "gql/graphql";
import { useEffect, useMemo, useState } from "react";
import { IoFunnelOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useDebounce } from "utils/useDebounce";
import { useUserDisplayName } from "utils/useUserDisplayName";

const QUERY = graphql(`
  query UserRobotAccess($userId: String!, $search: String) {
    identity(id: $userId) {
      firstName
      lastName
    }
    robots(identityId: $userId, search: $search) {
      count
      robots {
        id
        serialNumber
        active {
          organization {
            id
            name
            country
          }
        }
      }
    }
  }
`);

const columns: GridColDef[] = [
  {
    field: "robot",
    flex: 1,
    minWidth: 150,
    maxWidth: 300,
    headerName: "Robot",
    renderCell: (params: GridRenderCellParams) => (
      <RobotCell robot={params.row} showStatusDot />
    ),
    valueGetter(params) {
      return params.row.serialNumber;
    },
  },
  {
    field: "organization",
    flex: 1,
    minWidth: 180,
    maxWidth: 300,
    headerName: "Organization",
    valueGetter: (params) => params.row.active?.organization?.name,
    renderCell: (params: GridRenderCellParams) => (
      <OrganizationCell organization={params.row.active?.organization} />
    ),
  },
  {
    field: "disinfections",
    flex: 1,
    maxWidth: 300,
    renderCell: (params) => {
      return (
        <RobotDisinfectionCountCell
          robot={params.row}
          hideDescription={!!params.row.active}
        />
      );
    },
  },
];

export const UserRobotAccess = () => {
  const { userId } = useParams();

  const [searchText, setSearchText] = useState("");
  const debouncedValue = useDebounce(searchText, 500);

  const [fetchLazy, { loading, data }] = useLazyQuery(QUERY);

  const robots = data?.robots.robots as IRobot[];
  const displayName = useUserDisplayName(data?.identity);
  const robotCount = data?.robots.count;

  const description = useMemo(() => {
    if (robots?.length === robotCount) {
      return `${displayName ?? "This user"} can see ${data?.robots
        .count} robots`;
    } else {
      const diffCount = (robotCount ?? 0) - robots?.length;
      return `${displayName ?? "This user"} can see ${data?.robots
        .count} robots. ${diffCount} of them are hidden for you.`;
    }
  }, [robots, robotCount]);

  const handleChange = (e: any) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    fetchLazy({
      variables: { userId: userId ?? "", search: debouncedValue },
    });
  }, [debouncedValue]);

  return (
    <WidgetPaper>
      <WidgetHeader label="Robots" description={description} />
      <CloudTextField
        placeholder="Serial number"
        onChange={handleChange}
        autoComplete="new-password"
        InputProps={{
          startAdornment: <IoFunnelOutline size={20} />,
          autoComplete: "new-password",
        }}
        sx={(theme) => ({
          mt: 4,
          "svg > *": {
            color: theme.palette.grey[500],
          },
        })}
      />
      <CloudDataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "robot", sort: "asc" }],
          },
        }}
        rows={robots || []}
        columns={columns}
        pageSize={5}
        loading={loading}
      />
    </WidgetPaper>
  );
};
