import { canUse, Privileges } from "@blue-ocean-robotics/cloud-library";
import { useMemo } from "react";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { graphql } from "../../gql";

const GET_PRIVILEGES = graphql(`
  query PrivilegesWrapper {
    me {
      supportRole {
        title
        privileges
      }
      affiliations {
        id
        organization {
          id
        }
        roles {
          title
          privileges
        }
      }
    }
  }
`);

export function usePrivilege(privilege: Privileges, organizationId?: string) {
  const { data, error, loading } = useQueryWithSnack(GET_PRIVILEGES);

  const hasPrivilege = useMemo(() => {
    if (!data) return false;
    return canUse(data.me, privilege, organizationId);
  }, [data, privilege, organizationId]);

  return { loading, error, hasPrivilege };
}
