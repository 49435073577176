import { Grid } from "@mui/material";
import { DisinfectionFilterButtons } from "components/modules/disinfectionFilter/DisinfectionFilterButtons";
import {
  DisinfectionFilterPanel,
  useDDFilter,
  useDDTimeSpan,
} from "components/modules/disinfectionFilter/DisinfectionFilterPanel";
import { add, startOfMonth, sub } from "date-fns";
import { DisinfectionFilter } from "gql/graphql";
import { useMemo, useState } from "react";
import { getSelectedFilterList } from "utils/UVD/getSelectedFilterList";

interface Props {
  hiddenFilter?: DisinfectionFilter;
}

export const DisinfectionDashboardFilter = ({ hiddenFilter }: Props) => {
  const [DDFilters] = useDDFilter();
  const [startDate, setStartDate] = useDDTimeSpan();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const previousMonthFirstDay = useMemo(() => {
    return sub(new Date(startDate), { months: 1 });
  }, [startDate]);

  const nextMonthFirstDay = useMemo(() => {
    return add(new Date(startDate), { months: 1 });
  }, [startDate]);

  return (
    <>
      <Grid item>
        <DisinfectionFilterButtons
          start={new Date(startDate)}
          nextMonthFirstDay={nextMonthFirstDay}
          filterCount={getSelectedFilterList(DDFilters).length || 0}
          onOpenDrawer={() => setDrawerOpen(true)}
          onBackClick={() => setStartDate(new Date(previousMonthFirstDay))}
          onForwardClick={() => setStartDate(new Date(nextMonthFirstDay))}
          onTodayClick={() => setStartDate(startOfMonth(new Date()))}
        />
      </Grid>
      <DisinfectionFilterPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        hiddenFilter={hiddenFilter}
      />
    </>
  );
};
