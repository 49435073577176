import { RoleType } from "gql/graphql";
import { defineMessages } from "react-intl";
import { DefineMsg } from "types/types";

export const roleDescriptions: DefineMsg = defineMessages({
  [RoleType.Viewer]: {
    id: "enum.Viewer",
    defaultMessage: "View Disinfection Dashboard, Download Disinfection Report",
  },
  [RoleType.Editor]: {
    id: "enum.Editor",
    defaultMessage: "Dashboard Viewer + Manage robots, Manage users",
  },
  [RoleType.Owner]: {
    id: "enum.Owner",
    defaultMessage: "Editor + Edit organization, Assign Editor role",
  },
  [RoleType.FirstLevel]: {
    id: "enum.FirstLevel",
    defaultMessage:
      "Access and edit organization, users, robots and access to all disinfection data of:",
  },
  [RoleType.SecondLevel]: {
    id: "enum.SecondLevel",
    defaultMessage:
      "Access and edit organization, users, robots and access to all disinfection data of all organizations:",
  },
});

export const getSupportRoleShortDescription = (roleTitle: RoleType) => {
  switch (roleTitle) {
    case RoleType.SecondLevel:
      return "Access and edit all organizations, users and robot. Access to all disinfection data.";
    default:
      return `Access and edit specific organizations. Access to all their disinfection data.`;
  }
};
