import { Grid } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { BatteryCell } from "components/modules/tableCells/BatteryCell";
import { RobotCell } from "components/modules/tableCells/RobotCell";
import { TechnicalServiceNeededCell } from "components/modules/tableCells/TechnicalServiceNeededCell";
import { UVDRobotStatusCell } from "components/modules/tableCells/UVDRobotStatusCell";

import { AssignedRobots } from "components/organisms/AssignedRobots";
import { graphql } from "gql";
import { useParams } from "react-router-dom";
import { getIsForbidden } from "utils/privileges/privilegeUtils";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { ForbiddenPage } from "./ForbiddenPage";

const QUERY = graphql(`
  query OrganizationRobotListPage($orgId: String!) {
    organization(id: $orgId) {
      name
    }
  }
`);

// repeating columns here, because I cannot make the columnVisibility prop work in the DataGrid
// TODO: see if I can make the columnVisibility work, so that we dont have to repeat the columns here (only organization should be hidden)
const columns: Array<GridColDef> = [
  {
    field: "robot",
    width: 150,
    headerName: "Robot",
    renderCell: (params: GridRenderCellParams) => (
      <RobotCell robot={params.row} showStatusDot disableHoverOnRobotName />
    ),
  },
  {
    field: "battery",
    width: 150,
    headerName: "Battery",
    renderCell: (params) => (
      <BatteryCell
        robot={params.row}
        hideBattery={false}
        batteryMergerPercentage={params.row?.status?.batteryMergerPercentage}
      />
    ),
  },
  {
    field: "status",
    width: 150,
    headerName: "Status",
    renderCell: UVDRobotStatusCell,
  },
  {
    field: "technical-service",
    headerName: "Technical Service",
    renderCell: TechnicalServiceNeededCell,
    width: 200,
  },
  {
    field: "sw-version",
    width: 150,
    headerName: "SW Version",
    valueGetter(params) {
      return params.row.version?.seto || "-";
    },
  },
  {
    field: "lastTimeActive",
    width: 150,
    headerName: "Last seen", // TODO: field is breaking from the backend
  },
];

export const OrganizationRobotListPage = () => {
  const { orgId } = useParams();

  const { data, error } = useQueryWithSnack(QUERY, {
    variables: { orgId: orgId ?? "" },
    skip: orgId === "support",
  });

  const forbidden = getIsForbidden(error);
  if (forbidden) return <ForbiddenPage />;

  return (
    <Grid container spacing={6}>
      <HeaderGrid
        crumbs={[
          {
            label: orgId ? data?.organization?.name ?? "-" : "-",
          },
          { label: "Robots" },
        ]}
      />
      <Grid item xs={12}>
        <AssignedRobots columns={columns} label="Robots" showGrid />
      </Grid>
    </Grid>
  );
};
