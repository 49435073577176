import { Box } from "@mui/material";

interface Props {
  height: number;
  children: any;
  style: any;
}

export const CustomScrollBox = ({ height, children, style }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        overflow: "scroll",
        height: height + "px",

        "::-webkit-scrollbar": {
          width: "4px",
        },

        "::-webkit-scrollbar-track": {
          background: "#fff",
        },
        "::-webkit-scrollbar-thumb": {
          background: theme.palette.grey[200],
          borderRadius: 100,
        },

        "::-webkit-scrollbar-thumb:hover": {
          background: theme.palette.grey[300],
        },
        ...style,
      })}
    >
      {children}
    </Box>
  );
};
