import { ApolloClient, ApolloProvider, HttpLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { createClient } from "graphql-ws";
import _ from "lodash";
import { CloudSnackbarProvider } from "providers/CloudSnackbarProvider";
import Favicon from "react-favicon";
import { IconContext } from "react-icons";
import { BrowserRouter } from "react-router-dom";
import { cache } from "./cache";
import { ConfigProvider } from "./config/configHook";
import { Config } from "./config/configTypes";
import { defaultConfig } from "./config/defaultConfig";
import { ContentRoot } from "./ContentRoot";
import { useFirebase } from "./firebaseWrapper";
import { defaultTheme } from "./theme/defaultTheme";

function App() {
  const specificConfig: Config | undefined = (() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return require(
        `./config/${process.env.REACT_APP_ROBOT_TYPE?.toLowerCase()}Config`,
      ).config;
    } catch (err) {
      return undefined;
    }
  })();

  const extraConfig = {
    robotType: process.env.REACT_APP_ROBOT_TYPE,
  };

  const config = _.merge(defaultConfig, specificConfig, extraConfig);

  const customTheme = _.merge(defaultTheme, config?.theme);
  const theme = createTheme(customTheme);

  const firebase = useFirebase(config);

  const authLink = setContext(async (_, { headers }) => {
    const user = firebase.auth.currentUser;
    const token = await user?.getIdToken();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const httpLink = new HttpLink({
    uri: config.graphqlUri,
  });

  const authHeader =
    (process.env.REACT_APP_AUTH_HEADER as string) ?? "authorization";
  const wsLink = new GraphQLWsLink(
    createClient({
      url: config.wsUri ?? "",
      connectionParams: async () => {
        const user = firebase.auth.currentUser;
        const token = await user?.getIdToken();
        const cp = {} as Record<string, string>;
        cp[authHeader] = token ? `Bearer ${token}` : "";
        return cp;
      },
    }),
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

  const client = new ApolloClient({
    link: splitLink,
    cache,
  });

  return (
    <ApolloProvider client={client}>
      <ConfigProvider value={config}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <IconContext.Provider
                value={{
                  style: {
                    verticalAlign: "middle",
                  },
                }}
              >
                <CloudSnackbarProvider maxSnack={3} autoHideDuration={5000}>
                  <Favicon url={config.favicon} />
                  <ContentRoot />
                </CloudSnackbarProvider>
              </IconContext.Provider>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </ConfigProvider>
    </ApolloProvider>
  );
}

export default App;
