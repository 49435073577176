import { gql } from "@apollo/client";
import { GenericPrivileges } from "@blue-ocean-robotics/cloud-library";
import {
  Box,
  CircularProgress,
  Input,
  Popover,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { CustomScrollBox } from "components/atoms/CustomScrollBox";
import { ListSectionHeader } from "components/atoms/ListSectionHeader";
import { useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { IoSearchOutline } from "react-icons/io5";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { usePrivilege } from "utils/privileges/usePrivileges";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { Identity, Organization, UvdRobot } from "../../gql/graphql";
import { OrganizationCardContent } from "./cards/OrganizationCardContent";
import { RobotCardContent } from "./cards/RobotCardContent";
import { UserCardContent } from "./cards/UserCardContent";
import { FilterSelector } from "./FilterSelector";

const ResultWrapper = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  ":hover": {
    color: theme.palette.primary.main,
  },
}));

const GLOBALSEARCH = gql`
  query GlobalSearch($searchText: String!) {
    globalSearch(searchText: $searchText) {
      ... on UvdRobot {
        id
        serialNumber
        robotType
        active {
          robotOrg: organization {
            id
            name
          }
        }
      }
      ... on Organization {
        id
        name
        robotAffiliations {
          robot {
            serialNumber
          }
        }
        affiliations {
          identity {
            id
          }
        }
      }
      ... on Identity {
        id
        ...UserCardContent
      }
      ... on Label {
        id
        name
        labelOrg: organization {
          id
          name
        }
      }
    }
  }
  ${UserCardContent.fragments.identity}
`;

export const GlobalSearch = () => {
  const intl = useIntl();
  const popoverRef = useRef();
  const navigate = useNavigate();
  const searchFieldRef = useRef<any>(null);

  const responsiveHeight = window.innerHeight / 2;

  const [searchText, setSearchText] = useState("");
  const [resultPopoverOpen, setResultPopoverOpen] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState<any>(null);

  const hasGlobalSearch = usePrivilege(
    GenericPrivileges.GlobalSearch,
  ).hasPrivilege;

  const [selectedFilter, setSelectedFilter] = useState("all");

  useHotkeys(
    "ctrl+space",
    () => {
      searchFieldRef.current.focus();
    },
    [searchFieldRef],
  );

  const { data: searchData, loading } = useQueryWithSnack(GLOBALSEARCH, {
    variables: {
      searchText: searchText.trim(),
    },
    skip: !searchFieldRef.current,
  });

  const handleClose = () => {
    setResultPopoverOpen(false);
    setSearchText("");
    setSelectedFilter("all");
  };

  const assignedRobotResults: Partial<UvdRobot>[] | any[] = useMemo(() => {
    return (
      searchData?.globalSearch
        .filter((item: any) => item.__typename === "UvdRobot" && !!item.active)
        .slice(0, 5) || []
    );
  }, [searchData]);

  const unassignedRobotResults: Partial<UvdRobot>[] | any[] = useMemo(() => {
    return (
      searchData?.globalSearch
        .filter((item: any) => item.__typename === "UvdRobot" && !item.active)
        .slice(0, 5) || []
    );
  }, [searchData]);

  const userResults: Partial<Identity>[] | any[] = useMemo(() => {
    return (
      searchData?.globalSearch
        ?.filter((item: any) => item.__typename === "Identity")
        .slice(0, 5) || []
    );
  }, [searchData]);

  const organizationResults: Partial<Organization>[] | any[] = useMemo(() => {
    return (
      searchData?.globalSearch
        ?.filter((item: any) => item.__typename === "Organization")
        .slice(0, 5) || []
    );
  }, [searchData]);

  const showUserResults =
    (selectedFilter === "all" || selectedFilter === "users") &&
    userResults.length > 0;

  const showAssignedRobotResults =
    (selectedFilter === "all" || selectedFilter === "robots") &&
    assignedRobotResults.length > 0;

  const showUnassignedRobotResults =
    (selectedFilter === "all" || selectedFilter === "robots") &&
    unassignedRobotResults.length > 0;

  const showOrganizationResults =
    (selectedFilter === "all" || selectedFilter === "organizations") &&
    organizationResults.length > 0;

  if (!hasGlobalSearch) return null;

  return (
    <>
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "12px 8px",
          width: "50%",
          minWidth: 250,
          path: {
            color: theme.palette.grey[500],
          },
        })}
        ref={popoverRef}
      >
        <IoSearchOutline
          style={{ height: 20, width: 20, minWidth: 20, marginRight: "8px" }}
        />
        <Input
          sx={{
            "& input": {
              minWidth: 50,
              width: searchText.trim().length === 0 ? "20%" : "100%",
            },
            background: "white",
          }}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onFocus={() => {
            setPopoverAnchor(popoverRef.current);
            setResultPopoverOpen(true);
          }}
          inputRef={searchFieldRef}
          disableUnderline
          placeholder={intl.formatMessage({
            id: "global-search",
            defaultMessage: "Search:",
          })}
          endAdornment={
            searchText.trim().length === 0 ? (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: theme.palette.grey[600],
                })}
              >
                <Box
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.grey[350]}`,
                    borderRadius: 1,
                    padding: "2px 8px",
                  })}
                >
                  Control
                </Box>
                <Typography margin={1}>+</Typography>
                <Box
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.grey[350]}`,
                    borderRadius: 1,
                    padding: "2px 8px",
                  })}
                >
                  Space
                </Box>
              </Box>
            ) : null
          }
        />
      </Box>
      <Popover
        open={resultPopoverOpen}
        anchorEl={popoverAnchor}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableRestoreFocus
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: {
              boxShadow: "0px 0px 22px 0px rgba(24, 28, 50, 0.15)",
              padding: "24px",
            },
          },
        }}
      >
        {loading ? (
          <Box
            sx={{
              width: 250,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <FilterSelector
              selected={selectedFilter}
              onChange={setSelectedFilter}
            />
            <CustomScrollBox
              height={responsiveHeight}
              style={{ marginTop: "8px", marginBottom: 0, paddingBottom: 0 }}
            >
              {showUserResults ? (
                <>
                  <ListSectionHeader>Users</ListSectionHeader>
                  {userResults.map((user) => (
                    <ResultWrapper
                      key={user.id}
                      onClick={() => {
                        handleClose();
                        navigate("/cloud/support/users/" + user.id);
                      }}
                    >
                      <UserCardContent identity={user} hideIcon />
                    </ResultWrapper>
                  ))}
                </>
              ) : null}
              {showAssignedRobotResults ? (
                <>
                  <ListSectionHeader>Robots</ListSectionHeader>
                  {assignedRobotResults.map((robot) => {
                    return (
                      <ResultWrapper
                        key={robot.id}
                        onClick={() => {
                          handleClose();
                          navigate("/cloud/support/robots/" + robot.id);
                        }}
                      >
                        <RobotCardContent
                          robot={robot}
                          hideIcon
                          hideAvatar={false}
                          wrapperStyle={{ padding: "8px 0px" }}
                          description={robot?.active?.robotOrg?.name}
                        />
                      </ResultWrapper>
                    );
                  })}
                </>
              ) : null}
              {showUnassignedRobotResults ? (
                <>
                  <ListSectionHeader>Unassigned Robots</ListSectionHeader>
                  {unassignedRobotResults.map((robot) => {
                    return (
                      <ResultWrapper
                        key={robot.id}
                        onClick={() => {
                          handleClose();
                          navigate(
                            "/cloud/support/unassigned-robots/" + robot.id,
                          );
                        }}
                      >
                        <RobotCardContent
                          robot={robot}
                          hideIcon
                          hideAvatar={false}
                          wrapperStyle={{ padding: "8px 0px" }}
                          description={robot?.active?.robotOrg?.name}
                        />
                      </ResultWrapper>
                    );
                  })}
                </>
              ) : null}
              {showOrganizationResults ? (
                <>
                  <ListSectionHeader>Organizations</ListSectionHeader>
                  {organizationResults.map((org) => (
                    <ResultWrapper
                      key={org.id}
                      onClick={() => {
                        handleClose();
                        navigate("/cloud/support/organizations/" + org.id);
                      }}
                    >
                      <OrganizationCardContent
                        organization={org}
                        hideIcon
                        description={[
                          `${org.robotAffiliations.length} robots`,
                          `${(org.affiliations || []).length} users`,
                        ].join(" / ")}
                      />
                    </ResultWrapper>
                  ))}
                </>
              ) : null}
            </CustomScrollBox>
          </>
        )}
      </Popover>
    </>
  );
};
