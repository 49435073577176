import { Box, Grid, Typography, useTheme } from "@mui/material";
import { ServiceNeededOfflineIcon } from "assets/icons/ServiceNeeded-Offline-Icon";
import { ServiceNeededIcon } from "assets/icons/ServiceNeededIcon";
import { RobotAvatar } from "components/atoms/avatars/RobotAvatar";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { UvdRobot } from "gql/graphql";
import { useParams } from "react-router-dom";
import {
  useUvdConnectionStatus,
  UVDConnectionStatus,
} from "utils/UVD/useUvdConnectionStatus";
import { getShowTechnicalServiceNeeded } from "utils/UVD/UVDUtils";
import { BatteryCell } from "./tableCells/BatteryCell";
import { UVDRobotStatusCell } from "./tableCells/UVDRobotStatusCell";

interface Props {
  robot: UvdRobot;
  onClick(): void;
}

export const RobotListCard = ({ robot, onClick }: Props) => {
  const theme = useTheme();
  const { orgId } = useParams();

  const connectionStatus = useUvdConnectionStatus(robot);

  const notSyncing = !robot.status;

  const isOffline = connectionStatus === UVDConnectionStatus.OFFLINE;
  const { showWarning } = getShowTechnicalServiceNeeded(robot);
  const showRobotAsDataSource = robot.active?.organization.id !== orgId;

  const showWarningIcon =
    (showWarning ||
      notSyncing ||
      connectionStatus == UVDConnectionStatus.SERVICE_NEEDED) &&
    !showRobotAsDataSource;

  return (
    <Grid item display="flex">
      <WidgetPaper
        onClick={onClick}
        sx={{
          position: "relative",
          width: 185,
          height: 301,
          boxShadow:
            "0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.04)",
          ":hover": {
            boxShadow: "0px 0px 22px 0px rgba(24, 28, 50, 0.15)",
            transition: "none",
            cursor: "pointer",
          },
        }}
      >
        {(showWarningIcon && !isOffline) || showRobotAsDataSource ? (
          <Box
            sx={(theme) => ({
              position: "absolute",
              height: 12,
              background: showRobotAsDataSource
                ? theme.palette.grey[350]
                : theme.palette.warning.main,
              top: 0,
              left: 0,
              right: 0,
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            })}
          />
        ) : null}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h4"
            mb={1}
            color={(theme) =>
              connectionStatus !== UVDConnectionStatus.ONLINE &&
              connectionStatus !== UVDConnectionStatus.SERVICE_NEEDED
                ? theme.palette.grey[400]
                : "auto"
            }
          >
            {robot.serialNumber}
          </Typography>
          {showWarningIcon ? (
            connectionStatus === UVDConnectionStatus.OFFLINE ? (
              <ServiceNeededOfflineIcon />
            ) : (
              <ServiceNeededIcon />
            )
          ) : null}
        </Box>

        {showRobotAsDataSource ? (
          <Typography variant="body2">Data source</Typography>
        ) : notSyncing ? (
          <Typography variant="body2">Not syncing</Typography>
        ) : (
          <UVDRobotStatusCell
            robot={robot}
            variant="body2"
            style={{
              color: theme.palette.grey[isOffline ? 800 : 600],
            }}
          />
        )}
        <RobotAvatar
          sx={{ marginTop: 2, marginBottom: 2 }}
          robotType={robot.robotType}
          size="huge"
          isOffline={
            connectionStatus !== UVDConnectionStatus.ONLINE &&
            connectionStatus !== UVDConnectionStatus.SERVICE_NEEDED
          }
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <BatteryCell
            hideBattery={notSyncing}
            robot={robot}
            batteryMergerPercentage={robot.status?.batteryMergerPercentage}
          />

          {robot?.version?.seto ? (
            <Typography
              variant="bodyS"
              color={(theme) => theme.palette.grey[isOffline ? 350 : 400]}
            >
              version
              <Typography
                component="span"
                variant="bodyS"
                display="inline"
                color={(theme) => theme.palette.grey[isOffline ? 400 : 900]}
                sx={{ paddingLeft: 1 }}
              >
                {robot?.version?.seto}
              </Typography>
            </Typography>
          ) : notSyncing ? (
            <Typography
              variant="bodyS"
              color={(theme) => theme.palette.grey[400]}
            ></Typography>
          ) : null}
        </Box>
      </WidgetPaper>
    </Grid>
  );
};
