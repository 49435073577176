import { useSubscription } from "@apollo/client";
import {
  Box,
  Skeleton,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import MapMarkerOffline from "assets/images/map-marker-offline.png";
import MapMarkerOnline from "assets/images/map-marker-online.png";
import MapMarkerWarning from "assets/images/map-marker-warning.png";
import UVDCStandardImg from "assets/images/uvd-c.png";
import { InlineCardEmptyView } from "components/atoms/InlineCardEmptyView";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { ON_ROBOT_CHANGED } from "components/organisms/UnassignedRobots";
import { graphql } from "gql";
import { UvdRobot } from "gql/graphql";
import { Icon } from "leaflet";
import _ from "lodash";
import { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useParams } from "react-router-dom";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import {
  useUvdConnectionStatus,
  UVDConnectionStatus,
} from "utils/UVD/useUvdConnectionStatus";
import { getShowTechnicalServiceNeeded } from "utils/UVD/UVDUtils";
import "./map.css";

const MainText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

MainText.defaultProps = {
  variant: "body2",
};

type SecondaryTextProp = React.ComponentType<
  TypographyProps<"span", { component?: "span" }>
>;

const SecondaryText: SecondaryTextProp = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[900],
  paddingLeft: 4,
}));

SecondaryText.defaultProps = {
  display: "inline",
  component: "span",
  variant: "body2",
};

const onlineIcon = new Icon({
  iconUrl: MapMarkerOnline,
  iconSize: [14, 14],
  popupAnchor: [0, -10],
});

const offlineIcon = new Icon({
  iconUrl: MapMarkerOffline,
  iconSize: [14, 14],
  popupAnchor: [0, -10],
});

const warningIcon = new Icon({
  iconUrl: MapMarkerWarning,
  iconSize: [14, 14],
  popupAnchor: [0, -10],
});

const MAP_QUERY = graphql(`
  query RobotMap($robotId: String!) {
    robot(id: $robotId) {
      id
      serialNumber
      ... on UvdRobot {
        location {
          geocoordinates {
            allowed
            selected
            custom {
              value
            }
            deviceGps {
              value
            }
            ipData {
              value
            }
            ipWifi {
              value
            }
          }
        }
        active {
          organization {
            id
            name
          }
        }
        status {
          job {
            state
            type
          }
        }
        version {
          plc {
            hasNewAvailable
          }
          batteries {
            family
            version
          }
        }
        internetPreference
      }
    }
  }
`);

export const RobotMap = () => {
  const { robotId } = useParams();
  const [robot, setRobot] = useState<UvdRobot | undefined>(undefined);

  const { data, loading } = useQueryWithSnack(MAP_QUERY, {
    variables: { robotId: robotId ?? "" },
  });

  const { data: subscriptionData } = useSubscription(ON_ROBOT_CHANGED);

  useEffect(() => {
    setRobot(_.merge(robot, data?.robot) as UvdRobot | undefined);
  }, [data]);

  useEffect(() => {
    if (robot && subscriptionData && subscriptionData.robotChanged) {
      const subRobot = subscriptionData.robotChanged as UvdRobot;
      if (robot.id == subRobot.id) {
        setRobot(_.merge(robot, subRobot) as UvdRobot | undefined);
      }
    }
  }, [subscriptionData]);

  const connectionStatus = useUvdConnectionStatus(robot);
  const isOffline = connectionStatus == UVDConnectionStatus.OFFLINE;
  const { showWarning } = getShowTechnicalServiceNeeded(robot);

  const getCoords = () => {
    const coords = robot?.location?.geocoordinates;

    switch (coords?.selected) {
      case "custom":
        return coords.custom.value;
      case "deviceGps":
        return coords.deviceGps.value;
      case "ipData":
        return coords.ipData.value;
      case "ipWifi":
        return coords.ipWifi.value;
      default:
        return null;
    }
  };

  const getWifiText = () => {
    switch (robot?.internetPreference) {
      case "wifi":
        return "robot Wi-Fi";
      default:
        return "robot data";
    }
  };

  const markerIcon = () => {
    if (isOffline) return offlineIcon;

    if (showWarning || connectionStatus == UVDConnectionStatus.UNKNOWN)
      return warningIcon;

    return onlineIcon;
  };

  const coords = getCoords() as any;

  if (loading) return <Skeleton height={300} />;

  return (
    <WidgetPaper
      sx={{
        overflow: "hidden",
        padding: 6,
      }}
    >
      {getCoords() === null || !robot?.location?.geocoordinates.allowed ? (
        <InlineCardEmptyView label="No location available for this robot" />
      ) : (
        <MapContainer
          center={coords}
          zoom={5}
          scrollWheelZoom={false}
          style={{
            height: 380,
            borderRadius: 0,
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
          />
          <Marker position={coords} icon={markerIcon()}>
            <Popup>
              <Box
                sx={{
                  paddingTop: 4,
                  paddingRight: 4,
                }}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <img
                    src={UVDCStandardImg}
                    style={{
                      height: 70,
                      marginRight: 8,
                    }}
                  />
                  <Box>
                    <MainText sx={{ margin: 0 }}>
                      Robot<SecondaryText>{robot?.serialNumber}</SecondaryText>
                    </MainText>
                    <MainText sx={{ margin: 0 }}>
                      Organization
                      <SecondaryText>
                        {robot?.active?.organization.name ||
                          "No organization assigned"}
                      </SecondaryText>
                    </MainText>
                    {isOffline ||
                    connectionStatus == UVDConnectionStatus.UNKNOWN ? null : (
                      <MainText sx={{ margin: 0 }}>
                        Via<SecondaryText>{getWifiText()}</SecondaryText>
                      </MainText>
                    )}
                  </Box>
                </Box>
              </Box>
            </Popup>
          </Marker>
        </MapContainer>
      )}
    </WidgetPaper>
  );
};
