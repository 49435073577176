import { Button, CircularProgress, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { ButtonLight } from "components/atoms/ButtonLight";
import { CustomContentProps, SnackbarContent } from "notistack";
import { forwardRef } from "react";

interface CloudSnackbar extends CustomContentProps {
  onActionClick?: any;
  actionLabel?: any;
}

export const CloudSnackbar = forwardRef<HTMLDivElement, CloudSnackbar>(
  ({ ...props }, ref) => {
    const theme = useTheme();

    const renderAction = () => {
      switch (props.variant) {
        case "loading":
          return <CircularProgress size={24} />;
        case "success":
          return props.onActionClick ? (
            <ButtonLight color="success" onClick={props.onActionClick}>
              {props.actionLabel || "Click here"}
            </ButtonLight>
          ) : null;
        default:
          return props.onActionClick ? (
            <Button color="primary" onClick={props.onActionClick}>
              {props.actionLabel || "Click here"}
            </Button>
          ) : null;
      }
    };

    const getColor = () => {
      switch (props.variant) {
        case "loading":
          return { background: "white" };
        case "success":
          return { background: theme.palette.success.main, text: "white" };
        case "error":
          return { background: theme.palette.error.main, text: "white" };
        default:
          return { background: "white" };
      }
    };

    return (
      <SnackbarContent ref={ref}>
        <Card
          sx={(theme) => ({
            padding: "16px 24px",
            background: getColor().background,
            color: getColor().text,
            borderRadius: "5px",
            minHeight: 30,
            minWidth: 256,
            maxWidth: 640,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            boxShadow: "0px 0px 22px 0px rgba(24, 28, 50, 0.15)",
            "& svg > *": {
              color: theme.palette.grey[900],
            },
          })}
        >
          <Typography variant="body1" mr={4}>
            {props.message}
          </Typography>
          <CardActions>{renderAction()}</CardActions>
        </Card>
      </SnackbarContent>
    );
  },
);
