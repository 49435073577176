import { useMutation } from "@apollo/client";
import { DomainVerificationPrivileges } from "@blue-ocean-robotics/cloud-library";
import { Box, Button, Typography } from "@mui/material";
import { GoogleIcon } from "assets/icons/GoogleIcon";
import { MicrosoftIcon } from "assets/icons/MicrosoftIcon";
import { CloudLabel } from "components/atoms/CloudLabel";
import { CloudTextField } from "components/atoms/CloudTextField";
import { ListElement, OrderedList } from "components/atoms/ListElements";
import { Dot } from "components/atoms/RobotStatusDot";
import { CloudDrawer, CloudDrawerProps } from "components/modules/CloudDrawer";
import { graphql } from "gql";
import { DomainVerification } from "gql/graphql";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { usePrivilege } from "utils/privileges/usePrivileges";
import { USERAUTH_QUERY } from "../organizationWidgets/UserAuthentication";

const VERIFY_DOMAIN = graphql(`
  mutation VerifyDomain($verificationId: String!) {
    verifyDomainVerification(id: $verificationId) {
      id
      hostname
      verified
    }
  }
`);

const DELETE_DOMAIN = graphql(`
  mutation DeleteDomain($verificationId: String!) {
    deleteDomainVerification(id: $verificationId) {
      id
      hostname
      verified
    }
  }
`);

interface Props {
  open: CloudDrawerProps["open"];
  onClose: CloudDrawerProps["onClose"];
  verification: DomainVerification;
  statusColor: string;
  statusText: string | ReactElement;
  onVerificationError?: (e: any) => void;
}

export const ManageDomain = ({
  open,
  onClose,
  verification,
  statusColor,
  statusText,
  onVerificationError,
}: Props) => {
  const { orgId } = useParams();
  const canVerifyDomain = usePrivilege(
    DomainVerificationPrivileges.Verify,
    orgId,
  ).hasPrivilege;
  const canDeleteDomain = usePrivilege(
    DomainVerificationPrivileges.Delete,
    orgId,
  ).hasPrivilege;

  const [verifyDNS] = useMutation(VERIFY_DOMAIN, {
    refetchQueries: [
      {
        query: USERAUTH_QUERY,
        variables: {
          orgId: orgId ?? "",
        },
      },
    ],
  });

  const [deleteDNS] = useMutation(DELETE_DOMAIN, {
    refetchQueries: [
      {
        query: USERAUTH_QUERY,
        variables: {
          orgId: orgId ?? "",
        },
      },
    ],
  });

  const handleVerifyDomain = () => {
    const snackbarId = enqueueSnackbar("Verifying domain...", {
      variant: "loading",
      persist: true,
    });
    onClose();

    verifyDNS({
      variables: {
        verificationId: verification.id,
      },
      onCompleted: () => {
        enqueueSnackbar(`Domain ${verification.hostname} was verified`, {
          variant: "success",
        });
        closeSnackbar(snackbarId);
      },
      onError: (error) => {
        enqueueSnackbar(
          `Domain ${verification.hostname} could not be verified`,
          { variant: "error" },
        );
        closeSnackbar(snackbarId);
        if (onVerificationError) {
          onVerificationError(error);
        }
      },
    });
  };

  const handleDeleteDomain = () => {
    const snackbarId = enqueueSnackbar("Deleting domain...", {
      variant: "loading",
      persist: true,
    });
    onClose();

    deleteDNS({
      variables: {
        verificationId: verification.id,
      },
      onCompleted: () => {
        enqueueSnackbar(`Domain ${verification.hostname} was deleted`, {
          variant: "success",
        });
        closeSnackbar(snackbarId);
      },
      onError: () => {
        enqueueSnackbar(
          `Domain ${verification.hostname} could not be deleted`,
          {
            variant: "error",
          },
        );
        closeSnackbar(snackbarId);
      },
    });
  };

  return (
    <CloudDrawer
      open={open}
      onClose={onClose}
      action={{
        label: "Verify domain",
        onClick: handleVerifyDomain,
        disabled: verification.verified || !canVerifyDomain,
      }}
      secondaryAction={{
        label: "Delete domain",
        onClick: handleDeleteDomain,
        disabled: !canDeleteDomain,
      }}
      header="Manage Domain"
    >
      <CloudLabel>Supported Authentication methods</CloudLabel>
      <Box display="flex" flexDirection="row" alignItems="center">
        <GoogleIcon />
        <Typography ml={2}>Google</Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <MicrosoftIcon />
        <Typography ml={2}>Microsoft</Typography>
      </Box>

      <CloudTextField
        wrapperStyle={{ marginBottom: 2 }}
        label="Domain"
        value={verification.hostname || ""}
        disabled
      />
      <Box mb={4} display="flex" flexDirection="row" alignItems="center">
        <Dot color={statusColor} />
        <Typography>{statusText}</Typography>
      </Box>
      <Box
        mb={2}
        sx={(theme) => ({
          "& p": {
            color: theme.palette.grey[600],
          },
        })}
      >
        <Typography variant="body2">To verify the domain:</Typography>
        <OrderedList>
          <ListElement>
            Go to your domain name server (DNS) registration site
          </ListElement>
          <ListElement>
            Add a TXT record with the verification code below
          </ListElement>
          <ListElement>Press the verify button</ListElement>
        </OrderedList>
        <Typography variant="body2">
          NOTE: It can take some time for the record to be added. Please be
          patient and try again later.
        </Typography>
      </Box>
      <CloudLabel>DNS verification code</CloudLabel>
      <Typography fontFamily={"monospace"}>
        {"borify=" + verification.id}
      </Typography>
      <Button
        variant="outlined"
        size="small"
        onClick={() =>
          navigator.clipboard.writeText("borify=" + verification.id)
        }
        sx={{ marginTop: 2 }}
      >
        Copy code
      </Button>
    </CloudDrawer>
  );
};
