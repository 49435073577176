import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { HamburgerIcon } from "assets/icons/HamburgerIcon";
import { GlobalSearch } from "components/modules/GlobalSearch";
import { IdentityButton } from "components/modules/IdentityButton";
import { useConfig } from "config/configHook";
import { RoleType } from "gql/graphql";
import { useState } from "react";
import { CreateOrganization } from "./dialogs/CreateOrganization";
import { InviteUser } from "./dialogs/InviteUser";

const LogoImg = styled("img")(({ theme }) => ({
  height: "32px",
  width: "auto",
  display: "block",
  marginLeft: theme.spacing(6),
}));

interface Props {
  onToggleMenu: () => void;
  isSupporter: boolean;
  userRoles: any;
}

export const DashboardBanner = ({
  onToggleMenu,
  isSupporter,
  userRoles,
}: Props) => {
  const config = useConfig();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);
  const [createOrgDialogOpen, setCreateOrgDialogOpen] = useState(false);

  const showInviteUserButton =
    userRoles.includes(RoleType.Owner) || userRoles.includes(RoleType.Editor);

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        paddingLeft: 4,
        paddingRight: 6,
        position: "sticky",
        top: 0,
        backgroundColor: "white",
        zIndex: 1100,
        height: 86,
      })}
    >
      <Box
        sx={{
          width: 242,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            height: 40,
            width: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            path: {
              color: theme.palette.grey[600],
            },
            ":hover path": {
              color: theme.palette.primary.main,
            },
          })}
          onClick={onToggleMenu}
        >
          <HamburgerIcon />
        </Box>
        <LogoImg src={config.logo?.defaultLogo} />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flex: 1,
          alignItems: "center",
          ml: theme.spacing(4),
        })}
      >
        <GlobalSearch />
      </Box>

      {largeScreen ? (
        <Box
          sx={(theme) => ({
            display: "flex",
            marginLeft: theme.spacing(6),
            alignItems: "center",
          })}
        >
          {isSupporter ? (
            <Button size="small" onClick={() => setCreateOrgDialogOpen(true)}>
              New organization
            </Button>
          ) : showInviteUserButton ? (
            <Button size="small" onClick={() => setInviteUserDialogOpen(true)}>
              Invite user
            </Button>
          ) : null}
        </Box>
      ) : null}
      <Box
        sx={(theme) => ({
          display: "flex",
          marginLeft: theme.spacing(4),
          alignItems: "center",
        })}
      >
        <IdentityButton />
      </Box>
      <InviteUser
        open={inviteUserDialogOpen}
        onClose={() => setInviteUserDialogOpen(false)}
      />
      <CreateOrganization
        open={createOrgDialogOpen}
        onClose={() => setCreateOrgDialogOpen(false)}
      />
    </Box>
  );
};
