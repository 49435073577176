import { Typography } from "@mui/material";
import { ContentWrapper } from "components/atoms/ContentWrapper";
import { useEffect, useState } from "react";

export const NoMatch = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 50);
    return () => clearTimeout(timeout);
  }, []);

  if (!show) return <div></div>;
  return (
    <ContentWrapper
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: "10%",
      }}
    >
      <Typography variant="h1" sx={{ fontSize: 96, marginBottom: 12 }}>
        404
      </Typography>
      <Typography variant="h3" mb={2}>
        This page could not be found
      </Typography>
    </ContentWrapper>
  );
};
