import { Organization } from "gql/graphql";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { graphql } from "../../../gql";
import { CloudAutoComplete } from "./CloudAutocomplete";

const ORGANIZATIONS = graphql(`
  query OrganizationsAutoComplete {
    organizations {
      id
      name
      country
    }
  }
`);

export const OrganizationAutoComplete = (props: any) => {
  const { data } = useQueryWithSnack(ORGANIZATIONS);

  // const getUsersOwnerAff = useMemo(() => {
  //   return data?.me.affiliations?.filter(
  //     (aff: any) =>
  //       aff?.roles.filter((role: Role) => role.title === RoleType.Owner)
  //         .length > 0,
  //   );
  // }, [data?.me.affiliations]);

  //  getUsersOwnerAff?.map((item) => item.organization)

  return (
    <CloudAutoComplete
      options={props?.options || data?.organizations}
      getOptionLabel={(option: Organization) => option.name}
      {...props}
    />
  );
};

// TODO : make sure user cannot see all orgs if they are not support
