import { FirebaseApp, FirebaseError, initializeApp } from "firebase/app";
import {
  Auth,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  addDoc,
  collection,
  Firestore,
  getFirestore,
} from "firebase/firestore";
import { firebaseErrorVar } from "./cache";
import { FirebaseConfig } from "./config/configTypes";

const googleProvider = new GoogleAuthProvider();
googleProvider.addScope("profile");
googleProvider.addScope("email");
const microsoftProvider = new OAuthProvider("microsoft.com");
microsoftProvider.addScope("profile");
microsoftProvider.addScope("email");
let app: FirebaseApp;
let auth: Auth;
let db: Firestore;

function isPrivateIpv4(ip: string) {
  const parts = ip.split(".");
  return (
    parts[0] === "10" ||
    (parts[0] === "172" &&
      parseInt(parts[1], 10) >= 16 &&
      parseInt(parts[1], 10) <= 31) ||
    (parts[0] === "192" && parts[1] === "168")
  );
}

export const useFirebase = (config: FirebaseConfig) => {
  if (app === undefined) {
    app = initializeApp(config.firebase);
    auth = getAuth(app);
    db = getFirestore(app);

    if (
      (isPrivateIpv4(window.location.hostname) ||
        window.location.hostname === "localhost") &&
      process.env.REACT_APP_CLOUD_FIREBASE !== "true"
    ) {
      connectAuthEmulator(auth, `http://${window.location.hostname}:9099/`, {
        disableWarnings: true,
      });
    }
  }
  return {
    app,
    auth,
    db,
    signInWithGoogle,
    signInWithMicrosoft,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
  };
};

const signInWithGoogle = async () => {
  try {
    return await signInWithPopup(auth, googleProvider);
  } catch (err: unknown) {
    firebaseErrorVar((err as FirebaseError).message);
  }
};

const signInWithMicrosoft = async () => {
  try {
    return await signInWithPopup(auth, microsoftProvider);
  } catch (err: unknown) {
    firebaseErrorVar((err as FirebaseError).message);
  }
};

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (err: unknown) {
    firebaseErrorVar((err as FirebaseError).message);
  }
};

const registerWithEmailAndPassword = async (
  email: string,
  password: string,
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      authProvider: "local",
      email,
    });
  } catch (err: unknown) {
    firebaseErrorVar((err as FirebaseError).message);
  }
};

export const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err: unknown) {
    firebaseErrorVar((err as FirebaseError).message);
  }
};

const logout = () => {
  signOut(auth);
};
