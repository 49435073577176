import { GenericPrivileges } from "@blue-ocean-robotics/cloud-library";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { InlineCard } from "components/atoms/InlineCard";
import { ActivityWidget } from "components/organisms/activityWidgets/ActivityWidget";
import { CyclesCounterChart } from "components/organisms/charts/CyclesCounterChart";
import { RobotHardware } from "components/organisms/robotWidgets/hardware/RobotHardware";
import { RobotBasicInfo } from "components/organisms/robotWidgets/RobotBasicInfo";
import { RobotBatteries } from "components/organisms/robotWidgets/RobotBatteries";
import { RobotLatestDisinfection } from "components/organisms/robotWidgets/RobotLatestDisinfection";
import { RobotMap } from "components/organisms/robotWidgets/RobotMap";
import { RobotOrganization } from "components/organisms/robotWidgets/RobotOrganization";
import { RobotSoftware } from "components/organisms/robotWidgets/RobotSoftware";
import { RobotTransferStatus } from "components/organisms/robotWidgets/RobotTransferStatus";
import { graphql } from "gql";
import { UvdRobot } from "gql/graphql";
import { IoChevronForward } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getIsForbidden, isSupportPath } from "utils/privileges/privilegeUtils";
import { PrivilegeWrapper } from "utils/privileges/PrivilegeWrapper";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import {
  useUvdConnectionStatus,
  UVDConnectionStatus,
} from "utils/UVD/useUvdConnectionStatus";
import { ForbiddenPage } from "./ForbiddenPage";

const ROBOT_PAGE_QUERY = graphql(`
  query RobotPage($robotId: String!) {
    robot(id: $robotId) {
      id
      serialNumber
      ... on UvdRobot {
        status {
          lastTimeActive
        }
        location {
          geocoordinates {
            allowed
          }
        }
      }
    }
    filterOptions {
      start
      end
    }
  }
`);

const ORG_QUERY = graphql(`
  query RobotPageOrganization($orgId: String!) {
    organization(id: $orgId) {
      id
      name
    }
  }
`);

const columnLeft = [
  "basicInfo",
  "latestDisinfection",
  "organization",
  "hardware",
  "activityLog",
];

const columnRight = [
  "cyclesCounter",
  "transferStatus",
  "batteries",
  "software",
  "robotMap",
];

const smallScreenColumns = [
  "basicInfo",
  "transferStatus",
  "latestDisinfection",
  "organization",
  "hardware",
  "cyclesCounter",
  "batteries",
  "software",
  "robotMap",
  "activityLog",
];

export const RobotPage = () => {
  const { robotId } = useParams();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const location = useLocation();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const isOnSupportPath = isSupportPath(location);

  const { data, error, loading } = useQueryWithSnack(ROBOT_PAGE_QUERY, {
    variables: { robotId: robotId ?? "" },
    skip: !robotId,
  });

  const robot = data?.robot as UvdRobot;
  const status = useUvdConnectionStatus(robot);

  const { data: orgData, error: orgError } = useQueryWithSnack(ORG_QUERY, {
    variables: { orgId: orgId ?? "" },
    skip: !orgId || orgId === "support",
  });

  const renderColumn = (type: any) => {
    switch (type) {
      case "basicInfo":
        return (
          <Grid item mb={6} key={type}>
            <RobotBasicInfo />
          </Grid>
        );
      case "latestDisinfection":
        return (
          <Grid item mb={6} key={type}>
            <RobotLatestDisinfection robotSerialNumber={robot?.serialNumber} />
          </Grid>
        );
      case "organization":
        return isOnSupportPath ? (
          <Grid item mb={6} key={type}>
            <RobotOrganization forbiddenOrg={forbiddenOrg} />
          </Grid>
        ) : null;
      case "hardware":
        return (
          <Grid item mb={6} key={type}>
            <RobotHardware />
          </Grid>
        );
      case "cyclesCounter":
        return status === UVDConnectionStatus.UNKNOWN ? null : (
          <Grid item mb={6} key={type}>
            {/* TODO: filter on only the current robot, and get for all time */}
            <CyclesCounterChart
              description="Number of disinfection cycles (all time)"
              filter={{
                serialNumbers: robot?.serialNumber
                  ? [robot?.serialNumber]
                  : undefined,
                timeSpan: data?.filterOptions?.start
                  ? {
                      start: data?.filterOptions.start,
                      end: data?.filterOptions.end,
                    }
                  : undefined,
              }}
              queryParams={{ skip: !robot?.serialNumber }}
              actionCardChildren={
                <Box mt={3}>
                  <InlineCard
                    onClick={() => {
                      const basePath = isOnSupportPath
                        ? "/cloud/support/disinfections"
                        : `/cloud/fm/${orgId}/disinfections`;

                      const path =
                        basePath +
                        "?filters=" +
                        encodeURI(
                          JSON.stringify({
                            serialNumbers: [robot.serialNumber],
                          }),
                        );

                      navigate(path);
                    }}
                  >
                    <Box
                      sx={(theme) => ({
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: 4,
                        width: "100%",
                        "& svg > *": {
                          color: theme.palette.grey[600],
                        },
                      })}
                    >
                      <Typography>View Disinfections</Typography>
                      <IoChevronForward size={18} />
                    </Box>
                  </InlineCard>
                </Box>
              }
            />
          </Grid>
        );
      case "transferStatus":
        return isOnSupportPath ? (
          <Grid item mb={6} key={type}>
            <RobotTransferStatus />
          </Grid>
        ) : null;
      case "batteries":
        return status === UVDConnectionStatus.UNKNOWN ? null : (
          <Grid item mb={6} key={type}>
            <RobotBatteries />
          </Grid>
        );
      case "software":
        return status === UVDConnectionStatus.UNKNOWN ? null : (
          <Grid item mb={6} key={type}>
            <RobotSoftware />
          </Grid>
        );
      case "activityLog":
        return isOnSupportPath ? (
          <PrivilegeWrapper privilege={GenericPrivileges.AuditLog} key={type}>
            <Grid item xs={12} mb={6}>
              <ActivityWidget mustInclude={[robotId ?? ""]} />
            </Grid>
          </PrivilegeWrapper>
        ) : null;
      case "robotMap":
        return (
          <Grid item xs={12} mb={6} key={type}>
            <RobotMap />
          </Grid>
        );
      default:
        return null;
    }
  };

  const forbidden = getIsForbidden(error);
  const forbiddenOrg = getIsForbidden(orgError);

  const widgetArr = largeScreen ? columnLeft : smallScreenColumns;

  if (forbidden) {
    return <ForbiddenPage />;
  }

  return (
    <Grid container spacing={6}>
      <HeaderGrid
        crumbs={
          loading
            ? []
            : location.pathname.includes("support")
              ? [
                  { label: "Support" },
                  location.pathname.includes("unassigned")
                    ? {
                        label: "Unassigned Robots",
                        onClick: () =>
                          navigate("/cloud/support/unassigned-robots"),
                      }
                    : {
                        label: "Robots",
                        onClick: () => navigate("/cloud/support/robots"),
                      },
                  {
                    label: robot?.serialNumber ?? "",
                  },
                ]
              : [
                  {
                    label: orgId && orgData ? orgData?.organization.name : "-",
                  },
                  {
                    label: "Robots",
                    onClick: () => navigate(`/cloud/fm/${orgId}/robots`),
                  },
                  {
                    label: robot?.serialNumber ?? "",
                  },
                ]
        }
      />

      <Grid item xs={12} xl={6}>
        {widgetArr.map((item) => renderColumn(item))}
      </Grid>
      {largeScreen ? (
        <Grid item xs={12} xl={6}>
          {columnRight.map((item) => renderColumn(item))}
        </Grid>
      ) : null}
    </Grid>
  );
};
