import { useMutation } from "@apollo/client";
import { RobotPrivileges } from "@blue-ocean-robotics/cloud-library";
import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";
import { OrganizationAutoComplete } from "components/atoms/autoComplete/OrganizationAutoComplete";
import { CloudLabel } from "components/atoms/CloudLabel";
import { OrganizationDisinfectionCard } from "components/modules/cards/OrganizationDisinfectionCard";
import { CloudDrawer, CloudDrawerProps } from "components/modules/CloudDrawer";
import { useFormik } from "formik";
import { graphql } from "gql";
import { Organization } from "gql/graphql";
import _ from "lodash";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { usePrivilege } from "utils/privileges/usePrivileges";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import * as Yup from "yup";
import { ASSIGNED_ROBOTS_QUERY } from "../AssignedRobots";
import { ORG_DATA_SOURCES_QUERY } from "../organizationWidgets/DisinfectionDataSources";
import { ROBOT_ORGANIZATION_QUERY } from "../robotWidgets/RobotOrganization";
import { UNASSIGNED_ROBOTS_QUERY } from "../UnassignedRobots";

const TRANSFER_ROBOT = graphql(`
  mutation TransferRobot($robotId: String!, $orgId: String) {
    transferRobot(id: $robotId, organizationId: $orgId) {
      id
    }
  }
`);

const QUERY = graphql(`
  query TransferRobotDisinfectionCount($orgId: String!) {
    disinfections(filter: { organizationIds: [$orgId] }) {
      count
    }
    filterOptions {
      start
      end
    }
  }
`);

const handleBeforeUnload = (event: any) => {
  event.preventDefault();
  event.returnValue = "";
};

interface Props {
  open: CloudDrawerProps["open"];
  onClose: CloudDrawerProps["onClose"];
  robot: any;
}

type Values = {
  organization: Partial<Organization> | null;
};

export const TransferRobot = ({ open, onClose, robot }: Props) => {
  const intl = useIntl();
  const canRemoveRobot = usePrivilege(RobotPrivileges.Update).hasPrivilege;

  const [updateRobot] = useMutation(TRANSFER_ROBOT, {
    refetchQueries: [
      {
        query: ROBOT_ORGANIZATION_QUERY,
        variables: { robotId: robot?.id || "" },
      },
      UNASSIGNED_ROBOTS_QUERY,
      { query: ASSIGNED_ROBOTS_QUERY },
      {
        query: ORG_DATA_SOURCES_QUERY,
        variables: {
          orgId: robot?.organization?.id || "",
        },
        fetchPolicy: "no-cache",
      },
    ],
  });

  const { data } = useQueryWithSnack(QUERY, {
    variables: {
      orgId: robot?.active?.organization?.id,
    },
  });

  const handleRemoveRobotFromAllOrganizations = () => {
    const snackbarId = enqueueSnackbar("Removing robot from organization...", {
      variant: "loading",
      persist: true,
    });

    onClose();
    updateRobot({
      variables: {
        robotId: robot?.id || "",
      },
      onCompleted: () => {
        enqueueSnackbar(`Robot was removed`, {
          variant: "success",
        });
        closeSnackbar(snackbarId);
        onClose();
      },
      onError: () => {
        enqueueSnackbar(`Robot could not be removed`, {
          variant: "error",
        });
        closeSnackbar(snackbarId);
        onClose();
      },
    });
  };

  const formik = useFormik<Values>({
    initialValues: {
      organization: null,
    },
    validationSchema: Yup.object({
      organization: Yup.object().required(
        intl.formatMessage({
          id: "required",
          defaultMessage: "Required",
        }),
      ),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar(
        `Transfering robot from ${
          robot?.active?.organization?.name || "organization"
        }...`,
        {
          variant: "loading",
          persist: true,
        },
      );
      window.addEventListener("beforeunload", handleBeforeUnload);
      onClose();

      updateRobot({
        variables: {
          robotId: robot?.id || "",
          orgId: values.organization?.id ?? "",
        },
        onCompleted: () => {
          enqueueSnackbar(`Robot was transfered`, {
            variant: "success",
          });
          closeSnackbar(snackbarId);
          return window.removeEventListener("beforeunload", handleBeforeUnload);
        },
        onError: () => {
          enqueueSnackbar(`Robot could not be transferred`, {
            variant: "error",
          });
          closeSnackbar(snackbarId);
          return window.removeEventListener("beforeunload", handleBeforeUnload);
        },
      });
    },
  });

  useEffect(() => {
    (() => formik.validateForm())();
  }, []);

  return (
    <form>
      <CloudDrawer
        open={open}
        onClose={onClose}
        header={`Transfer robot ${robot.serialNumber}`}
        action={{
          label: "Transfer",
          disabled: !formik.isValid,
          onClick: formik.handleSubmit,
        }}
        secondaryAction={{
          label: "Remove from organization",
          onClick: handleRemoveRobotFromAllOrganizations,
          disabled: !canRemoveRobot,
        }}
      >
        <OrganizationDisinfectionCard
          organization={robot.active?.organization}
          disinfectionStart={data?.filterOptions?.start}
          disinfectionEnd={data?.filterOptions?.end}
        />
        <FormHelperText sx={{ mt: 2 }}>
          {robot?.active?.organization?.name || "The current organization"} will
          keep access to their disinfection data but lose access to robot data.
        </FormHelperText>

        <CloudLabel>To organization</CloudLabel>
        <OrganizationAutoComplete
          name="organization"
          multiple={false}
          value={formik.values.organization}
          onChange={(e: any, value: Organization) => {
            formik.setFieldValue("organization", value);
          }}
          filterOptions={(options: Organization[]) =>
            options.filter(
              (item) => item.id !== robot?.active?.organization?.id,
            )
          }
          placeholder="Organization"
          onBlur={() => formik.setFieldTouched("organization")}
          textfieldprops={{
            error: formik.touched.organization && formik.errors.organization,
            helperText:
              formik.touched.organization && formik.errors.organization
                ? formik.errors.organization
                : "The organization will be able to see the robot right away in their Fleet Management. The robot will need Technical Service before their disinfections will sync to the Cloud.",
          }}
        />
        <CloudLabel>
          Access to {robot?.active?.organization?.name}'s disinfection data
        </CloudLabel>
        <RadioGroup
          defaultValue={"grant"}
          name="disinfectionstatus"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <FormControlLabel
            disabled
            value={"block"}
            control={<Radio />}
            label="Block access"
          />
          <FormControlLabel
            disabled
            value={"grant"}
            control={<Radio />}
            label="Grant access"
          />
        </RadioGroup>
        {!_.isNull(formik.values.organization) ? (
          <>
            <OrganizationDisinfectionCard
              organization={formik.values.organization}
              disinfectionStart={data?.filterOptions?.start}
              disinfectionEnd={data?.filterOptions?.end}
            />
            <FormHelperText sx={{ mt: 2 }}>
              {formik.values.organization?.name ?? "The new organization"} will
              have access to {robot?.active?.organization?.name}'s disinfection
              data
            </FormHelperText>
          </>
        ) : null}
        {/* <>
          <InlineCardEmptyView
            sx={{ flexGrow: 0 }}
            label="No disinfection data"
          />
          <FormHelperText sx={{ mt: 2 }}>
            The selected organization will not have access to{" "}
            {robot?.active?.organization?.name || "the current organization"}'s
            Disinfection data.
          </FormHelperText>
        </> */}
      </CloudDrawer>
    </form>
  );
};
