import { UvdRobot } from "gql/graphql";
import { useEffect, useRef, useState } from "react";
import { getShowTechnicalServiceNeeded } from "./UVDUtils";

export enum UVDConnectionStatus {
  NOT_CONNECTED = "NOT_CONNECTED",
  SERVICE_NEEDED = "SERVICE_NEEDED",
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  UNKNOWN = "UNKNOWN",
}

export const useUvdConnectionStatus = (robot?: UvdRobot) => {
  const [returnState, setReturnState] = useState(getInternalStatus(robot));
  const [, setDelay] = useState(1000); // TODO: remove this once robot can be guaranteed

  useEffect(() => {
    if (robot) {
      setReturnState(getInternalStatus(robot));
    }
  }, [robot]);

  useInterval(() => {
    if (robot) {
      setReturnState(getInternalStatus(robot));
      setDelay(getTimeUntilRefresh(robot));
    }
  }, 1000);

  return returnState;
};

function getInternalStatus(robot?: UvdRobot) {
  if (!robot?.status) return UVDConnectionStatus.UNKNOWN;

  const { showWarning } = getShowTechnicalServiceNeeded(robot);
  if (showWarning) return UVDConnectionStatus.SERVICE_NEEDED;

  if (isDateLessThan30SecondsAgo(robot?.status?.lastTimeActive))
    return UVDConnectionStatus.ONLINE;

  return UVDConnectionStatus.OFFLINE;
}

const refreshGracePeriod = 35 * 1000; // 5 second grace period in case of slow connection
function getTimeUntilRefresh(robot: UvdRobot) {
  if (!robot.status) return 1000; // TODO: remove this once robot can be guaranteed
  return (
    refreshGracePeriod -
    getTimeDifference(new Date(), new Date(robot.status.lastTimeActive))
  );
}

function isDateLessThan30SecondsAgo(date: string) {
  return getTimeDifference(new Date(), new Date(date)) < refreshGracePeriod;
}

function getTimeDifference(first: Date, second: Date) {
  return first.getTime() - second.getTime();
}

function useInterval(callback: () => void, delay: number) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay > 0) {
      const interval = window.setInterval(() => {
        callbackRef.current();
      }, delay);
      return () => clearInterval(interval);
    }
  }, [delay]);
}
