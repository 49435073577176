import { format, isAfter } from "date-fns";
import { UvdRobot } from "gql/graphql";
import { UVDConnectionStatus } from "./UVD/useUvdConnectionStatus";
import { getRobotStatus } from "./UVD/UVDRobotStatusConverter";

export function secondsToHMS(seconds: any) {
  seconds = Number(seconds);
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const hours = h > 9 ? h : "0" + h;
  const minutes = m > 9 ? m : "0" + m;
  const sec = s > 9 ? s : "0" + s;

  return `${hours}:${minutes}:${sec}`;
}

export function secondsToMS(seconds: any) {
  seconds = Number(seconds);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const minutes = m > 9 ? m : "0" + m;
  const sec = s > 9 ? s : "0" + s;

  return `${minutes}:${sec}`;
}

export function getDaysInMonth(month: number, year: number) {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date).toISOString());
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export function capitalizeWithPascalCase(label: string) {
  return [label.slice(0, 1).toUpperCase(), label.slice(1, label.length)].join(
    "",
  );
}

export function getInitials(fullName: string) {
  return fullName
    .match(/(\b\S)?/g)
    ?.join("")
    ?.match(/(^\S|\S$)?/g)
    ?.join("");
}

export const dateFormat = "dd MMMM yyyy";

export const getFormattedDate = (date: Date | undefined) => {
  if (date) {
    return format(new Date(date), dateFormat);
  }
  return null;
};

export const getDateHasPassed = (date: Date | undefined) => {
  if (date) {
    return isAfter(new Date(), new Date(date));
  }
  return true;
};

export function getUvdRobotStatus(
  robot?: UvdRobot,
  connectionStatus?: UVDConnectionStatus,
) {
  if (!robot) return "-";

  switch (connectionStatus) {
    case UVDConnectionStatus.ONLINE:
      return robot.status && getRobotStatus(robot.status.job);
    case UVDConnectionStatus.OFFLINE:
      return robot.status?.lastTimeActive
        ? `Offline since ${getFormattedDate(robot.status?.lastTimeActive)}`
        : "Offline";
    case UVDConnectionStatus.UNKNOWN:
      return "Not syncing data";
    case UVDConnectionStatus.SERVICE_NEEDED:
    default:
      return;
  }
}
