import { gql } from "@apollo/client";
import { Box } from "@mui/material";
import { UserAvatar } from "components/atoms/avatars/UserAvatar";
import { UserCardContent } from "components/modules/cards/UserCardContent";
import { useConfig } from "config/configHook";
import { useFirebase } from "firebaseWrapper";
import { closeSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routing/Routes";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { CloudListItem } from "../atoms/dashboard/Identity/popup/CloudListItem";
import { CloudListItemGroup } from "../atoms/dashboard/Identity/popup/CloudListItemGroup";
import { PopupMenu } from "../atoms/dashboard/Identity/popup/CloudPopupMenu";

const GET_IDENTITY = gql`
  query IdentityButton {
    me {
      ...UserAvatar
      ...UserCardContent
    }
  }
  ${UserAvatar.fragments.identity}
  ${UserCardContent.fragments.identity}
`;

export const IdentityButton = () => {
  const config = useConfig();
  const firebase = useFirebase(config);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data, client } = useQueryWithSnack(GET_IDENTITY);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <UserAvatar
        size="medium"
        onClick={handleOpen}
        identity={data?.me}
        sx={{ ml: 6, cursor: "pointer" }}
      />
      <PopupMenu open={open} onClose={handleClose} anchorEl={anchorEl}>
        <Box>
          <UserCardContent
            identity={data?.me}
            iconType="settings"
            wrapperStyle={{ padding: 4 }}
            onIconClick={() => {
              navigate(ROUTES.profile);
              handleClose();
            }}
          />
          <CloudListItemGroup sx={{ padding: 2 }}>
            <CloudListItem
              label="Log out"
              onClick={() => {
                firebase.logout();
                closeSnackbar();
                navigate("/");
                client.clearStore();
              }}
            />
          </CloudListItemGroup>
        </Box>
      </PopupMenu>
    </>
  );
};
