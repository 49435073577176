import { Menu } from "@mui/material";
import { ReactElement } from "react";

interface Props {
  open: boolean;
  anchorEl: any;
  onClose: () => void;
  children: ReactElement<any>;
  paperProps?: object;
}

export const PopupMenu = ({
  open,
  anchorEl,
  onClose,
  children,
  paperProps,
}: Props) => {
  return (
    <Menu
      anchorEl={anchorEl}
      data-cy="identity.menu"
      open={open}
      onClose={onClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            width: "300px",
            overflow: "visible",
            filter: "drop-shadow(0px 0px 22px rgba(24, 28, 50, 0.15))",
            ul: {
              padding: 0,
            },
            marginTop: "12px",
            ...paperProps,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {children}
    </Menu>
  );
};
