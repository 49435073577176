import { Privileges } from "@blue-ocean-robotics/cloud-library";
import { ReactElement } from "react";
import { usePrivilege } from "./usePrivileges";

interface Props {
  children: ReactElement;
  privilege: Privileges;
  organizationId?: string;
}

export const PrivilegeWrapper = ({
  privilege,
  organizationId,
  children,
}: Props) => {
  const { hasPrivilege, loading } = usePrivilege(privilege, organizationId);

  if (loading) {
    return null;
  } else if (!loading && hasPrivilege) {
    return children;
  } else {
    return null;
  }
};
