import { PositionIcon } from "@blue-ocean-robotics/bor_ui/dist/controllers/mapEditor/positions";
import UVDStandardChargingImg from "assets/images/uvd-c-charging.png";
import UVDStandardDisinfectingImg from "assets/images/uvd-c-disinfecting.png";
import UVDCStandardImg from "assets/images/uvd-c.png";
import UVDPharmaChargingImg from "assets/images/uvd-pharma-charging.png";
import UVDPharmaDisinfectingImg from "assets/images/uvd-pharma-disinfecting.png";
import UVDPharmaImg from "assets/images/uvd-pharma.png";
import { differenceInSeconds } from "date-fns";
import {
  DisinfectionFile,
  DisinfectionInterruption,
  InterruptionReason,
  Maybe,
  RobotJob,
  RobotType,
} from "gql/graphql";
import _ from "lodash";
import { SelectedDisinfectionReport } from "types/types";
import { secondsToHMS } from "utils/utilFunctions";
import { getIsDisinfecting, getIsDocked } from "./UVDRobotStatusConverter";

export const getUVCLightDurationInHMS = (
  uvcLightDuration: number | undefined,
) => {
  return uvcLightDuration ? secondsToHMS(uvcLightDuration) : "00:00:00";
};

export const getTaskDuration = (start: Date, end: Date) => {
  const durationInSeconds =
    start && end ? differenceInSeconds(new Date(end), new Date(start)) : 0;

  return {
    taskDurationInSeconds: durationInSeconds,
    taskDurationInHMS:
      durationInSeconds > 0 ? secondsToHMS(durationInSeconds) : "00:00:00",
  };
};

export const getUVDRobotImgSrc = (robotType: RobotType) => {
  switch (robotType) {
    case RobotType.Pharma:
      return UVDPharmaImg;
    default:
      return UVDCStandardImg;
  }
};

export const getUVDRobotStatusImgSrc = (
  robotType: RobotType,
  robotJob?: RobotJob,
) => {
  if (!robotJob) {
    return robotType === RobotType.Pharma ? UVDPharmaImg : UVDCStandardImg;
  }

  const { type, state } = robotJob;

  const dbType = type?.toLowerCase();

  const isRobotDocked = getIsDocked(state);
  const isRobotDisinfecting = getIsDisinfecting(state);

  if (dbType === "" && isRobotDocked) {
    return robotType === RobotType.Pharma
      ? UVDPharmaChargingImg
      : UVDStandardChargingImg;
  }

  if (dbType === "disinfect zone" && isRobotDisinfecting) {
    return robotType === RobotType.Pharma
      ? UVDPharmaDisinfectingImg
      : UVDStandardDisinfectingImg;
  }

  return robotType === RobotType.Pharma ? UVDPharmaImg : UVDCStandardImg;
};

export const getShowTechnicalServiceNeeded = (robot: any) => {
  if (!robot?.version)
    return {
      showBatteryFamilyWarning: false,
      showBatteryVersionWarning: false,
      showPLCWarning: false,
      showWarning: false,
    };

  const batteriesVersion = robot?.version?.batteries;
  const batteryFamilies = _.chain(batteriesVersion)
    .map((item) => item?.family)
    .uniq()
    .value();

  const batteryVersions = _.chain(batteriesVersion)
    .map((item) => item?.version)
    .uniq()
    .value();

  const showBatteryFamilyWarning = batteryFamilies.length > 1;
  const showBatteryVersionWarning = batteryVersions.length > 1;
  const showPLCWarning = robot.version?.plc?.hasNewAvailable;

  return {
    showBatteryFamilyWarning,
    showBatteryVersionWarning,
    showPLCWarning,
    showWarning:
      showBatteryFamilyWarning || showBatteryVersionWarning || showPLCWarning,
  };
};

export const getRoom = (
  room: string | undefined,
  department: string | undefined,
) => {
  return !room && !department
    ? "-"
    : [room, department].filter((item) => item !== "").join(" - ");
};

export const getMapPositions = (
  disinfectionReport: SelectedDisinfectionReport | null,
) => {
  const zonePositions = _.flatten(
    disinfectionReport?.navigationMap?.zones?.map((zone) => zone.mapItems),
  )?.filter((item) => item?.category === "Disinfection position");

  const disinfectionPositions = (disinfectionReport?.positions ?? []).map(
    (item) => ({
      ...item,
      ...zonePositions.find((el) => el?.name === item.name),
    }),
  );

  const getPositionStatus = (status: string) => {
    switch (status) {
      case "disinfected":
        return "success";
      case "failed":
        return "failed";
      default:
        return "remaining";
    }
  };

  const formattedPositions = disinfectionPositions?.map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (positionItem: any) =>
      ({
        uuid: positionItem?.id?.toString() || "",
        position: {
          x: positionItem?.position?.x,
          y: positionItem?.position?.y,
        },
        yaw: positionItem?.position?.yaw,
        text: positionItem?.order,
        status: positionItem?.status
          ? getPositionStatus(positionItem.status)
          : "",
        name: positionItem?.name,
      }) as PositionIcon,
  );

  return {
    formattedMapPositions: formattedPositions,
    all: disinfectionPositions,
    completed: disinfectionPositions.filter(
      (item) => item.status === "disinfected",
    ),
    failed: disinfectionPositions.filter((item) => item.status === "failed"),
    remaining: disinfectionPositions.filter(
      (item) => item.status !== "disinfected" && item.status !== "failed",
    ),
  };
};

export const getInterruptionArr = (
  interruptions: DisinfectionInterruption | undefined,
): {
  allInterruptions: Array<{ label: InterruptionReason; value: number }> | [];
  activeInterruptions: Array<{ label: InterruptionReason; value: number }> | [];
} => {
  if (!interruptions) return { allInterruptions: [], activeInterruptions: [] };

  const all = Object.keys(interruptions)
    .slice(2) // slicing the first 2 elements in the array, to remove typename and "cause"
    .map((value) => ({
      label: value as InterruptionReason,
      value: interruptions[value as keyof DisinfectionInterruption] as number,
    }));

  return {
    allInterruptions: all,
    activeInterruptions: all.filter((item) => item.value !== 0),
  };
};

export const getMissingMapMessage = (
  navMapFileName?: string,
  heatMap?: Maybe<DisinfectionFile> | undefined,
  room?: string,
  error?: any,
) => {
  if (!navMapFileName && !heatMap && room === "") {
    return "Map not available because disinfection was performed without selecting a specific room.";
  } else if (error) {
    return "Map could not be shown for this disinfection. <br /> Refresh to try again.";
  } else if (!heatMap) {
    return "Map not available for this disinfection.";
  } else {
    return undefined;
  }
};
