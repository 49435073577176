import { useReactiveVar } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { firebaseErrorVar } from "cache";
import { FormikPassword, FormikTextField } from "components/atoms/formik";
import { useConfig } from "config/configHook";
import { useFirebase } from "firebaseWrapper";
import { useFormik } from "formik";
import { ReactElement, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";

interface Values {
  email: string;
  password: string;
}

export const EmailPasswordForm = ({
  submitButton,
  onSubmit,
  initialEmail,
  disableEmail,
}: {
  submitButton: ReactElement;
  onSubmit?: any;
  initialEmail?: string;
  disableEmail?: boolean;
}) => {
  const intl = useIntl();
  const config = useConfig();
  const firebase = useFirebase(config);

  const firebaseError: string = useReactiveVar(firebaseErrorVar);

  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    setErrorMsg("");
  }, []);

  useEffect(() => {
    if (firebaseError) {
      setErrorMsg(firebaseError);
    }
  }, [firebaseError]);

  const formik = useFormik<Values>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(
          intl.formatMessage({
            id: "login.invalidEmail",
            defaultMessage: "Invalid email address",
          }),
        )
        .required(
          intl.formatMessage({
            id: "required",
            defaultMessage: "Required",
          }),
        ),
      password: Yup.string()
        .required(
          intl.formatMessage({
            id: "required",
            defaultMessage: "Required",
          }),
        )
        .min(8, "Password is too short - should be 8 characters minimum."),
      //.matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    }),
    onSubmit(values) {
      if (onSubmit) {
        onSubmit(values);
      } else {
        firebase.logInWithEmailAndPassword(values.email, values.password);
        firebaseErrorVar("");
      }
    },
  });

  useEffect(() => {
    if (initialEmail) formik.setFieldValue("email", initialEmail);
  }, [initialEmail]);

  return (
    <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
      <Box>
        <FormikTextField
          type="email"
          formik={formik}
          name="email"
          label={intl.formatMessage({
            id: "login.email",
            defaultMessage: "E-mail",
          })}
          placeholder="E-mail"
          disabled={disableEmail}
          onChange={() => setErrorMsg("")}
        />
        <FormikPassword
          formik={formik}
          name="password"
          label={intl.formatMessage({
            id: "login.password",
            defaultMessage: "Password",
          })}
          placeholder="Password"
          onChange={() => setErrorMsg("")}
        />
      </Box>
      <Typography
        color={(theme) => theme.palette.error.main}
        sx={{ marginTop: 6, marginBottom: 6 }}
      >
        {errorMsg ? "Incorrect user credentials" : ""}
      </Typography>
      {submitButton}
    </form>
  );
};
