import { useMutation } from "@apollo/client";
import { Box, Button, Skeleton } from "@mui/material";
import {
  CloudStepper,
  CloudStepperProps,
} from "components/atoms/stepper/CloudStepper";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { addMonths, addWeeks, format } from "date-fns";
import { graphql } from "gql";
import { ActivationState } from "gql/graphql";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import {
  dateFormat,
  getDateHasPassed,
  getFormattedDate,
} from "utils/utilFunctions";

const RESEND = graphql(`
  mutation ResendUserInvitation($userIds: [String!]!) {
    sendReminderEmails(identityIds: $userIds)
  }
`);

export const getPillStatus: any = (status: string) => {
  switch (status) {
    case "ACTIVE":
      return { label: "Active", color: "success", style: { color: "white" } };
    case "PENDING":
      return {
        label: "Pending onboarding",
        color: "warning",
      };
    case "REVOKED":
      return {
        label: "Revoked",
        color: "error",
        style: { color: "white" },
      };
    case "MISSING_OWNER":
      return {
        label: "Missing owner",
        color: "error",
        style: { color: "white" },
      };
    default:
      return { label: "-", color: "warning" };
  }
};

interface Props {
  user: any;
  loading?: boolean;
}

export const UserOnboardingStatus = ({ user, loading }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const loggedInWithSSO = user?.loginWithMicrosoft || user?.loginWithGoogle;
  const status = user?.inviteState;
  const isPending = status === ActivationState.Pending;
  const isActive = status === ActivationState.Active;
  const rolesMissing = user?.affiliations.length === 0 && !user?.supportRole;

  const pillStatus = getPillStatus(status);

  const [resendInvitation] = useMutation(RESEND);

  useEffect(() => {
    if (status === ActivationState.Active) {
      setIsOpen(false);
    }
  }, [status]);

  const completedOnboardingText = useMemo(() => {
    if (user?.firstReminderSent || user?.secondReminderSent) {
      return "";
    } else if (
      user?.firstReminderSent === null &&
      user?.inviteState !== "ACTIVE"
    ) {
      const firstReminderDate = user?.invitedDate
        ? addWeeks(new Date(user?.invitedDate), 2)
        : null;

      return `Sending 1st Reminder email on ${
        firstReminderDate ? format(firstReminderDate, dateFormat) : "-"
      }`;
    } else if (user?.firstReminderSent && user?.secondReminderSent === null) {
      const secondReminderDate = user?.invitedDate
        ? addMonths(new Date(user.invitedDate), 1)
        : null;
      return `Sending 2nd Reminder email on ${
        secondReminderDate ? format(secondReminderDate, dateFormat) : "-"
      }`;
    } else {
      return getFormattedDate(user?.firstLogin) || "";
    }
  }, [user]);

  const steps: CloudStepperProps["steps"] = useMemo(() => {
    return [
      {
        label: "Invited",
        description: getFormattedDate(user?.invitedDate) || "",
      },
      {
        label: "Role(s) assigned",
        description: getFormattedDate(user?.invitedDate) || "",
      },
      {
        label: "1st Reminder email sent",
        description: getFormattedDate(user?.firstReminderSent) || "",
        hidden: isActive || getDateHasPassed(user?.firstReminderSent) === true,
      },
      {
        label: "2nd Reminder email sent",
        description: getFormattedDate(user?.secondReminderSent) || "",
        hidden: isActive || getDateHasPassed(user?.secondReminderSent) === true,
      },
      {
        label: "Completed onboarding",
        description: completedOnboardingText,
        variant: isPending ? "waiting" : "done",
      },
      {
        label: "First successful login",
        description: getFormattedDate(user?.firstLogin) || "",
        variant: isPending ? "waiting" : "done",
      },
      {
        label: "Invitation revoked",
        color: "error",
        description: "---",
        hidden: isPending || isActive,
      },
    ];
  }, [isPending, isActive]);

  const SSOuserSteps: CloudStepperProps["steps"] = useMemo(() => {
    return [
      {
        label: "First successful login",
        description: "via SSO, " + getFormattedDate(user?.firstLogin) || "",
      },
      {
        label: "Completed onboarding",
        description: getFormattedDate(user?.firstLogin) || "",
      },
      {
        label: "Role(s) assigned",
        variant: rolesMissing ? "waiting" : "done",
      },
    ];
  }, [isPending, isActive, rolesMissing]);

  const handleResendInvitation = () => {
    const snackbarId = enqueueSnackbar("Saving your name...", {
      variant: "loading",
      persist: true,
    });

    resendInvitation({
      variables: {
        userIds: [user.id],
      },
      onCompleted: () => {
        enqueueSnackbar(`Invitation will be sent again`, {
          variant: "success",
        });
        closeSnackbar(snackbarId);
      },
      onError: () => {
        enqueueSnackbar(`Could not resend invitation`, {
          variant: "error",
        });
        closeSnackbar(snackbarId);
      },
    });
  };

  if (loading) return <Skeleton height={110} />;

  return (
    <WidgetPaper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <WidgetHeader
          label="Status"
          description={
            isActive
              ? `Last login: ${getFormattedDate(user?.lastLogin) || "-"}`
              : undefined
          }
          pillLabel={pillStatus?.label}
          pillStyle={pillStatus?.style}
          pillColor={pillStatus?.color}
        />
        {status === "ACTIVE" ? (
          <Box onClick={() => setIsOpen(!isOpen)} sx={{ cursor: "pointer" }}>
            {isOpen ? <IoChevronUp size={24} /> : <IoChevronDown size={24} />}
          </Box>
        ) : null}
      </Box>
      {isOpen ? (
        <>
          <CloudStepper
            size="small"
            style={{ marginTop: 4 }}
            steps={loggedInWithSSO ? SSOuserSteps : steps}
          />
          {status === "ACTIVE" ? null : (
            <Box mt={6} display="flex" justifyContent="space-between">
              <Button size="small" onClick={handleResendInvitation}>
                Resend invitation
              </Button>
              {/* <Button size="small" variant="outlined" color="error">
                Revoke invitation
              </Button> */}
            </Box>
          )}
        </>
      ) : null}
    </WidgetPaper>
  );
};
