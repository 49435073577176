import { Box, styled, Typography } from "@mui/material";
import { CloudStepperProps } from "./CloudStepper";
import { CloudStepperCircle } from "./CloudStepperCircle";

const HorizontalLine = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isDone" && prop !== "isCurrent",
})<{ isDone: boolean; isCurrent: boolean }>(({ isDone, isCurrent }) => ({
  borderTop: `2px ${isDone || isCurrent ? "solid" : "dashed"}`,
  height: "1px",
  flexGrow: 1,
}));

interface CloudHorizontalStepperProps extends CloudStepperProps {
  wrapperStyle?: any;
  stepIndex?: any;
}

export const CloudHorizontalStepper = ({
  steps,
  wrapperStyle,
  stepIndex,
}: CloudHorizontalStepperProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        width: "100%",
        ...wrapperStyle,
      }}
    >
      {steps
        .filter((item) => !item.hidden)
        .map((step, index) => {
          const isDone = index < stepIndex;
          const isCurrent = index === stepIndex;

          return (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <HorizontalLine
                  isDone={isDone}
                  isCurrent={isCurrent}
                  sx={(theme) => ({
                    borderColor:
                      index === 0
                        ? "transparent"
                        : isDone || isCurrent
                          ? theme.palette.primary.main
                          : theme.palette.grey[400],
                  })}
                />

                <CloudStepperCircle
                  color={step.color}
                  variant={isDone ? "done" : isCurrent ? "current" : "waiting"}
                />
                <HorizontalLine
                  isDone={isDone}
                  isCurrent={isCurrent}
                  sx={(theme) => ({
                    borderTop: `2px ${isDone ? "solid" : "dashed"}`,
                    borderColor:
                      index + 1 === steps.length
                        ? "transparent"
                        : isDone
                          ? theme.palette.primary.main
                          : theme.palette.grey[400],
                    marginRight: isCurrent || !isDone ? 1 : 0,
                  })}
                />
              </Box>
              <Typography mt={1}>{step.label}</Typography>
            </Box>
          );
        })}
    </Box>
  );
};
